import React from 'react';
import { Card, Col } from 'antd';
import { colors, shadows } from '../../styles';

function StatisticBox(props) {
	const styles = {
		root: {
			...props.boxStyle,
			borderRadius: `6px`,
			boxShadow: shadows.elevation5,
		},
		title: {
			...props.titleStyle,
			color: colors.gray2,
			fontSize: `14px`,
			lineHeight: `19px`,
		},
		value: {
			color: colors.gray1,
			fontSize: `28px`,
			lineHeight: `42px`,
			marginTop: `2px`,
		},
		right: {
			position: `absolute`,
			top: `16px`,
			right: `16px`,
		}
	}

	return (
		<Col sm={24} md={12} lg={8}>
			<Card 
				bordered={false}
				style={styles.root} 
				bodyStyle={props.innerStyle}
			>
				<div style={styles.title}>{props.title}</div>
				<div style={styles.value}>{props.value}</div>
				{
					props.rightDom &&
					<div style={styles.right}>{props.rightDom}</div>
				}
			</Card>
		</Col>
	);
}

export default StatisticBox;
