// RL environment related
export const maxGameSpeed = 5;
export const carBrakingMode = 1;		// 0 = Constant, 1 = Variable

// RL Training / Evaluation Related 
export const rl_mode = 1;	// 0 = tensorflowjs, 1 = Q-table
export const stateInputNo = 2;
export const recommendedNoOfTrainToDo = -1;	//30;
export const noOfEvaluationTrial = 5;
export const maxSuccessDistance = 5;
export const minSuccessDistance = 0;
export const actionPerSecond = 15;
export const maxInitialVelocity = 30;
export const minInitialVelocity = 25;
export const maxInitialDistance = 30;
export const minInitialDistance = 20;
export const episodeTerminationDistance = -7;
export const availableBrakingForceFactorArray = [0, 1, 2, 3, 4];
export const availableBrakingForceFactorWeightArray = [1, 1, 1, 1, 1];

// RL Reward Related 
export const largestReward = 100;
export const smallestReward = -100;

const rewardItemDetailsDummyValue = -999999;
export const rewardItemArray = [
	{
		isFinalSituation: true,

		distUpperLimit: rewardItemDetailsDummyValue,
		distLowerLimit: 15,
		hasUpperLimit: false,
		hasLowerLimit: true,

		defaultReward: 5,
		reward: 5,
	},
	{
		isFinalSituation: true,

		distUpperLimit: 15,
		distLowerLimit: 5,
		hasUpperLimit: true,
		hasLowerLimit: true,

		defaultReward: 20,
		reward: 20,
	},
	{
		isFinalSituation: true,

		distUpperLimit: 5,
		distLowerLimit: 2,
		hasUpperLimit: true,
		hasLowerLimit: true,

		defaultReward: 80,
		reward: 80,
	},
	{
		isFinalSituation: true,

		distUpperLimit: 2,
		distLowerLimit: 0,
		hasUpperLimit: true,
		hasLowerLimit: true,

		defaultReward: 100,
		reward: 100,
	},
	{
		isFinalSituation: true,

		distUpperLimit: 0,
		distLowerLimit: -5,
		hasUpperLimit: true,
		hasLowerLimit: true,

		defaultReward: -10,
		reward: -10,
	},
	{
		isFinalSituation: true,

		distUpperLimit: -5,
		distLowerLimit: rewardItemDetailsDummyValue,
		hasUpperLimit: true,
		hasLowerLimit: false,

		defaultReward: -30,
		reward: -30,
	},
]
