import React from 'react';
import { Slider, Progress } from 'antd';

import { ContextStore } from '../../../../store/ContextStore';
import { parseDate } from '../../../../utils/app';
import { S3_URL } from '../../../../constants/app.constant';
import { 
	SELECT_AIART_STYLE_IMG,
} from '../../../../constants/tracker.constant';
import { ReactComponent as QuestionMarkSvg } from '../../../../assets/question-mark.svg';
import { ReactComponent as CheckCircleSvg } from '../../../../assets/check-circle.svg';
import { ReactComponent as LeftSvg } from '../../../../assets/chevron-left.svg';
import { ReactComponent as XSvg } from '../../../../assets/x.svg';
import { postLessonResult } from '../../../../actions/project.action';
import { trackerWithEvent } from '../../../../actions/app.action';
import { aiArtLang } from '../../../../languages/aiArt.language';
import { colors, shadows } from '../../../styles';
import Button from '../../../Auth/Common/Button';

function StyledImage(props) {
	const { dispatch, project } = React.useContext(ContextStore);
	const imageStyles = [
		{src: props.src, s3: ''},
		{src: '', s3: 'chris-barbalis-FZ65n5sAsaY-unsplash'},
		{src: '', s3: 'alexander-schimmeck-ineC_oi7NHs-unsplash'},
		{src: '', s3: 'toa-heftiba-0WAJhFK7Q9o-unsplash'},
		{src: '', s3: 'dimitar-belchev-fRBpWLAcWIY-unsplash'},
		{src: '', s3: 'daniele-levis-pelusi-YQrUzrsRNes-unsplash'},
		{src: '', s3: 'DT1502_cropped2'},
		{src: '', s3: 'DP-1014-001'},
		{src: '', s3: 'DT47'},
		{src: '', s3: 'DT3108'},
		{src: '', s3: 'DP-18070-024'},
		{src: '', s3: 'hb_1989.141.4'},
		{src: '', s3: 'hb_22.25a-f'},
		{src: '', s3: 'DP143983'},
	];
	const [values, setValue] = React.useState({
		loading: false,
		selected: imageStyles[0],
		step: 100,
		startStyling: false,
		trained: false,
		completed: false,
	});

	const styles = {
		playground: {
			backgroundColor: colors.white, 
			boxShadow: shadows.elevation5,
			height: `660px`,
			minHeight: `450px`,
			maxHeight: `calc(100vh - 167px - 95px)`, 
			borderRadius: `6px`,
			overflow: `hidden`,
		},
		resultContainer: {
			display: `inline-block`,
			width: `calc(100% - 232px)`,
			textAlign: `center`,
			position: `relative`,
			height: `100%`,
		},
		imageContainer: {
			width: `100%`,
			textAlign: `center`,
			position: `relative`,
			height: `calc(100% - 96px)`,
			overflowY: `scroll`,
		},
		styledImg: {
			width: `auto`,
			height: `auto`,
			maxWidth: `calc(100% - 32px)`,
			maxHeight: `532px`,
			margin: `16px`,
		},
		stylesContainer: {
			display: `inline-block`,
			width: `232px`,
			height: `100%`,
			borderLeft: `1px solid #E0E0E0`,
			float: `right`,
			padding: `16px 0px 16px 24px`,
			position: `relative`,
			overflowX: `hidden`,
		},
		style: {
			borderRadius: `6px`,
			margin: `2px 4px`,
			width: `88px`,
			height: `88px`,
			display: `inline-block`,
			boxShadow: shadows.elevation4,
			backgroundPosition: `center`,
			backgroundSize: `cover`,
			overflow: `hidden`,
			cursor: `pointer`,
			position: `relative`,
		},
		selectOverlay: {
			width: `100%`,
			height: `100%`,
			backgroundColor: `rgba(51, 51, 51, 0.25)`,
			textAlign: `right`,
			padding: `6px`,
			color: colors.success2,
			position: `absolute`,
			top: 0,
		},
		controlPanel: {
			height: `96px`,
			padding: `16px`,
			backgroundColor: colors.white,
			borderTop: `1px solid ${colors.gray5}`,
		},
		stepContainer: {
			maxWidth: `297px`,
			margin: `0px auto`,
			width: `100%`,
		},
		startStylingContainer: {
			height: `100%`, 
			display: `flex`, 
			justifyContent: `center`, 
			alignItems: `center`,
			overflowY: `scroll`,
		},
		sample: {
			borderRadius: `14px`,
			width: `176px`,
			height: `176px`,
			display: `inline-block`,
			boxShadow: shadows.elevation4,
			backgroundColor: colors.gray6,
			backgroundPosition: `center`,
			backgroundSize: `cover`,
			padding: `42px`,
			overflow: `hidden`,
		},
	}

	async function changeStyle(style) {
		// <----- waiting server response
		if(values.loading) return;

		setValue({
			...values,
			selected: style,
			startStyling: false,
			trained: false,
			completed: false,
		});
	}

	async function startStyling(style) {
		setValue({
			...values,
			step: 0,
			startStyling: true,
			loading: true,
		});
		trackerWithEvent(SELECT_AIART_STYLE_IMG, style.s3); // image id

		// post lesson result
		const timer = parseDate(Date.now());
		dispatch(await postLessonResult([{
			...timer,
			project_uuid: project.overview.id,
			lesson_uuid: project.curriculum.try.id,
			content_uuid: null,
			is_correct: true,
			result: props.source+'_'+style.s3,
		}]));
	}

	async function handleChangeStep(step) {
		setValue({
			...values,
			step: step,
		});
	}

	function reset(){
		// <----- waiting server response
		if(values.loading) return;
		
		props.reset();
	}

	function loadedImage() {
		// <----- display completed msg 
		if(values.completed){
			setTimeout(() => {
				setValue({ 
					...values, 
					completed: false,
					loading: false,
				});
			}, 1500); // hardcode 1.5s
		}

		// <----- simulate training process
		if(values.step<100 && !values.trained){
			setTimeout(() => {
				setValue({ 
					...values, 
					step: values.step+25,
					trained: values.step===75 ? true : false,
					completed: values.step===75 ? true : false,
				});
			}, 1000); // hardcode 1s
		}
	}

	function formatter(value) {
		switch(value){
			case 0: return aiArtLang.original;
			case 25: return aiArtLang.formatString(aiArtLang.intermediate, 1);
			case 50: return aiArtLang.formatString(aiArtLang.intermediate, 2);
			case 75: return aiArtLang.formatString(aiArtLang.intermediate, 3);
			case 100: return aiArtLang.final_result;
			default: return null;
		}
	}

	function parseOutput(value) {
		if(!value.startStyling){
			// <------ start styling page
			return (
				<div style={styles.startStylingContainer}>
					<div>
						<div id="aiart-try-output-container" style={{display: `flex`, justifyContent: `center`, alignItems: `center`, marginBottom: `32px`}}>
							<div style={{...styles.sample, backgroundImage: `url(`+props.src+`)`}} />
							<XSvg style={{margin: `24px`}} />
							{
								value.selected.s3
								? <div style={{...styles.sample, backgroundImage: `url('${S3_URL}/aiart-testtube/pretrained/thumbnail/style_image/${value.selected.s3}.jpg')`}} />
								: <div style={styles.sample}><QuestionMarkSvg /><div style={{color: colors.gray2, marginTop: `14px`}}>{aiArtLang.select_style}</div></div>
							}
						</div>
						<Button 
							type="primary" 
							inlinestyle={{visibility: value.selected.s3 ? 'visible' : 'hidden'}} 
							onClick={() => startStyling(value.selected)}>
							{aiArtLang.start_styling}
						</Button>
					</div>
				</div>
			);
		}

		// <----- training outputs
		// footer
		let footer = <div>{aiArtLang.training}</div>
		if(value.completed){
			footer = <div style={{color: colors.success2}}>{aiArtLang.training_completed}</div>
		}
		else if(value.trained){
			footer = (
				<div style={{cursor: `pointer`}} onClick={reset}>
					<LeftSvg style={{position: `relative`, top: `3px`, width: `16px`, height: `16px`, marginRight: `6px`}} />
					{aiArtLang.start_over}
				</div>
			);
		}

		const output = `${S3_URL}/aiart-testtube/pretrained/step${value.step/25}/${props.source}_${value.selected.s3}.jpg`; // step 1 - 4
		return (
			<React.Fragment>
				{/* trained images */}
				<div style={styles.imageContainer}>
					<img alt="output" style={styles.styledImg} src={value.step>0 ? output : props.src} onLoad={loadedImage} />
				</div>

				{/* control panel */}
				<div style={styles.controlPanel}>
					<div style={styles.stepContainer}>
						{
							value.completed || !value.trained
							? <Progress 
								strokeWidth={4} 
								showInfo={false} 
								strokeColor={colors.primary2} 
								percent={value.step} 
								style={{marginBottom: `20px`, position: `relative`, top: `-6px`}} 
							  />
							: <Slider 
								style={{width: `100%`, margin: `0px 0px 4px 0px`, height: `36px`}} 
								marks={{0: aiArtLang.original, 25: "", 50: "", 75: "", 100: aiArtLang.result}} 
								step={null} 
								value={value.step} 
								tipFormatter={formatter} 
								onChange={handleChangeStep} 
							  />
						}
						{footer}
					</div>
				</div>
			</React.Fragment>
		);
	}

	const styleItems = imageStyles.map((unit, index) => (
		<div 
			key={index} 
			onClick={() => changeStyle(unit)}  
			style={{
				...styles.style, 
				backgroundImage: unit.s3 ? `url('${S3_URL}/aiart-testtube/pretrained/thumbnail/style_image/${unit.s3}.jpg')` : `url('${unit.src}')`,
			}} 
		>
			{
				values.selected.s3 === unit.s3 &&
				<div style={styles.selectOverlay}>
					<CheckCircleSvg style={{width: `24px`, height: `24px`}} />
				</div>
			}
		</div>
	));

	return (
		<div style={styles.playground}>
			<div id="aiart-try-image-container" style={styles.resultContainer}>
				{ parseOutput(values) }
			</div>
			<div id="aiart-try-style-container" style={styles.stylesContainer}>
				<div style={{fontWeight: `600`, marginBottom: `16px`, color: colors.gray1}}>
					{aiArtLang.styles}
				</div>
				<div style={{margin: `0px -4.5px`}}>
					{styleItems}
				</div>
			</div>
		</div>
	);
}
export default StyledImage;
