import React from 'react';
import { useParams } from 'react-router-dom';
import AiArtTry from '../../../Course/AiArt/Try';
import SelfDrivingCarTry from '../../../Course/SelfDriving/Try'
function Try(props) {
	switch(useParams().name){
		case 'ai-art': 
			return <div className="course-container"><AiArtTry /></div>;
		case 'self-driving-car':
			return <div className="fluid"><SelfDrivingCarTry/></div>
		default:
			return <div />;
	}
}
export default Try;