import React, { useEffect } from 'react';
import { Row, Table, Icon } from 'antd';
import moment from 'moment';

import { ContextStore } from '../../../store/ContextStore';
import { reportLang } from '../../../languages/report.language';
import { 
	S_REPORT,
} from '../../../constants/tracker.constant';
import { getAllReport } from '../../../actions/report.action';
import { startAppLoading, endAppLoading } from '../../../actions/dom.action';
import { trackerWithPageView } from '../../../actions/app.action';
import { colors } from '../../styles';
import StatisticBox from './StatisticBox';

function Report(props) {
	const { dispatch, dom, report } = React.useContext(ContextStore);

	const styles = {
		title: {
			fontSize: `24px`,
			lineHeight: `36px`,
			fontWeight: `600`,
			color: colors.gray1,
		},
		subTitle: {
			fontSize: `18px`,
			lineHeight: `22px`,
			color: colors.gray2,
			marginBottom: `24px`,
			marginTop: `64px`,
		},
		statisticTitle: {
			textTransform: `uppercase`,
			fontWeight: `500`,
			letterSpacing: `0.05em`,
		}
	}

	let progressCol = [
		{
			title: reportLang.project,
			dataIndex: 'title',
			key: 'title',
			render: text => <span style={{fontWeight: `500`}}>{text}</span>,
		}
	];

	for(let i in report.cols){
		if (report.cols[i].title==="Assessment") continue;
		progressCol.push({
			title: report.cols[i].title,
			dataIndex: report.cols[i].key,
			key: report.cols[i].key,
			render: boolean => boolean && <Icon type="check" style={{color: colors.success2}} />,
		});
	}

	progressCol.push({
		title: reportLang.time_spent,
		dataIndex: 'duration',
		key: 'duration',
		render: text => moment.duration(text).humanize()
	});

	const assessmentCol = [
		{
			title: reportLang.project,
			dataIndex: 'title',
			key: 'title',
			render: text => <span style={{fontWeight: `500`}}>{text}</span>,
		},
		{
			title: reportLang.score,
			dataIndex: 'score',
			key: 'score',
		},
		{
			title: reportLang.time_spent,
			dataIndex: 'assessment',
			key: 'assessment',
			render: text => moment.duration(text).humanize()
		},
	];

	const statistic = [
		{
			title: reportLang.average_time,
			value: moment.duration(report.avgTime).humanize(),
		},
		{
			title: reportLang.completed_projects,
			value: report.completedProjects,
		},
		{
			title: reportLang.completed_assessments,
			value: report.completedAssessments,
		},
	];

	async function handleGetReport(){
		dispatch(startAppLoading());
		dispatch(await getAllReport());
		dispatch(endAppLoading());
	}

	useEffect(() => {
		if(!dom.loading){
			handleGetReport();
			trackerWithPageView(S_REPORT);
		}
	}, []);

	const boxes = statistic.map((unit, index) => (
		<StatisticBox 
			key={index}
			boxStyle={{marginTop: `20px`}} 
			innerStyle={{padding: `20px 24px`}} 
			titleStyle={styles.statisticTitle}
			title={unit.title} 
			value={unit.value} 
		/>
	));

	return (
		<div style={{width: `100%`}}>
			<div style={styles.title}>{reportLang.report}</div>
			<Row gutter={16}>{boxes}</Row>

			<div style={styles.subTitle}>{reportLang.learning_progress}</div>
			<Table 
				columns={progressCol} 
				dataSource={report.projects} 
				pagination={false} 
				scroll={{x: true}} 
			/>

			<div style={styles.subTitle}>{reportLang.assessment}</div>
			<Table 
				columns={assessmentCol} 
				dataSource={report.projects} 
				pagination={false} 
				scroll={{x: true}} 
			/>
		</div>
	);
}
export default Report;
