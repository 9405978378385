import React, { useEffect } from 'react';
import { Row, Col, Card } from 'antd';

import { ContextStore } from '../../../store/ContextStore';
import { projectsLang } from '../../../languages/projects.language';
import { trackerWithPageView } from '../../../actions/app.action';
import { getPopularProjects } from '../../../actions/project.action';
import { startAppLoading, endAppLoading } from '../../../actions/dom.action';
import { 
	S_AIART_COURSE
} from '../../../constants/tracker.constant';
import { getCourse } from '../../projects';
import { colors, text, shadows } from '../../styles';
import Label from '../Common/Label';
import RelatedProjects from './RelatedProjects';
import Summary from './Summary';
import Step from './Step';

function Overview(props) {
	const { dispatch, dom, project } = React.useContext(ContextStore);

	const styles = {
		root: {
			boxShadow: shadows.elevation5,
			borderRadius: `8px`,
		},
		title: {
			...text.courseOutlineTitle,
			color: colors.gray1,
		},
		description: {
			marginTop: `16px`,
			fontSize: `14px`,
			lineHeight: `22px`,
			color: colors.gray2,
		},
	}

	async function handleGetProjects(){
		dispatch(startAppLoading());
		dispatch(await getPopularProjects(false));
		dispatch(endAppLoading());
	}

	useEffect(() => {
		if(!dom.loading){
			handleGetProjects();
			trackerWithPageView(S_AIART_COURSE);
		}
	}, []);

	let lessons = [];
	const tag = project.overview.tag.map(unit => <Label outlined key={unit} text={unit} />);

	for(let i in project.overview.lessons){
		const unit = project.overview.lessons[i];
		const {icon} = getCourse(unit.title);
		lessons.push(
			<Step 
				key={unit.uuid}
				index={lessons.length + 1} 
				title={unit.title} 
				icon={icon} 
				content={unit.description} 
				success={unit.completed} 
			/>
		);
	}

	window.scrollTo(0, 0)

	return (
		<div style={{width: `100%`}}>
			<Row gutter={24}>
				<Col md={24} lg={16} style={{marginBottom: `24px`}}>
					<Card bordered={false} style={styles.root} bodyStyle={{padding: `25px 24px 20px 24px`}}>
						<div style={styles.title}>{projectsLang.overview}</div>
						<div style={{margin: `17px 0px 0px -8px`}}>{tag}</div>
						<div 
							id="project-description" 
							style={styles.description} 
							dangerouslySetInnerHTML={{__html: `${project.overview.description}`}} 
						/>
						
						{
							project.overview.lessons.length>0 &&
							<div style={{marginTop: `41px`}}>
								<div style={styles.title}>{projectsLang.curriculum}</div>
								<div style={{marginTop: `17px`}}>{lessons}</div>
							</div>
						}
					</Card>
				</Col>

				<Col md={24} lg={8}>
					<Summary
						history={props.history}
						hours={project.overview.teachingHours}
						duration={project.overview.duration}
						review={project.overview.reviewAssessment}
						permitted={project.overview.permitted}
					/>
					{
						project.overview.relatedProjects.length>0 &&
						<RelatedProjects projects={project.overview.relatedProjects} />
					}
				</Col>
			</Row>
		</div>
	);
}
export default Overview;
