import React from 'react';
import { Card, Modal } from 'antd';

import { ContextStore } from '../../../store/ContextStore';
import { projectsLang } from '../../../languages/projects.language';
import { getProjectLessons } from '../../../actions/project.action';
import { startAppLoading, endAppLoading, visitLessonPage } from '../../../actions/dom.action';
import { trackerWithEvent, parseProjectUrl } from '../../../actions/app.action';
import { isAuthorized } from '../../../reducers/rules/auth.rules';
import { ROUTE_LEARN, ROUTE_LOGIN } from '../../../constants/app.constant';
import { 
	CLICK_AIART_ENROLL
} from '../../../constants/tracker.constant';
import { ReactComponent as ClockSvg } from '../../../assets/outline-clock.svg';
import { ReactComponent as ClipboardSvg } from '../../../assets/outline-clipboard.svg';
import { ReactComponent as LockSvg } from '../../../assets/lock.svg';
import { colors, shadows } from '../../styles';
import Button from '../Common/Button';

function Summary(props) {
	const { dispatch, auth, project } = React.useContext(ContextStore);
	const [values, setValue] = React.useState({
		modal: false,
	});

	const styles = {
		root: {
			boxShadow: shadows.elevation5,
			borderRadius: `8px`,
			marginBottom: `24px`,
		},
		summaryIcon: {
			width: `20px`, 
			height: `20px`,
			marginRight: `12px`,
		},
		summaryItem: {
			marginBottom: `32px`,
		},
		title: {
			position: `relative`,
			top: `-5px`,
			color: colors.gray2,
			fontSize: `14px`,
			lineHeight: `19px`,
		},
		value: {
			float: `right`,
			color: colors.gray1,
			fontSize: `14px`,
			lineHeight: `19px`,
		},
		button: {
			marginBottom: `30px`,
		},
		lock: {
			width: `16px`, 
			height: `16px`,
			marginLeft: `6px`,
		},
		modal: {
			textAlign: `center`,
			padding: `32px`,
		},
		modalTitle: {
			fontWeight: `600`,
			fontSize: `16px`,
			lineHeight: `22px`,
			color: colors.gray1,
		},
		modalDescription: {
			margin: `8px 0px 16px 0px`,
			fontSize: `14px`,
			lineHeight: `20px`,
			color: colors.gray2,
		}
	}

	function handleOpenModal() {
		setValue({
			...values,
			modal: true
		});
	}

	function handleCloseModal() {
		setValue({
			...values,
			modal: false
		});
	}

	function handleLogin() {
		trackerWithEvent(CLICK_AIART_ENROLL, '', 0); // login status
		localStorage.setItem('login.location', props.history.location.pathname);
		props.history.push(ROUTE_LOGIN);
	}

	async function handleClickStart() {
		dispatch(visitLessonPage(true));
		dispatch(startAppLoading());
		const path = parseProjectUrl(project.overview.name, ROUTE_LEARN);
		trackerWithEvent(CLICK_AIART_ENROLL, '', 1); // login status
		dispatch(await getProjectLessons(project.overview.id));
		props.history.push(path);
		dispatch(endAppLoading());
		return;
	}

	function parseEnrollButton() {
		if(!project.overview.moduleKey)
			return <Button block type="primary" disabled={true}>{projectsLang.coming_soon}</Button>;
		else if(!isAuthorized(auth))
			return <Button block type="primary" onClick={handleLogin}>{projectsLang.login_to_enroll}</Button>;
		else if(!project.overview.permitted)
			return <Button block type="primary" onClick={handleOpenModal}>{projectsLang.enroll_course}<LockSvg style={styles.lock} /></Button>;
		else if(project.overview.enrolled)
			return <Button block type="primary" onClick={handleClickStart}>{projectsLang.start_course}</Button>;
		else
			return <Button block type="primary" onClick={handleClickStart}>{projectsLang.enroll_course}</Button>;
	}

	return (
		<Card bordered={false} style={styles.root} bodyStyle={{padding: `24px 24px 0px 24px`}}>
			<div style={styles.button}>{ parseEnrollButton() }</div>
			<div style={styles.summaryItem}>
				<ClockSvg style={styles.summaryIcon} />
				<span style={styles.title}>{projectsLang.teaching_hours}</span>
				<span style={styles.value}>{props.hours}</span>
			</div>
			<div style={styles.summaryItem}>
				<ClipboardSvg style={styles.summaryIcon} />
				<span style={styles.title}>{projectsLang.review_assessment}</span>
				<span style={styles.value}>{props.review ==="included" ? projectsLang.included : projectsLang.excluded}</span>
			</div>
			<Modal
				visible={values.modal}
				closable
				onCancel={handleCloseModal}
				footer={null}
				bodyStyle={styles.modal}
				width={420}
			>
				<img alt="locked course" src="/images/locked-course.png" />
				<div style={styles.modalTitle}>{projectsLang.oh_dear}</div>
				<div style={styles.modalDescription}>{projectsLang.contact_your_teacher}</div>
			</Modal>
		</Card>
	);
}

export default Summary;
