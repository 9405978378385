import React from 'react';
import { CarouselProvider, Slider, Slide, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { ContextStore } from '../../../store/ContextStore';
import { projectsLang } from '../../../languages/projects.language';
import { ROUTE_REVIEW, ROUTE_LEARN } from '../../../constants/app.constant';
import { 
	CLICK_AIART_LEARN_NEXT_SLIDE,
	CLICK_AIART_LEARN_PREVIOUS_SLIDE,
	CLICK_AIART_LEARN_FULLSCREEN,
	CLICK_AIART_REVIEW_NEXT_SLIDE,
	CLICK_AIART_REVIEW_PREVIOUS_SLIDE,
	CLICK_AIART_REVIEW_FULLSCREEN,
} from '../../../constants/tracker.constant';
import { controlLightbox } from '../../../actions/dom.action';
import { trackerWithEvent } from '../../../actions/app.action';
import { ReactComponent as LeftSvg } from '../../../assets/chevron-left.svg';
import { ReactComponent as RightSvg } from '../../../assets/chevron-right.svg';
import { ReactComponent as MaximizeSvg } from '../../../assets/maximize.svg';
import { colors, shadows } from '../../styles';

function Carousel(props) {
	const { dispatch } = React.useContext(ContextStore);
	const [values, setValue] = React.useState({
		slide: 0,
	});

	const styles = {
		root: {
			boxShadow: shadows.elevation5,
		},
		controlBar: {
			backgroundColor: colors.gray2,
			color: colors.white,
			height: `40px`,
			display: `flex`,
			justifyContent: `center`,
			alignItems: `center`,
			position: `relative`,
		},
		page: {
			margin: `0px 16px`,
		},
		back: {
			cursor: `pointer`,
			height: `24px`,
		},
		maximize: {
			cursor: `pointer`,
			position: `absolute`,
			right: `15px`,
			height: `24px`,
		},
		slide: {
			backgroundColor: colors.gray5,
		},
		image: {
			width: `auto`,
			height: `auto`,
			maxWidth: `100%`,
			maxHeight: `100%`,
			margin: `auto`,
		}
	}

	function handleNext() {
		if(values.slide + 1 < props.slides.length){
			switch(props.route){
				case ROUTE_LEARN:
					trackerWithEvent(CLICK_AIART_LEARN_NEXT_SLIDE, props.slides[values.slide+1].content_uuid); // slide id
					break;
				case ROUTE_REVIEW:
					trackerWithEvent(CLICK_AIART_REVIEW_NEXT_SLIDE, props.slides[values.slide+1].content_uuid); // slide id
					break;
				default:
					break;
			}
			setValue({...values, slide: values.slide+1});
		}
	}

	function handleBack() {
		if(values.slide > 0){
			switch(props.route){
				case ROUTE_LEARN:
					trackerWithEvent(CLICK_AIART_LEARN_PREVIOUS_SLIDE, props.slides[values.slide-1].content_uuid); // slide id
					break;
				case ROUTE_REVIEW:
					trackerWithEvent(CLICK_AIART_REVIEW_PREVIOUS_SLIDE, props.slides[values.slide-1].content_uuid); // slide id
					break;
				default:
					break;
			}
			setValue({...values, slide: values.slide-1});
		}
	}

	function handleMaximize() {
		switch(props.route){
			case ROUTE_LEARN:
				trackerWithEvent(CLICK_AIART_LEARN_FULLSCREEN, props.slides[values.slide].content_uuid); // slide id
				break;
			case ROUTE_REVIEW:
				trackerWithEvent(CLICK_AIART_REVIEW_FULLSCREEN, props.slides[values.slide].content_uuid); // slide id
				break;
			default:
				break;
		}
		dispatch(controlLightbox(true, props.slides, props.slides[values.slide].src));
	}

	const slides = props.slides.map((unit, index) => <Slide key={index} index={index} style={styles.slide}><Image style={styles.image} src={unit.src} /></Slide>);

	return (
		<CarouselProvider
			id="project-carousel"
			naturalSlideWidth={100}
			naturalSlideHeight={56}
			totalSlides={props.slides.length}
			currentSlide={values.slide}
			dragEnabled={false}
			touchEnabled={false}
			infinite="false"
			style={styles.root}
		>
			<Slider>
				{slides}
			</Slider>
			<div style={styles.controlBar}>
				<span style={styles.back} onClick={handleBack}><LeftSvg /></span>
				<span style={styles.page}>{projectsLang.formatString(projectsLang.page_of, (values.slide + 1), props.slides.length)}</span>
				<span style={styles.back} onClick={handleNext}><RightSvg /></span>
				<span style={styles.maximize} onClick={handleMaximize}><MaximizeSvg /></span>
			</div>
		</CarouselProvider>
	);
}
export default Carousel;
