import { colors } from '../../styles';

export const init = async () => {
  window.languagePluginUrl = "https://pyodide.cdn.iodide.io/";
  const script = document.createElement("script");
  script.src = "/pyodide/pyodide.js";
  script.async = true;
  document.body.appendChild(script);
  return true;
}

export const runPython = (id, code) => {
  const parent = document.querySelector("#"+id+" .ant-card-body");

  window.languagePluginLoader.then(() => {
    try{
      window.pyodide.runPythonAsync(code, (data) => {
        parseResult(parent, data);
      }).then(result => {
        parseResult(parent, result)
      }, (error) => {
        parseResult(parent, error, true);
      })
    }
    catch(e){
      parseResult(parent, e, true);
    }

    window.iodide = {
      output: {
        element: (tagName) => {
          let elem = document.createElement(tagName);
          parent.appendChild(elem);
          return elem;
        }
      }
    };
  });
}

export const validate = (code, callback) => {
  window.languagePluginLoader.then(() => {
    try{
      window.pyodide.runPythonAsync(code, (data) => {
        callback(data);
      }).then(result => {
        callback(result);
      }, (error) => {
        callback(false);
      })
    }
    catch(e){
      callback(false);
    }
  });
}

const parseResult = (parent, data, error=false) => {
  if(!data) return;

  let elem = document.createElement('div');
  elem.innerHTML = data;
  if(error)
    elem.style.color = colors.danger2;

  parent.appendChild(elem);
  return elem;
}

export default {
  init,
  runPython,
  validate,
};
