import React, { useEffect } from 'react';
import { Tabs } from 'antd';

import { ContextStore } from '../../../../store/ContextStore';
import { projectsLang } from '../../../../languages/projects.language';
import { trackerWithPageView, trackerWithEvent } from '../../../../actions/app.action';
import { 
	S_AIART_INSPECT,
	CLICK_AIART_INSPECT_TAB,
} from '../../../../constants/tracker.constant';
import { colors } from '../../../styles';
import { fontStyles } from '../styles';
import FlowChart from './FlowChart';
import TabContent from './TabContent';

function Inspect(props) {
	const { project } = React.useContext(ContextStore);

	let treeToArray = parseTreeData(project.curriculum.inspect.tree, []);
	function parseTreeData(data, parent) {
		for(let i in data){
			if(data[i].content)
				parent.push(data[i].content);
			if(data[i].children.length>0){
				parent.join(parseTreeData(data[i].children, parent));
			}
		}
		return parent;
	}

	let tabStatus = {};
	for(let i in treeToArray){
		tabStatus[treeToArray[i].uuid] = false;
	}
	const [values, setValue] = React.useState({
		...tabStatus,
		currentTab:  "overview",
		init: false,
	});

	const styles = {
		tabPane: {
			background: colors.white,
		},
		tabBar: {
			border: `none`, 
			margin: `0px 32px`,		
		}
	}

	function onChange(activeKey) {
		trackerWithEvent(CLICK_AIART_INSPECT_TAB, activeKey); // tab id

		setValue({
			...values,
			currentTab: activeKey,
		});
	}

	function onEdit(tabName, action) {
		let value = {};
		value[tabName] = false;

		if(action==='remove'){
			setValue({
				...values,
				...value,
				currentTab: "overview",
			});
		}
	}

	function handleOpenTab(tabName){
		trackerWithEvent(CLICK_AIART_INSPECT_TAB, tabName); // tab id
		let value = {};
		value[tabName] = true;

		setValue({
			...values,
			...value,
			currentTab: tabName,
		});
	}

	useEffect(() => {
		if(!values.init){
			trackerWithPageView(S_AIART_INSPECT);
			setValue({
				...values,
				init: true,
			});
		}
	}, [values]);

	const tabs = treeToArray.map(function (unit, index) {
		if(values[unit.uuid])
			return (
				<Tabs.TabPane key={unit.uuid} style={styles.tabPane} tab={unit.introduction} closable={true}>
					<TabContent data={{type: "text", src: unit.introduction}} />
				</Tabs.TabPane>
			);
		else
			return false;
	});

	return (
		<div style={{width: `100%`}}>
			<div style={{...fontStyles.heading1, marginBottom: `20px`}}>{projectsLang.inspect}</div>

			<div className="card-container" style={{margin: `0px -32px -24px -32px`}}>
				<Tabs 
					type="editable-card"
					hideAdd
					activeKey={values.currentTab}
					onChange={onChange} 
					onEdit={onEdit}
					tabBarStyle={styles.tabBar}
				>
					<Tabs.TabPane style={styles.tabPane} tab={projectsLang.tab_overview} key="overview" closable={false}>
						<FlowChart 
							data={project.curriculum.inspect.tree} 
							open={handleOpenTab}
							name={project.overview.name}
						/>
					</Tabs.TabPane>
					{tabs}
				</Tabs>
			</div>
		</div>
	);
}
export default Inspect;
