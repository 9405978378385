import * as controller from "./controller"

let ReactUICallback = null;

export default function bridge(inputJSONStr, callback) {
  // <---- set callback to update graph
  ReactUICallback = callback;	
  
  // console.log ("bridge : ", inputJSONStr);
  let inputJSON = JSON.parse(inputJSONStr);
  let responseStr = "";

  switch (inputJSON.action) {
    case "initRLConfig":
      responseStr = controller.initRLConfig(inputJSON.request);
      break;
    case "resetRL":
      responseStr = controller.resetRL(inputJSON.request);
      break;
    case "setReward":
      responseStr = controller.setRLReward(inputJSON.request);
      break;
    case "chooseTrainingAction":
      responseStr = controller.chooseTrainingAction(inputJSON.request);
      break;
    case "chooseEvaluationAction":
      responseStr = controller.chooseEvaluationAction(inputJSON.request);
      break;
    case "startSimulation":
      responseStr = controller.startRLSimulation(inputJSON.request);
      break;
    case "startEpisode":
      responseStr = controller.startRLEpisode(inputJSON.request);
      break;
    case "endEpisode":
      responseStr = controller.endRLEpisode(inputJSON.request);
      break;
    case "postEndEpisode":
      responseStr = controller.postEndRLEpisode(inputJSON.request);
      break;
    default:
      responseStr = controller.getDefaultResponse();
      break;
  }
  return responseStr;
}

// For updating the graph
export function GraphUpdateCallback(action, payload) {
  ReactUICallback(action, payload);
};