import React from 'react';
import { Layout } from 'antd';
import { publicLang } from '../../../languages/public.language';
import { colors } from '../../styles'; 

function MainFooter(props) {
	const styles = {
		root: {
			textAlign: `center`,
			color: colors.gray3,
		}
	}

	return (
		<Layout.Footer style={styles.root}>
			{publicLang.formatString(publicLang.footer, new Date().getFullYear())}
		</Layout.Footer>
	);
}
export default MainFooter;
