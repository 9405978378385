import React from 'react';
import { Card } from 'antd';

import { projectsLang } from '../../../languages/projects.language';
import { colors, shadows } from '../../styles';
import Label from '../Common/Label';

function RelatedProjects(props) {
	const styles = {
		root: {
			boxShadow: shadows.elevation5,
			borderRadius: `8px`,
		},
		title: {
			fontSize: `14px`,
			lineHeight: `21px`,
			fontWeight: `600`,
			letterSpacing: `0.05em`,
			color: colors.gray2,
			marginBottom: `22px`,
		},
		projectImg: {
			width: `96px`,
			height: `64px`,
			borderRadius: `4px`,
			marginRight: `16px`,
		},
		projectName: {
			color: colors.gray1,
			fontSize: `14px`,
			lineHeight: `21px`,
			marginRight: `8px`,
		},
	}

	const projects = props.projects.map(unit => (
		<a 
			key={unit.id} 
			style={{marginBottom: `16px`, display: `block`}} 
			href={unit.url} 
			target="_blank"
			rel="noopener noreferrer"
		>
			<img alt="projects" style={styles.projectImg} src={unit.image} />
			<span style={styles.projectName}>{unit.name}</span>
			{unit.product && <Label outlined text={unit.product} />}
		</a>
	));

	return (
		<Card bordered={false} style={styles.root} bodyStyle={{padding: `18px 24px 0px 24px`}}>
			<div style={styles.title}>{projectsLang.related_projects}</div>
			{projects}
		</Card>
	);
}

export default RelatedProjects;
