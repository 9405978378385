import {
	ACTION_START_APP_LOADING,
	ACTION_END_APP_LOADING,
	ACTION_LIGHTBOX,
	ACTION_WELCOME,
	ACTION_VISIT_LESSON,
} from '../constants/dom.constant';
 
function domReducer(state, action) {
	const payload = action.payload;
	switch (action.key) {
		case ACTION_START_APP_LOADING:
		case ACTION_END_APP_LOADING:
			return Object.assign({}, state, {
					...state,
					loading: payload
				});
		case ACTION_LIGHTBOX:
			if(payload.isOpened){
				for(var i=0; i<payload.imageSources.length; i++){
					if(payload.imageSources[i].src===payload.selectedSource){
						return Object.assign({}, state, {
									...state,
									lightbox:{
										...state.lightbox,
										isOpened: true,
										imageSources: payload.imageSources,
										currentIndex: i,
									},
								});
					}
				}
			}
			else{
				return Object.assign({}, state, {
							...state,
							lightbox:{
								...state.lightbox,
								isOpened: false,
							},
						});
			}
		break;
		case ACTION_WELCOME:
			if(!payload.isOpened){
				localStorage.setItem('welcome.dismissed', true);
			}
			return Object.assign({}, state, {
				...state,
				welcome:{
					isOpened: payload.isOpened,
				},
			});
		case ACTION_VISIT_LESSON:
			return Object.assign({}, state, {
					...state,
					lesson: payload
				});
		default:
			return state;
	}
}
export default domReducer;
