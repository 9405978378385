import {
	ACTION_PROJECT_POPULAR_PROJECTS,
	ACTION_PROJECT_OVERVIEW,
	ACTION_PROJECT_LESSON,
	ACTION_PROJECT_RESULT,
	ACTION_AI_ART_TRY,
} from '../constants/project.constant';
 
function projectReducer(state, action) {
	const payload = action.payload;
	switch (action.key) {
		case ACTION_PROJECT_POPULAR_PROJECTS:
			const projects = parseProjects(payload);
			const overview = updateOverview(projects, state.overview);
			localStorage.setItem('project.popularProjects', JSON.stringify(projects));
			localStorage.setItem('project.overview', JSON.stringify(overview));
			return Object.assign({}, state, {
					...state,
					popularProjects: projects,
					overview: overview,
				});
		case ACTION_PROJECT_OVERVIEW:
			localStorage.setItem('project.overview', JSON.stringify(payload));
			return Object.assign({}, state, {
					...state,
					overview: payload
				});
		case ACTION_PROJECT_LESSON:
			const lessons = parseLessonContent(payload, state.overview.lessons);
			const codeEditorUrl = payload.api.learnUrl;
			localStorage.setItem('project.curriculum', JSON.stringify(lessons));
			localStorage.setItem('project.codeEditorUrl', payload.api.learnUrl);
			return Object.assign({}, state, {
					...state,
					curriculum: lessons,
					codeEditorUrl: codeEditorUrl,
				});
		case ACTION_AI_ART_TRY:
			const output = parseStyleTransfer(payload);
			localStorage.setItem('project.curriculum.try.output', output);
			return Object.assign({}, state, {
					...state,
					curriculum: {
						...state.curriculum,
						try: {
							...state.curriculum.try,
							output: output,
						}
					}
				});
		case ACTION_PROJECT_RESULT:
			const updatedResult  = updateCurriculum(payload, state.overview, state.curriculum);
			localStorage.setItem('project.overview', JSON.stringify(updatedResult.overview));
			localStorage.setItem('project.curriculum', JSON.stringify(updatedResult.curriculum));
			return Object.assign({}, state, {
					...state,
					overview: updatedResult.overview,
					curriculum: updatedResult.curriculum,
				});
		default:
			return state;
	}
}
export default projectReducer;

function parseProjects(payload){
	return payload.map(function(unit, index, array){
		const title = unit.titles.filter(x => x.locale==='en-US');
		const description = unit.descriptions.filter(x => x.locale==='en-US');
		const tags = unit.tags.map(({title}) => title);
		const relatedProjects = unit.relatedProjects.length>0 ? parseRelatedProjects(unit.relatedProjects) : [];

		return {
			id: unit.uuid,
			name: title[0].text,
			description: description[0].text,
			tag: tags,
			color: JSON.parse(unit.styles) ? JSON.parse(unit.styles)['background-color'] : '#ddd',
			image: JSON.parse(unit.styles) ? JSON.parse(unit.styles).icon : '',
			teachingHours: unit.teaching_hours+" hours",
			duration: unit.duration+" hours",
			reviewAssessment: unit.review_assessment,
			relatedProjects: relatedProjects,
			lessons: unit.lessons,
			permitted: unit.permitted,
			moduleKey: unit.module_key,
			enrolled: unit.enrolled,
		};
	});
}

function parseRelatedProjects(payload){
	return payload.map(function(unit, index, array){
		const title = unit.titles.filter(x => x.locale==='en-US');
		return {
			id: unit.uuid,
			name: title[0].text,
			product: unit.description,
			url: unit.url,
			image: unit.image_src,
		};
	});
}

function parseLessonContent(payload, lessons){
	let data = {};
	for(let i in payload.api.lessons){
		let unit = payload.api.lessons[i];
		const lesson = lessons.filter(x => x.project_lesson_uuid===unit.lesson_uuid);
		if(lesson.length===0) continue
		const name = lesson[0].title.toLowerCase();
		const content = parseContent(unit.lessonContent)
		data[name] = {
			...payload.data[name],
			type: unit.contentType, 
			content: content,
			result: unit.result,
			duration: unit.duration,
			id: lesson[0].project_lesson_uuid,
			jupyter: payload.api.challengeUrl
		}
	}
	return data;
}

function parseContent(payload){
	return payload.map(function(unit, index, array){
		const title = unit.titles.filter(x => x.locale==='en-US');
		const content = unit.contents.filter(x => x.locale==='en-US');
		const description = unit.descriptions.filter(x => x.locale==='en-US');
		const question = unit.question_uuid ? parseQuestion(unit.question, unit.question_uuid) : null;
		const tag = unit.tags.length > 0 ? unit.tags[0].title : "";

		return {
			key: unit.uuid, // user_result.uuid
			content_uuid: unit.content_uuid, // project_lesson_contents.uuid
			type: unit.type,
			name: title[0].text,
			description: description[0] ? description[0].text : "",
			src: content[0].text,
			is_correct: unit.is_correct, // user_result.is_correct
			question: question,
			tag: tag,
		};
	});
}

function parseQuestion(payload, uuid){
	if(!payload) return {};
	const image = payload.images.filter(x => x.locale==='en-US');
	const content = payload.contents.filter(x => x.locale==='en-US');
	const introduction = payload.introductions.filter(x => x.locale==='en-US');
	const options = parseQuestionOption(payload.options);
	return {
		id: uuid, // question uuid
		options: options,
		type: payload.type,
		code: payload.code,
		pre_define: payload.pre_define,
		output_code: payload.output_code,
		expected_output: payload.expected_output,
		image: image[0] ? image[0].text : '',
		content: content[0].text,
		introduction: introduction[0].text,
		highlighted: payload.highlighted ? JSON.parse(payload.highlighted) : null,
	}
}

function parseQuestionOption(payload){
	return payload.map(function(unit, index, array){
		const content = unit.contents.filter(x => x.locale==='en-US');
		return {
			id: unit.uuid,
			content: content[0].text,
			correct: unit.correct,
		};
	});
}

function updateOverview(projects, overview){
	if(!overview) return {};
	for(let i in projects){
		if(projects[i].id === overview.id){
			return projects[i];
		}
	}
	return {};
}

function parseStyleTransfer(payload){
	return payload.outputImageUrl
}

function updateCurriculum(payload, overview, curriculum){
	for(let i in overview.lessons){
		const lesson = overview.lessons[i];
		const lessonResult = payload.filter(({lesson_uuid}) => lesson_uuid===lesson.project_lesson_uuid);
		if(lessonResult.length>0){
			const name = lesson.title.toLowerCase();
			curriculum[name].result = lessonResult[0].result;
			curriculum[name].duration = lessonResult[0].duration;
			overview.lessons[i].completed = lessonResult[0].is_correct;
			for(let j in curriculum[name].content){
				const content = curriculum[name].content[j];
				const contentResult = payload.filter(({content_uuid}) => content_uuid===content.content_uuid);
				if(contentResult.length > 0){
					curriculum[name].content[j].is_correct = contentResult[0].is_correct;
				}
			}
		}
	}
	return {
		curriculum: curriculum,
		overview: overview,
	};
}
