
const colors = {
	gray1: "#333333",
	gray2: "#656565",
	gray3: "#969696",
	gray4: "#C8C8C8",
	gray5: "#E1E1E1",
	gray6: "#FAFAFA",

	white: "#FFFFFF",

	primary1: "#2D9AA9",
	primary2: "#3EC0C5",
	primary3: "#6BDCD6",
	primary6: "#F8FFFF",

	success1: "#399C33",
	success2: "#56B647",
	success3: "#87D372",
	success6: "#F9FFF8",

	info1: "#1B73D8",
	info2: "#2594FC",
	info3: "#5BB7FD",
	info6: "#F8FCFF",

	danger1: "#DB394C",
	danger2: "#FF4F54",
	danger3: "#FF827A",
	danger6: "#FFF8F9",

	alert1: "#DBA005",
	alert2: "#FFC107",
	alert3: "#FFD545",
	alert4: "#FF792B",
	alert6: "#FFFDF8",

	code1: '#03102D',
	code2: '#061C3F',
};

const text = {
	heading1: {
		fontWeight: `600`,
		fontSize: `40px`,
		lineHeight: `48px`,
	},
	heading2: {
		fontWeight: `500`,
		fontSize: `28px`,
		lineHeight: `42px`,
	},
	heading3: {
		fontWeight: `600`,
		fontSize: `24px`,
		lineHeight: `36px`,
	},
	headingHighlight: {
		fontWeight: `600`,
		fontSize: `16px`,
		lineHeight: `24px`,
		letterSpacing: `0.05em`,
		textTransform: `uppercase`,
	},
	sectionTitle: {
		fontWeight: `600`,
		fontSize: `20px`,
		lineHeight: `30px`,
		textTransform: `capitalize`,
	},
	courseOutlineTitle: {
		fontSize: `18px`,
		lineHeight: `22px`,
		fontWeight: `600`,
		textTransform: `capitalize`,
	},
	specTitle: {
		fontSize: `24px`,
		lineHeight: `36px`,
	}, 
	description: {
		fontSize: `14px`,
		lineHeight: `19px`,
	},
}

const shadows = {
	elevation1: `0px 12px 24px rgba(0, 0, 0, 0.06)`,
	elevation2: `0px 6px 12px rgba(0, 0, 0, 0.08)`,
	elevation3: `0px 4px 8px rgba(0, 0, 0, 0.1)`,
	elevation4: `0px 2px 4px rgba(0, 0, 0, 0.12)`,
	elevation5: `0px 1px 2px rgba(0, 0, 0, 0.15)`,
};

export {
	colors,
	text,
	shadows,
}
