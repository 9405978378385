import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { disableScalableOnIOS } from './utils/app';

// <----- compatible with IE methods
import 'es6-promise/auto';
import 'isomorphic-fetch';

ReactDOM.render(<App />, document.getElementById('root'), function(){ disableScalableOnIOS(); });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

window.onbeforeunload = function() {
	if(localStorage.getItem('auth.remeberMe')!=='true'){
		const dismissed = localStorage.getItem('welcome.dismissed');
		localStorage.clear();
		localStorage.setItem('welcome.dismissed', dismissed);
	}
}
