let episodeTotalRewardsInTraining = [];
let episodeFinalDistancesInTraining = [];

let trainingLogs = [];
let trainingExplorationRates = [];

export let noOfFinishedEpisodeInTraining = 0;
let noOfTrainedRound = 0;

let episodeRewardsInEvaluation = [];
let episodeFinalDistancesInEvaluation = [];

export let noOfFinishedEpisodeInEvaluation = 0;

export function resetStats(isInitTrainingStats, isInitEvaluationStats) {
	if (isInitTrainingStats) {
		episodeTotalRewardsInTraining = [];
		episodeFinalDistancesInTraining = [];

		trainingLogs = [];
		trainingExplorationRates = [];

		noOfFinishedEpisodeInTraining = 0;
		noOfTrainedRound = 0;
	}

	if (isInitEvaluationStats) {
		episodeRewardsInEvaluation = [];
		episodeFinalDistancesInEvaluation = [];

		noOfFinishedEpisodeInEvaluation = 0;
	}
}

export function resetNoOfFinishedEpisode(isInitTrainingStats, isInitEvaluationStats) {
	if (isInitTrainingStats) {
		noOfFinishedEpisodeInTraining = 0;
	}

	if (isInitEvaluationStats) {
		noOfFinishedEpisodeInEvaluation = 0;
	}
}

export function addTrainingEpisodeStats(episodeTotalReward, episodeFinalDistance, isGoingToTrain, explorationRate) {//, learningRate) {
	if (!episodeTotalRewardsInTraining[noOfTrainedRound]) {
		episodeTotalRewardsInTraining[noOfTrainedRound] = [];
	}

	if (!episodeFinalDistancesInTraining[noOfTrainedRound]) {
		episodeFinalDistancesInTraining[noOfTrainedRound] = [];
	}

	let episodeRewardsArray = episodeTotalRewardsInTraining[noOfTrainedRound];
	episodeRewardsArray.push(episodeTotalReward);
	episodeTotalRewardsInTraining[noOfTrainedRound] = episodeRewardsArray;

	let episodeFinalDistancesArray = episodeFinalDistancesInTraining[noOfTrainedRound];
	episodeFinalDistancesArray.push(episodeFinalDistance);
	episodeFinalDistancesInTraining[noOfTrainedRound] = episodeFinalDistancesArray;

	if (isGoingToTrain) {
		trainingExplorationRates.push(explorationRate);
	}
}

export function addFinishedEpisodeInTrainingCount() {
	noOfFinishedEpisodeInTraining++;
}

export function addTrainedRoundCount() {
	noOfTrainedRound++;
}

export function addTrainingLog(log) {
	let index = noOfTrainedRound;
	if (!trainingLogs[index]) {
		trainingLogs[index] = [];
	}

	let trainingLogsArray = trainingLogs[index];
	trainingLogsArray.push(log);
	trainingLogs[index] = trainingLogsArray;
}

export function addEvaluationEpisodeStats(episodeTotalReward, episodeFinalDistance) {
	episodeRewardsInEvaluation.push(episodeTotalReward);
	episodeFinalDistancesInEvaluation.push(episodeFinalDistance);

	noOfFinishedEpisodeInEvaluation++;
}

function getLastEpisodeTotalReward(isTraining) {
	if (isTraining) {
		if (!episodeTotalRewardsInTraining || episodeTotalRewardsInTraining.length === 0) {
			return 0;
		} else {
			let rewardArray = episodeTotalRewardsInTraining[episodeTotalRewardsInTraining.length - 1];
			if (!rewardArray || rewardArray.length === 0) {
				return 0;
			} else {
				return rewardArray[rewardArray.length - 1];
			}
		}
	} else {
		if (!episodeRewardsInEvaluation || episodeRewardsInEvaluation.length === 0) {
			return 0;
		} else {
			return episodeRewardsInEvaluation[episodeRewardsInEvaluation.length - 1];
		}
	}
}

function getLastEpisodeFinalDisance(isTraining) {
	if (isTraining) {
		if (!episodeFinalDistancesInTraining || episodeFinalDistancesInTraining.length === 0) {
			return 0;
		} else {
			let distanceArray = episodeFinalDistancesInTraining[episodeFinalDistancesInTraining.length - 1];
			if (!distanceArray || distanceArray.length === 0) {
				return 0;
			} else {
				return distanceArray[distanceArray.length - 1];
			}
		}
	} else {
		if (!episodeFinalDistancesInEvaluation || episodeFinalDistancesInEvaluation.length === 0) {
			return 0;
		} else {
			return episodeFinalDistancesInEvaluation[episodeFinalDistancesInEvaluation.length - 1];
		}
	}
}
