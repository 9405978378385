export function defaultResponse(isSuccess) {
  let json = {
    response: {
      success: isSuccess,
    }
  };

  return JSON.stringify(json);
}

export function initRLConfigResponse(
  isSuccess,
  maxGameSpeed,
  carBrakingModeInt,
  actionPerSecond,
  maxInitialDistance,
  minInitialDistance,
  maxInitialVelocity,
  minInitialVelocity,
  episodeTerminationDistance,
  noOfEpisodeBeforeTrain,
  recommendedNoOfTrainToDo,
  noOfEvaluationTrial,
  maxSuccessDistance,
  minSuccessDistance,
  largestReward,
  smallestReward,
  rewardItems,
) {
  let json = {
    response: {
      success: isSuccess,
      config: {
        maxGameSpeed: maxGameSpeed,
        carBrakingModeInt: carBrakingModeInt,
        actionPerSecond: actionPerSecond,
        maxInitialDistance: maxInitialDistance,
        minInitialDistance: minInitialDistance,
        maxInitialVelocity: maxInitialVelocity,
        minInitialVelocity: minInitialVelocity,
        episodeTerminationDistance: episodeTerminationDistance,
        noOfEpisodeBeforeTrain: noOfEpisodeBeforeTrain,
        minSuccessDistance: minSuccessDistance,
        maxSuccessDistance: maxSuccessDistance,
        recommendedNoOfTrainToDo: recommendedNoOfTrainToDo,
        noOfEvaluationTrial: noOfEvaluationTrial,
        rewardInfo: {
          largestReward: largestReward,
          smallestReward: smallestReward,
          items: rewardItems
        }
      }
    }
  };

  return JSON.stringify(json);
}

export function resetRLResponse(isSuccess) {
  let json = {
    response: {
      success: isSuccess,
    }
  };

  return JSON.stringify(json);
}

export function setRewardResponse(isSuccess) {
  let json = {
    response: {
      success: isSuccess,
    }
  };

  return JSON.stringify(json);
}

export function chooseTrainingActionResponse(isSuccess, brakingForceFactor) {
  let json = {
    response: {
      success: isSuccess,
      action: {
        brakingForceFactor,
      }
    }
  };

  return JSON.stringify(json);
}

export function chooseEvaluationActionResponse(isSuccess, brakingForceFactor) {
  let json = {
    response: {
      success: isSuccess,
      action: {
        brakingForceFactor,
      }
    }
  };

  return JSON.stringify(json);
}

export function startSimulationResponse(isSuccess) {
  let json = {
    response: {
      success: isSuccess,
    }
  };

  return JSON.stringify(json);
}

export function startEpisodeResponse(isSuccess, episodeNo) {
  let json = {
    response: {
      success: isSuccess,
      episodeNo: episodeNo
    }
  };

  return JSON.stringify(json);
}

export function endEpisodeResponse(isSuccess, totalReward, finalDistance) {
  let json = {
    response: {
      success: isSuccess,
      result: {
        totalReward: totalReward,
        finalDistance: finalDistance,
      }
    }
  };

  return JSON.stringify(json);
}

export function postEndEpisodeResponse(isSuccess, isStopSimulation) {
  let json = {
    response: {
      success: isSuccess,
      isStopSimulation: isStopSimulation
    }
  };

  return JSON.stringify(json);
}
