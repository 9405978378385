import { projectsLang } from '../languages/projects.language';
import LearnSvg from '../assets/book-open.svg';
import TrySvg from '../assets/box.svg';
import InspectSvg from '../assets/code.svg';
import ReviewSvg from '../assets/clipboard.svg';
import ExploreSvg from '../assets/bookmark.svg';
import ChallengeSvg from '../assets/target.svg';
import AssessmentSvg from '../assets/file-text.svg';
import MonoLearnSvg from '../assets/book-open-mono.svg';
import MonoTrySvg from '../assets/box-mono.svg';
import MonoInspectSvg from '../assets/code-mono.svg';
import MonoReviewSvg from '../assets/clipboard-mono.svg';
import MonoExploreSvg from '../assets/bookmark-mono.svg';
import MonoChallengeSvg from '../assets/target-mono.svg';
import MonoAssessmentSvg from '../assets/file-text-mono.svg';
import {
  ROUTE_LEARN,
  ROUTE_TRY,
  ROUTE_INSPECT,
  ROUTE_REVIEW,
  ROUTE_EXPLORE,
  ROUTE_CHALLENGE,
  ROUTE_QUIZ,
} from '../constants/app.constant';

const courses = {
	Learn: {
		title: projectsLang.learn,
		description: projectsLang.learn_process,
		icon: LearnSvg,
		mono: MonoLearnSvg,
		route: ROUTE_LEARN,
		beta: false,
	},
	Try: {
		title: projectsLang.try,
		description: projectsLang.try_process,
		icon: TrySvg,
		mono: MonoTrySvg,
		route: ROUTE_TRY,
		beta: false,
	},
	Inspect: {
		title: projectsLang.inspect,
		description: projectsLang.inspect_process,
		icon: InspectSvg,
		mono: MonoInspectSvg,
		route: ROUTE_INSPECT,
		beta: true,
	},
	Review: {
		title: projectsLang.review,
		description: projectsLang.review_process,
		icon: ReviewSvg,
		mono: MonoReviewSvg,
		route: ROUTE_REVIEW,
		beta: false,
	},
	Explore: {
		title: projectsLang.explore,
		description: projectsLang.explore_process,
		icon: ExploreSvg,
		mono: MonoExploreSvg,
		route: ROUTE_EXPLORE,
		beta: false,
	},
	Challenge: {
		title: projectsLang.challenge,
		description: projectsLang.challenge_process,
		icon: ChallengeSvg,
		mono: MonoChallengeSvg,
		route: ROUTE_CHALLENGE,
		beta: false,
	},
	Assessment: {
		icon: AssessmentSvg,
		mono: MonoAssessmentSvg,
		route: ROUTE_QUIZ,
		beta: false,
	},
};

export const getCourse = (course) => {
	return courses[course] ? courses[course] : courses['Learn'];
}

export default {
	getCourse
}

