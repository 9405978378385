import { PROMISE_RUN } from '../constants/promise.constant';
import { message } from 'antd';
import { E_INVALID_JWT_TOKEN } from '../constants/auth.constant';
import { logout } from '../reducers/rules/auth.rules';
import { ROUTE_LOGIN } from '../constants/app.constant';

export const run = async (key, payload, options = { skipLoadingState: false }) => {
	if(payload.error && payload.error.message){
		if(payload.error.status===E_INVALID_JWT_TOKEN){
			logout();
			window.location = ROUTE_LOGIN+"#message="+E_INVALID_JWT_TOKEN;
		}else{
			message.error(payload.error.message);
		}
		return {}
	}

	return {
		type: PROMISE_RUN,
		key: key,
		payload: await payload.data,
		...options
	}
};

export default {
  run
};
