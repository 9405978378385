import React, { useEffect } from 'react';
import Auth from './Auth';
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import {
	defaultApp,
	defaultDom,
	defaultAuth,
	defaultProject,
	defaultReport,
	ContextStore
} from '../store/ContextStore';
import appReducer from "../reducers/app.reducer";
import domReducer from "../reducers/dom.reducer";
import authReducer from "../reducers/auth.reducer";
import projectReducer from "../reducers/project.reducer";
import reportReducer from "../reducers/report.reducer";
import Loading from "./Loading";
import ReactGA from 'react-ga';
import {
	GOOGLE_ANALYTICS,
	ROUTE_HOME,
} from '../constants/app.constant';
import { E_INVALID_JWT_TOKEN } from '../constants/auth.constant';
import { message } from 'antd';
import { errorLang } from '../languages/error.language';
import qs from 'query-string';
import { addHotjarIdentity } from '../actions/hotjar.action';

if(GOOGLE_ANALYTICS) ReactGA.initialize(GOOGLE_ANALYTICS);

function App() {
	const [appState, appDispatch] = React.useReducer(appReducer, defaultApp);
	const [domState, domDispatch] = React.useReducer(domReducer, defaultDom);
	const [authState, authDispatch] = React.useReducer(authReducer, defaultAuth);
	const [projectState, projectDispatch] = React.useReducer(projectReducer, defaultProject);
	const [reportState, reportDispatch] = React.useReducer(reportReducer, defaultReport);

	function combineDispatchs(dispatchs) {
		return function(obj){
			for (let i = 0; i < dispatchs.length; i++)
			dispatchs[i](obj);
		}
	}

	function parseMessage(){
		if(!(window.location.hash)) return null;
		const parameters = qs.parse(window.location.hash);
		window.location.hash = '';
		switch(parameters.message){
			case E_INVALID_JWT_TOKEN:
				return message.error(errorLang.response[parameters.message]);
			default:
				return null;
		}
	}

	useEffect(() => {

	});
	
	parseMessage();
	addHotjarIdentity(authState.userDisplayName);

	return (
		<div>
			<div>
				<ContextStore.Provider
				      value={{
				      	app: appState,
				      	dom: domState,
				        auth: authState,
				        project: projectState,
				        report: reportState,
				        dispatch: combineDispatchs([
				        		appDispatch, 
				        		domDispatch, 
				        		authDispatch, 
				        		projectDispatch, 
				        		reportDispatch,
				        	]),
				      }}
				    >
				    <Loading/>
					<BrowserRouter basename="" forceRefresh={false}>
						<Switch>
							<Route path={ROUTE_HOME} component={Auth} />
						</Switch>
					</BrowserRouter>
				</ContextStore.Provider>
			</div>
		</div>
  );
}
export default App;
