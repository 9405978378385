import { run } from '../utils/promise';
import { getApi } from '../utils/api';
import {
	ACTION_ALL_REPORT,
} from '../constants/report.constant';

export const getAllReport = async () => {
	const response = await getApi().get(`/project/report`);
	return run(
		ACTION_ALL_REPORT,
		response.data,
	);
}

export default {
	getAllReport,
};
