import {
  ACTION_START_APP_LOADING,
  ACTION_END_APP_LOADING,
  ACTION_LIGHTBOX,
  ACTION_WELCOME,
  ACTION_VISIT_LESSON,
 } from '../constants/dom.constant';

export const startAppLoading = () => ({
  key: ACTION_START_APP_LOADING,
  payload: true,
});

export const endAppLoading = () => ({
  key: ACTION_END_APP_LOADING,
  payload: false,
});

export const controlLightbox = (isOpened, imageSources, selectedSource) => ({
  key: ACTION_LIGHTBOX,
  payload: {
    isOpened: isOpened,
    imageSources: imageSources,
    selectedSource: selectedSource,
  },
});

export const showMessage = (message) => ({
  // !----- TODO: may show message in snackbar
});

export const controlWelcome = (isOpened) => ({
  key: ACTION_WELCOME,
  payload: {
    isOpened: isOpened,
  },
});

export const visitLessonPage = (visited) => ({
  key: ACTION_VISIT_LESSON,
  payload: visited,
});

export default {
  startAppLoading,
  endAppLoading,
  controlLightbox,
  showMessage,
  controlWelcome,
  visitLessonPage,
};
