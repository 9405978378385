import React, { useEffect } from 'react';
import Animate from 'rc-animate';

import { ContextStore } from '../../../../store/ContextStore';
import { projectsLang } from '../../../../languages/projects.language';
import { ROUTE_SUMMARY } from '../../../../constants/app.constant';
import { 
	S_AIART_ASSIGNMENT,
	CLICK_AIART_ASSESSMENT_SUBMIT,
	CLICK_AIART_ASSESSMENT_RESULT,
} from '../../../../constants/tracker.constant';
import { postLessonResult } from '../../../../actions/project.action';
import { trackerWithPageView, trackerWithEvent } from '../../../../actions/app.action';
import { parseDate } from '../../../../utils/app';
import { ReactComponent as ArrowRightSvg } from '../../../../assets/arrow-right-circle.svg';
import { fontStyles } from '../styles';
import { colors } from '../../../styles';
import Button from '../../Common/Button';

function Assessment(props) {
	const { dispatch, project } = React.useContext(ContextStore);
	const [values, setValue] = React.useState({
		currentQuiz: 0,
		nextFlow: false,
		selected: null,
		checked: false,
		display: true,
		totalTimer: Date.now(),
		questionTimer: Date.now(),
		result: [],
		init: false,
	});

	const styles = {
		header: {
			color: colors.gray3,
			fontSize: `14px`,
			lineHeight: `19px`,
			paddingBottom: `16px`,
			borderBottom: `1px solid ${colors.gray5}`,
			marginBottom: `48px`,
		},
		image: {
			maxWidth: `100%`,
			maxHeight: `310px`,
			width: `auto`,
			height: `auto`,
			marginTop: `24px`,
		},
		questionNo: {
			fontWeight: `600`,
			fontSize: `14px`,
			lineHeight: `21px`,
			letterSpacing: `0.05em`,
			textTransform: `uppercase`,
			color: colors.primary2,
		},
		question: {
			marginTop: `8px`,
			color: colors.gray1,
			fontWeight: `600`,
			fontSize: `18px`,
			lineHeight: `24px`,
		},
		guideline: {
			marginTop: `24px`,
			color: colors.gray3,
			fontSize: `14px`,
			lineHeight: `24px`,
		},
		modelAnswer: {
			marginBottom: `24px`,
			color: colors.gray1,
			fontSize: `16px`,
			lineHeight: `22px`,
		}
	}

	function handleNext() {
		setValue({
			...values,
			display: false,
		});

		setTimeout(() => {
			setValue({
				...values,
				currentQuiz: values.currentQuiz + 1,
				selected: null,
				checked: false,
				display: true,
			});
		}, 300);
	}

	function handleSelected(unit) {
		setValue({
			...values,
			selected: unit,
			checked: false,
		});
	}

	async function handleCheckAnswer(value) {
		const nextFlow = value.currentQuiz + 1 >= project.curriculum.assessment.content.length ? true : value.nextFlow;
		const timer = parseDate(value.questionTimer);
		const content = project.curriculum.assessment.content[value.currentQuiz];
		value.result.push({
			...timer,
			project_uuid: project.overview.id,
			lesson_uuid: project.curriculum.assessment.id,
			content_uuid: content.content_uuid,
			result: value.selected.content,
			is_correct: value.selected.correct,
		});

		trackerWithEvent(CLICK_AIART_ASSESSMENT_SUBMIT, content.content_uuid, value.selected.correct); // question id, correct status

		setValue({
			...values,
			nextFlow: nextFlow,
			checked: true,
			result: value.result,
			questionTimer: Date.now(),
		});
	}

	async function handleViewResult(value) {
		trackerWithEvent(CLICK_AIART_ASSESSMENT_RESULT, project.overview.id); // project id

		// post lesson and content result
		const correct = value.result.filter(({is_correct}) => is_correct).length;
		const result = correct+'/'+project.curriculum.assessment.content.length;
		const timer = parseDate(value.totalTimer);
		value.result.push({
			...timer,
			project_uuid: project.overview.id,
			lesson_uuid: project.curriculum.assessment.id,
			content_uuid: null,
			result: result,
			is_correct: true,
		});
		dispatch(await postLessonResult(value.result));
		props.history.push(ROUTE_SUMMARY);
	}

	function parseResultButton(value) {
		if(value.nextFlow){
			return (
				<Button type="secondary" icon onClick={() => handleViewResult(value)}>
					<ArrowRightSvg style={{color: colors.primary2, marginRight: `8px`, marginTop: `4px`}} />
					<span style={{position: `relative`, top: `-7px`}}>{projectsLang.view_result}</span>
				</Button>
			);
		}
		else if(value.checked)
			return <Button type="primary" onClick={handleNext}>{projectsLang.next_question}</Button>
		else 
			return <Button type="primary" disabled={value.selected === null} onClick={() => handleCheckAnswer(value)}>{projectsLang.check_answer}</Button>
	}

	useEffect(() => {
		if(!values.init){
			trackerWithPageView(S_AIART_ASSIGNMENT);
			setValue({
				...values,
				init: true,
			});
		}
	}, [values]);

	const total = project.curriculum.assessment.content.length;
	const current = values.currentQuiz+1;
	const question = total>0 ? project.curriculum.assessment.content[values.currentQuiz].question : {options: []};
	const modelAnswer = total>0 ? question.options.filter(({correct}) => correct)[0] : {};

	const options = question.options.map(function(unit, index) {
		let type = values.selected === unit ? "selected" : "answer";
		let icon = values.selected === unit && values.checked ? true : false;

		if(values.checked){
			if(unit.correct) 
				type = "correct";
			else if(values.selected === unit) 
				type = "wrong";
		}

		return (
			<Button 
				key={index} 
				type={type} 
				icon={icon}
				inlinestyle={{marginBottom: `16px`}} 
				onClick={() => handleSelected(unit)} 
				disabled={values.checked}>
					{unit.content}
				</Button>
			);
	});

	return (
		<div style={{width: `100%`}}>
			<div style={fontStyles.heading1}>{projectsLang.assessment}</div>
			<div style={styles.header}>{projectsLang.formatString(projectsLang.total_questions, total)}</div>
			<Animate transitionName="slide" transitionLeave transitionEnter>
				{
					values.display &&
					<div key={1}>
						<div style={styles.questionNo}>{projectsLang.formatString(projectsLang.question_no, current)}</div>
						<div style={styles.question}>{question.content}</div>
						{
							question.type==="image" && question.image &&
							<img alt="question" style={styles.image} src={question.image} />
						}
						<div style={styles.guideline}>{projectsLang.select_the_right_answer}</div>
						<div style={{margin: `8px 0px`}}>{options}</div>
						{ 
							values.checked && values.selected.correct
							? <div style={styles.modelAnswer}>“{projectsLang.you_are_correct}”</div>
							: <div />
						}
						{ 
							values.checked && !values.selected.correct
							? <div style={styles.modelAnswer}>“{projectsLang.formatString(projectsLang.you_are_wrong, <b>{modelAnswer.content}</b>)}”</div>
							: <div />
						}

						{ parseResultButton(values) }
					</div>
				}
			</Animate>
		</div>
	);
}
export default Assessment;
