import React from 'react';
import { Timeline } from 'antd';

import { ReactComponent as QuestionMarkSvg } from '../../../../assets/flowchart-question-mark.svg';
import { ReactComponent as HelpSvg } from '../../../../assets/flowchart-help-circle.svg';
import { ReactComponent as CheckSvg } from '../../../../assets/flowchart-check.svg';
import { ReactComponent as CodeSvg } from '../../../../assets/flowchart-code.svg';
import { ReactComponent as ArrowSvg } from '../../../../assets/flowchart-arrow.svg';
import { ReactComponent as DotSvg } from '../../../../assets/circle.svg';
import { projectsLang } from '../../../../languages/projects.language';
import { colors, shadows } from '../../../styles';

function FlowChart(props) {
	const styles = {
		root: {
			minHeight: `calc(100vh - 238px)`, 
			padding: `48px`,
			width: `100%`,
			whiteSpace: `nowrap`,
			overflow: `scroll`,
			backgroundColor: `#F3F5F8`,
		},
		hints: {
			backgroundColor: colors.white,
			borderBottom: `1px solid ${colors.gray5}`,
			padding: `14px 32px`,
			width: `100%`,
			display: `flex`,
		},
		titleContainer: {
			margin: `-16px -16px 18px -16px`,
			padding: `12px 16px 12px 9px`,
			borderBottom: `1px solid ${colors.gray5}`,
			display: `flex`,
			justifyContent: `space-between`,
		},
		mainStepTitle: {
			marginLeft: `8px`,
			fontWeight: `bold`,
			color: colors.gray1,
		},
		mainStepResult: {
			fontWeight: `600`,
			fontSize: `12px`,
			lineHeight: `24px`,
			marginLeft: `16px`,
		},
		stepContainer: {
			display: `inline-block`,
			minWidth: `150px`,
			boxShadow: shadows.elevation4,
			borderRadius: `6px`,
			padding: `16px 16px 0px 16px`,
			backgroundColor: colors.white,
		},
		line: {
			position: `absolute`,
			top: `24px`,
			left: `-14px`,
			borderLeft: `1px solid ${colors.gray5}`,
		},
		timelineItem: {
			position: `relative`,
			padding: `0px 0px 16px 0px`,
		},
	}

	function parseChart(data) {
		return data.map((unit, index) => {
			const firstContent = unit.content ? 1 : 0;
			const firstComplete = firstContent && unit.is_correct ? 1 : 0;
			const { treeDom, total, complete } = parseTreeData(unit.children, [], firstContent, firstComplete);
			const firstStepDom = parseStepDom(unit, null);
			const resultColor = total>0 && total===complete ? colors.success2 : colors.gray4;

			return (
				<div key={unit.uuid} style={{display: `inline-flex`}}>
					<div style={styles.stepContainer}>
						<div style={styles.titleContainer}>
							<div style={{display: `flex`}}>
								<CodeSvg />
								<span style={styles.mainStepTitle}>{unit.name}</span>
							</div>
							<div style={{...styles.mainStepResult, color: resultColor}}>{complete}/{total}</div>
						</div>
						<Timeline>
							{firstStepDom}
							{treeDom}
						</Timeline>
					</div>
					{ index!==data.length-1 ? <ArrowSvg /> : <span /> }
				</div>
			);
		});
	}

	function parseTreeData(data, parent, total, complete) {
		for(let i in data){
			let line;
			if(i<data.length-1){
				const height = (data[i].children.length+1)*20 + data[i].children.length*16;
				line = <div style={{...styles.line, height: `${height}px`}} />;
			}
			total += data[i].content ? 1 : 0;
			complete += data[i].content && data[i].is_correct ? 1 : 0;

			parent.push(parseStepDom(data[i], line));
			if(data[i].children.length>0){
				const children = parseTreeData(data[i].children, parent, total, complete);
				parent.join(children.treeDom);
				total = children.total;
				complete = children.complete;
			}
		}
		
		return {
			treeDom: parent,
			total: total,
			complete: complete
		};
	}

	function parseStatus(content, correct) {
		if(!content)
			return <DotSvg />;
		if(correct)
			return <CheckSvg />;
		return <QuestionMarkSvg />;
	}

	function parseStepDom(data, line) {
		const status = parseStatus(data.content, data.is_correct);
		return (
			<Timeline.Item 
				key={data.uuid} 
				dot={status} 
				style={{
					...styles.timelineItem, 
					marginLeft: `${data.level*20}px`,
					cursor: data.content ? `pointer` : `default`,
					fontWeight: data.content ? `600` : `default`,
					color: data.content ? colors.gray2 : colors.gray3,
				}}
				onClick={data.content ? () => props.open(data.content.uuid) : () => {} }
			>
				{line}
				{data.name}
			</Timeline.Item>
		);
	}

	return (
		<React.Fragment>
			<div style={styles.hints}>
				<HelpSvg style={{marginRight: `8px`}} />
				{projectsLang.formatString(projectsLang.inspect_description, props.name)}
			</div>
			<div id="inspect-flowchart" style={styles.root}>
				{parseChart(props.data)}
			</div>
		</React.Fragment>
	);
}
export default FlowChart;
