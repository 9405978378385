import {
	ACTION_ALL_REPORT,
} from '../constants/report.constant';

function reportReducer(state, action) {
	const payload = action.payload;
	switch (action.key) {
		case ACTION_ALL_REPORT:
			const {cols, projects, avgTime, completedProjects, completedAssessments} = parseReport(payload);
			localStorage.setItem('report.avgTime', avgTime);
			localStorage.setItem('report.cols', JSON.stringify(cols));
			localStorage.setItem('report.projects', JSON.stringify(projects));
			localStorage.setItem('report.completedProjects', completedProjects);
			localStorage.setItem('report.completedAssessments', completedAssessments);

			return Object.assign({}, state, {
					...state,
					cols: cols,
					avgTime: avgTime,
					projects: projects,
					completedProjects: completedProjects,
					completedAssessments: completedAssessments,
				});
		default:
			return state;
	}
}

function parseReport(payload){
	let cols = [];
	let projects = [];
	let avgTime = 0;
	let completedProjects = 0;
	let completedAssessments = 0;
	let assessment = "";

	for(let i in payload.lessons){
		let unit = payload.lessons[i];
		const title = unit.titles.filter(x => x.locale==='en-US');

		if(unit.title === 'Assessment') 
			assessment = unit.uuid;

		cols.push({
			title: title[0].text,
			key: unit.uuid,
		});
	}

	if(payload.report && Object.keys(payload.report).length > 0){
		for(let i in payload.report){
			let lessons = {};
			let unit = payload.report[i];
			for(let j in unit.lessons){
				if(unit.lessons[j].lesson === assessment){
					completedAssessments += unit.lessons[j].is_correct ? 1 : 0;
					lessons['score'] = unit.lessons[j].result;
					lessons['assessment'] = unit.lessons[j].duration;
				}
				else
					lessons[unit.lessons[j].lesson] = unit.lessons[j].is_correct ? true : false;
			}
			avgTime += unit.duration;
			projects.push({
				...lessons,
				key: unit.title,
				title: unit.title,
				duration: unit.duration,
			});
		}
		avgTime = Math.round(avgTime/Object.keys(payload.report).length);
	}

	
	
	return {
		cols: cols,
		projects: projects,
		avgTime: avgTime,
		completedProjects: completedProjects,
		completedAssessments: completedAssessments,
	};
}
export default reportReducer;

