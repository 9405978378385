import React from 'react';
import { Layout, Menu } from 'antd';
import Cookies from 'universal-cookie';

import { ContextStore } from '../../../store/ContextStore';
import { projectsLang } from '../../../languages/projects.language';
import { Z_INDEX_MAIN_HEADER } from '../../../constants/dom.constant';
import { trackerWithEvent, parseProjectUrl } from '../../../actions/app.action';
import { 
	CLICK_CODE_EDITOR,
	CLICK_AIART_LEARN,
	CLICK_AIART_TRY,
	CLICK_AIART_INSPECT,
	CLICK_AIART_REVIEW,
	CLICK_AIART_ASSESSMENT,
	CLICK_AIART_EXPLORE,
	CLICK_AIART_CHALLENGE,
} from '../../../constants/tracker.constant';
import { ReactComponent as CheckSvg } from '../../../assets/check-circle.svg';
import MonoChallengeSvg from '../../../assets/target-mono.svg';
import { 
	ROUTE_LEARN,
	ROUTE_TRY,
	ROUTE_INSPECT,
	ROUTE_REVIEW,
	ROUTE_EXPLORE,
	ROUTE_CHALLENGE,
	ROUTE_QUIZ, 
	ROUTE_SUMMARY, 
	ROUTE_HOME,
	ROUTE_COURSE_PREFIX,
	ROUTE_COURSE_DEPTH,
} from '../../../constants/app.constant';
import { getCourse } from '../../projects';
import { colors, shadows } from '../../styles';
// import CodeEditorModal from './CodeEditorModal';

function MainSider(props) {
	const { project } = React.useContext(ContextStore);
	// const [values, setValue] = React.useState({
	// 	modal: false,
	// });

	let selectedKey = props.history.location.pathname;
	selectedKey = selectedKey.indexOf(ROUTE_COURSE_PREFIX)===0
                  ? ROUTE_COURSE_PREFIX+'/:name/'+selectedKey.split('/')[ROUTE_COURSE_DEPTH]
                  : '/:name/'+selectedKey.split('/')[2];
	if(selectedKey === ROUTE_SUMMARY){
		selectedKey = ROUTE_QUIZ;
	}
	
	const styles = {
		root:{
			boxShadow: shadows.elevation1,
			zIndex: Z_INDEX_MAIN_HEADER,
		},
		header: {
			padding: `0px 12px`, 
			cursor: `pointer`,
			borderBottom: `1px solid `+colors.gray5,
		},
		logo: {
			width: `156px`, 
			height: `41px`, 
			margin: `11px 0px`,
		},
		icon: {
			width: `32px`,
			height: `32px`,
			marginRight: `16px`,
		},
		check: {
			position: `relative`,
			top: `15px`,
			float: `right`,
			color: colors.success3,
		},
		title: {
			fontSize: `12px`,
			lineHeight: `18px`,
			letterSpacing: `0.05em`,
			color: colors.gray3,
			fontWeight: `600`,
			margin: `16px 20px 0px 20px`,
			textTransform: `uppercase`,
		},
		menuText: {
			color: colors.gray2,
		},
		selectedText: {
			color: colors.primary1,
			fontWeight: `600`,
		},
		beta: {
			marginLeft: `6px`,
			fontSize: `12px`,
			fontWeight: `600`,
			color: `#BFBFBF`,
		},
	}

	function parseMenu(title, completed, route, icon, mono, beta) {
		const status = completed===1 ? <CheckSvg style={styles.check} /> : <span />;
		const label = beta ? <span style={styles.beta}>beta</span> : <span />;

		return (
			<Menu.Item key={title} onClick={() => handleToLesson(route)}>
				<img alt={title} src={selectedKey===route ? icon : mono} style={styles.icon} /> 
				<span style={selectedKey===route ? styles.selectedText : styles.menuText}>{title}</span>
				{ label }
				{ status }
			</Menu.Item>
		);
	}

	// function handleCloseCodeEditor() {
	// 	setValue({ ...values, modal: false });
	// }

	function handleOpenCodeEditor() {
		// setValue({ ...values, modal: true });
		trackerWithEvent(CLICK_CODE_EDITOR, project.overview.id); // project id
		const cookie = new Cookies();
		cookie.remove('XSRF-TOKEN', {
			path: '/',
			domain: 'cherrypickslearning.com',
		});
		window.open(project.codeEditorUrl, "_blank");
	}

	function handleToLesson(route) {
		switch(route){
			case ROUTE_LEARN:
				trackerWithEvent(CLICK_AIART_LEARN, project.overview.id); // project id
				break;
			case ROUTE_TRY:
				trackerWithEvent(CLICK_AIART_TRY, project.overview.id); // project id
				break;
			case ROUTE_INSPECT:
				trackerWithEvent(CLICK_AIART_INSPECT, project.overview.id); // project id
				break;
			case ROUTE_REVIEW:
				trackerWithEvent(CLICK_AIART_REVIEW, project.overview.id); // project id
				break;
			case ROUTE_QUIZ:
				trackerWithEvent(CLICK_AIART_ASSESSMENT, project.overview.id); // project id
				break;
			case ROUTE_EXPLORE:
				trackerWithEvent(CLICK_AIART_EXPLORE, project.overview.id); // project id
				break;
			case ROUTE_CHALLENGE:
				trackerWithEvent(CLICK_AIART_CHALLENGE, project.overview.id); // project id
				break;
			default: 
				break;
		}
		const path = parseProjectUrl(project.overview.name, route);
		props.history.push(path);
	}

	function handleToHome() {
		props.history.push(ROUTE_HOME);
	}

	let menu = [];
	let extras = [];
	for(let i in project.overview.lessons){
		const unit = project.overview.lessons[i];
		const {route, icon, mono, beta} = getCourse(unit.title);
		if(unit.extra) {
			extras.push(parseMenu(unit.title, unit.completed, route, icon, mono, beta));
		}
		else {
			menu.push(parseMenu(unit.title, unit.completed, route, icon, mono, beta));
		}
	}

	return (
		<Layout.Sider 
			breakpoint="lg"
			width={256}
			collapsedWidth={0}
			style={styles.root}
		>
			<Menu
				style={{width: `256px`, border: `none`}}
				selectedKeys={[selectedKey]}
				mode="vertical"
			>
				<div style={styles.header} onClick={handleToHome}>
					<img alt="Smart Lab" src="/images/logo.png" style={styles.logo} />
				</div>
				<div style={styles.title}>{projectsLang.curriculum}</div>
				{menu}
				<div style={{...styles.title, paddingTop: `24px`}}>{projectsLang.extras}</div>
				{extras}
				<div style={{...styles.title, paddingTop: `24px`}}>{projectsLang.editor}</div>
				<Menu.Item key="code editor" onClick={handleOpenCodeEditor}>
					<img alt="code editor" src={MonoChallengeSvg} style={styles.icon} /> 
					<span style={styles.menuText}>{projectsLang.code_editor}</span>
				</Menu.Item>
			</Menu>
			{/*<CodeEditorModal isOpened={values.modal} close={handleCloseCodeEditor} />*/}
		</Layout.Sider>
	);
}
export default MainSider;
