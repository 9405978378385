import React from 'react';
import { ReactComponent as CorrectSvg } from '../../../assets/check-circle.svg';
import { ReactComponent as WrongSvg } from '../../../assets/x-circle.svg';
import { colors, shadows } from '../../styles';

function Button(props) {
  const base = {
    basic: {
      ...props.inlinestyle,
      borderRadius: `6px`,
      padding: props.icon ? `0px 20px 0px 12px` : `0px 20px`,
      cursor: `pointer`,
      fontSize: `14px`,
      lineHeight: `21px`,
      height: `40px`,
      width: props.block ? `100%` : `default`,
    },
    answer: {
      ...props.inlinestyle,
      borderRadius: `6px`,
      padding: `14px 14px 14px 16px`,
      cursor: props.disabled ? `default` : `pointer`,
      borderWidth: `1px`,
      borderStyle: `solid`,
      fontSize: `14px`,
      lineHeight: `19px`,
      minHeight: `48px`,
      width: `100%`,
      textAlign: `left`,
      position: `relative`,
    },
    icon: {
      position: `absolute`,
      right: `12px`,
      top: `11px`,
    }
  }

  const styles = {
    primary: {
      ...base.basic,
      backgroundColor: colors.primary2,
      color: colors.white,
      border: `none`,
      fontWeight: `500`,
      boxShadow: shadows.elevation5,
    },
    secondary: {
      ...base.basic,
      backgroundColor: colors.white,
      color: colors.gray2,
      border: `1px solid `+colors.gray5,
    },
    accent: {
      ...base.basic,
      backgroundColor: colors.white,
      color: colors.primary2,
      border: `1px solid `+colors.primary3,
      fontWeight: `500`,
      boxShadow: shadows.elevation5,
    },
    answer: {
      ...base.answer,
      color: colors.gray2,
      borderColor: colors.gray5,
      backgroundColor: colors.white,
      boxShadow: shadows.elevation5,
    },
    selected: {
      ...base.answer,
      color: colors.gray2,
      borderColor: colors.gray5,
      backgroundColor: colors.white,
      boxShadow: shadows.elevation4,
    },
    correct: {
      ...base.answer,
      color: colors.success1,
      borderColor: colors.success3,
      backgroundColor: colors.success6,
    },
    wrong: {
      ...base.answer,
      color: colors.danger2,
      borderColor: colors.danger3,
      backgroundColor: colors.danger6,
    },
  }

  let name = "button-default";
  let style = base.basic;
  let icon;

  switch(props.type){
    case "primary":
      name = "button-primary";
      style = styles.primary;
      break;
    case "secondary":
      name = "button-secondary";
      style = styles.secondary;
      break;
    case "accent":
      name = "button-accent";
      style = styles.accent;
      break;
    case "answer":
      name = "button-answer";
      style = styles.answer;
      break;
    case "selected":
      name = "button-selected";
      style = styles.selected;
      break;
    case "correct":
      name = "button-correct";
      style = styles.correct;
      icon = <CorrectSvg style={base.icon} />
      break;
    case "wrong":
      name = "button-wrong";
      style = styles.wrong;
      icon = <WrongSvg style={base.icon} />
      break;
    default:
      break;
  }

  return <button className={name} style={style} onClick={props.onClick} disabled={props.disabled}>{props.children}{props.icon && icon}</button>;

}
export default Button;
