
// **************************************************************** page views
// <----- Auth
export const S_LOGIN = 's_login_page';
export const S_FORGOT_PASSWORD = 's_forgot_password_page';

// <----- Home
export const S_LANDING = 's_landing_page';
export const S_REPORT = 's_report_page';

// <----- Course
export const S_AIART_COURSE = 's_ai_art_course_page';
export const S_AIART_LEARN = 's_ai_art_learn_page';
export const S_AIART_TRY = 's_ai_art_try_page';
export const S_AIART_INSPECT = 's_ai_art_inspect_page';
export const S_AIART_REVIEW = 's_ai_art_review_page';
export const S_AIART_ASSIGNMENT = 's_ai_art_assignment_page';
export const S_AIART_EXPLORE = 's_ai_art_explore_page';
export const S_AIART_CODE_EDITOR = 's_ai_art_code_editor';

// **************************************************************** events

// <----- Auth
export const CLICK_LOGIN = 'click_login';
export const CLICK_LOGOUT = 'click_logout';
export const CLICK_LOGIN_BUTTON = 'click_loginpage_login';
export const CLICK_FORGOT_PASSWORD = 'click_forgot_password';
export const CLICK_EDMODO_LOGIN = 'click_edmodo_login';
export const CLICK_GOOGLE_LOGIN = 'click_google_login';
export const CLICK_TNC = 'click_tnc';
export const CLICK_PRIVACY = 'click_privacy';
export const CLICK_AUTH_EVENTS = [
	CLICK_LOGIN,
	CLICK_LOGOUT,
	CLICK_LOGIN_BUTTON,
	CLICK_FORGOT_PASSWORD,
	CLICK_EDMODO_LOGIN,
	CLICK_GOOGLE_LOGIN,
	CLICK_TNC,
	CLICK_PRIVACY,
];

// <----- Home
export const CLICK_REPORT = 'click_report';
export const CLICK_HOME_EVENTS = [
	CLICK_REPORT,
];

// <----- Course
export const CLICK_AIART_COURSE = 'click_ai_art_course';
export const CLICK_AIART_ENROLL = 'click_ai_art_enroll_course';
export const CLICK_BACK_TO_COURSE = 'click_backtocourse';
export const CLICK_CODE_EDITOR = 'click_open_code_edtior';
export const CLICK_COURSE_EVENTS = [
	CLICK_AIART_COURSE,
	CLICK_AIART_ENROLL,
	CLICK_BACK_TO_COURSE,
	CLICK_CODE_EDITOR,
];

// <----- Learn
export const CLICK_AIART_LEARN = 'click_ai_art_learn';
export const CLICK_AIART_LEARN_NEXT_SLIDE = 'click_ai_art_learn_next_slide';
export const CLICK_AIART_LEARN_PREVIOUS_SLIDE = 'click_ai_art_learn_previous_slide';
export const CLICK_AIART_LEARN_FULLSCREEN = 'click_ai_art_learn_fullscreen';
export const CLICK_AIART_NEXT = 'click_ai_art_next';
export const CLICK_AIART_LEARN_EVENTS = [
	CLICK_AIART_LEARN,
	CLICK_AIART_LEARN_NEXT_SLIDE,
	CLICK_AIART_LEARN_PREVIOUS_SLIDE,
	CLICK_AIART_LEARN_FULLSCREEN,
	CLICK_AIART_NEXT,
];

// <----- Try
export const CLICK_AIART_TRY = 'click_ai_art_try';
export const SELECT_AIART_CONTENT_IMG = 'select_ai_art_content_image';
export const SELECT_AIART_STYLE_IMG = 'select_ai_art_style_image';
export const CLICK_AIART_TRY_EVENTS = [
	CLICK_AIART_TRY,
	SELECT_AIART_CONTENT_IMG,
	SELECT_AIART_STYLE_IMG,
];

// <----- Inspect
export const CLICK_AIART_INSPECT = 'click_ai_art_inspect';
export const CLICK_AIART_INSPECT_TAB = 'click_ai_art_inspect_tab';
export const CLICK_AIART_INSPECT_ANSWER = 'click_ai_art_inspect_answer';
export const CLICK_AIART_INSPECT_EVENTS = [
	CLICK_AIART_INSPECT,
	CLICK_AIART_INSPECT_TAB,
	CLICK_AIART_INSPECT_ANSWER,
];

// <----- Review
export const CLICK_AIART_REVIEW = 'click_ai_art_reivew';
export const CLICK_AIART_REVIEW_NEXT_SLIDE = 'click_ai_art_review_next_slide';
export const CLICK_AIART_REVIEW_PREVIOUS_SLIDE = 'click_ai_art_review_previous_slide';
export const CLICK_AIART_REVIEW_FULLSCREEN = 'click_ai_art_reivew_fullscreen';
export const CLICK_AIART_REVIEW_NEXT = 'click_ai_art_review_next';
export const CLICK_AIART_REVIEW_EVENTS = [
	CLICK_AIART_REVIEW,
	CLICK_AIART_REVIEW_NEXT_SLIDE,
	CLICK_AIART_REVIEW_PREVIOUS_SLIDE,
	CLICK_AIART_REVIEW_FULLSCREEN,
	CLICK_AIART_REVIEW_NEXT,
];

// <----- Assessment
export const CLICK_AIART_ASSESSMENT = 'click_ai_art_assessment';
export const CLICK_AIART_ASSESSMENT_SUBMIT = 'click_ai_art_assessment_sumbit';
export const CLICK_AIART_ASSESSMENT_RESULT = 'click_ai_art_assessment_result';
export const CLICK_AIART_ASSESSMENT_AGAIN = 'click_ai_art_assessment_again';
export const CLICK_AIART_ASSESSMENT_EVENTS = [
	CLICK_AIART_ASSESSMENT,
	CLICK_AIART_ASSESSMENT_SUBMIT,
	CLICK_AIART_ASSESSMENT_RESULT,
	CLICK_AIART_ASSESSMENT_AGAIN,
];

// <----- Explore
export const CLICK_AIART_EXPLORE = 'click_ai_art_explore';
export const CLICK_AIART_EXPLORE_LINK = 'click_ai_art_explore_link';
export const CLICK_AIART_EXPLORE_EVENTS = [
	CLICK_AIART_EXPLORE,
	CLICK_AIART_EXPLORE_LINK,
];

// <----- Challenge
export const CLICK_AIART_CHALLENGE = 'click_ai_art_challenge';
export const CLICK_AIART_CHALLENGE_JUPYTER = 'click_ai_art_challenge_jupyter';
export const CLICK_AIART_CHALLENGE_EVENTS = [
	CLICK_AIART_CHALLENGE,
	CLICK_AIART_CHALLENGE_JUPYTER,
];

// <----- categories
export const CATEGORY_AUTH = 'auth';
export const CATEGORY_HOME = 'home';
export const CATEGORY_COURSE = 'course';
export const CATEGORY_AIART_LEARN = 'AIART_LEARN';
export const CATEGORY_AIART_TRY = 'AIART_TRY';
export const CATEGORY_AIART_INSPECT = 'AIART_INSPECT';
export const CATEGORY_AIART_REVIEW = 'AIART_REVIEW';
export const CATEGORY_AIART_ASSESSMENT = 'AIART_ASSESSMENT';
export const CATEGORY_AIART_EXPLORE = 'AIART_EXPLORE';
export const CATEGORY_AIART_CHALLENGE = 'AIART_CHALLENGE';
export const CATEGORY_OTHERS = 'others';
export const CATEGORIES = [];
CATEGORIES[CATEGORY_AUTH] = CLICK_AUTH_EVENTS;
CATEGORIES[CATEGORY_HOME] = CLICK_HOME_EVENTS;
CATEGORIES[CATEGORY_COURSE] = CLICK_COURSE_EVENTS;
CATEGORIES[CATEGORY_AIART_LEARN] = CLICK_AIART_LEARN_EVENTS;
CATEGORIES[CATEGORY_AIART_TRY] = CLICK_AIART_TRY_EVENTS;
CATEGORIES[CATEGORY_AIART_INSPECT] = CLICK_AIART_INSPECT_EVENTS;
CATEGORIES[CATEGORY_AIART_REVIEW] = CLICK_AIART_REVIEW_EVENTS;
CATEGORIES[CATEGORY_AIART_ASSESSMENT] = CLICK_AIART_ASSESSMENT_EVENTS;
CATEGORIES[CATEGORY_AIART_EXPLORE] = CLICK_AIART_EXPLORE_EVENTS;
CATEGORIES[CATEGORY_AIART_CHALLENGE] = CLICK_AIART_CHALLENGE_EVENTS;
CATEGORIES[CATEGORY_OTHERS] = [];
