import React from 'react';
import { Layout } from 'antd';

import { colors } from '../../../styles';
import { parseDate } from '../../../../utils/app';
import { postLessonResult } from '../../../../actions/project.action';
import { trackerWithEvent } from '../../../../actions/app.action';
import { 
	CLICK_AIART_INSPECT_ANSWER,
} from '../../../../constants/tracker.constant';
import { ContextStore } from '../../../../store/ContextStore';
import MainFooter from '../../Common/MainFooter';
import CodeEditor from './CodeEditor';
import Question from './Question';
import Code from './Code';

function TabContent(props) {
	const { dispatch, project } = React.useContext(ContextStore);
	const [values] = React.useState({
		timer: Date.now(),
	});

	const styles = {
		code: {
			borderRadius: `12px 0px 0px 12px`, 
			width: `100%`, 
			height: `388px`,
			background: colors.code2,
		},
		image: {
			borderRadius: `12px 0px 0px 12px`, 
			width: `auto`, 
			height: `auto`,
			maxWidth: `580px`,
			maxHeight: `388px`,
		},
		codeEditor: {
			padding: `20px`, 
			borderRadius: `0px 0px 12px 12px`,
		},
		description: {
			marginBottom: `16px`,
			fontSize: `14px`,
			lineHeight: `22px`,
			color: colors.gray2,
		},
	}

	async function questionSubmit(result){
		const timer = parseDate(values.timer);
		dispatch(await postLessonResult([{
			...timer,
			project_uuid: project.overview.id,
			lesson_uuid: project.curriculum.inspect.id,
			content_uuid: props.data.content_uuid,
			is_correct: true,
			result: result,
		}]));
		return true;
	}

	async function codeSubmit(result, correct){
		trackerWithEvent(CLICK_AIART_INSPECT_ANSWER, props.data.question.id, correct ? 1 : 0); // question id, correct status
		const timer = parseDate(values.timer);
		dispatch(await postLessonResult([{
			...timer,
			project_uuid: project.overview.id,
			lesson_uuid: project.curriculum.inspect.id,
			content_uuid: props.data.content_uuid,
			is_correct: correct,
			result: result,
		}]));
	}

	function parseQuestion(question){
		if(!question) return <div />;
		switch(question.type){
			case 'code':
				return <Question question={question} next={questionSubmit} image={<Code style={styles.code} code={question.code} markers={question.highlighted} />} />;
			case 'image':
				return <Question question={question} next={questionSubmit} image={<img style={styles.image} alt={question.content} src={question.image} />} />;
			case 'editor':
				return (
					<CodeEditor 
						code={question.code} 
						highlighted={question.highlighted}
						preDefined={question.pre_define}
						outputCode={question.output_code}
						expectedOutput={question.expected_output}
						customStyle={styles.codeEditor}
						codeSubmit={codeSubmit}
					/>
				);
			default: return <div />;
		}
	}

	return (
		<Layout className="course-container" style={{minHeight: `calc(100vh - 190px)`}}>
			<Layout.Content style={{padding: `32px`}}>
			{
				props.data.src &&
				<div id="project-description" style={styles.description} dangerouslySetInnerHTML={{__html: `${props.data.src}`}} />
			}
			{ parseQuestion(props.data.question) }
			</Layout.Content>
			<MainFooter />
		</Layout>
	);
}
export default TabContent;
