let rewardItems = [];

export function rewardFunction(remainDistance, velocity, isEpisodeFinished) {
	let reward = rewardFunction_UserInput(remainDistance, velocity, isEpisodeFinished);
	return reward;
}

export function setReward(inputRewardItems) {
	rewardItems = inputRewardItems;
	return true;
}

function rewardFunction_UserInput(remainDistance, velocity, isEpisodeFinished) {
	if (rewardItems.length === 0) {
		return 0;
	}

	for (let i = 0; i < rewardItems.length; i++) {
		let item = rewardItems[i];
		if (isEpisodeFinished !== item.isFinalSituation) {
			continue;
		}

        if (!item.hasUpperLimit) {
            if (remainDistance >= item.distLowerLimit) {
                return item.reward;
            } else {
                continue;
            }
        }

        if (!item.hasLowerLimit) {
            if (remainDistance < item.distUpperLimit) {
                return item.reward;
            } else {
                continue;
            }
        }

        if (remainDistance >= item.distLowerLimit && remainDistance < item.distUpperLimit) {
            return item.reward;
        } else {
            continue;
        }
	}

	return 0;
}