import React from 'react';

import { ContextStore } from '../../../store/ContextStore';
import { projectsLang } from '../../../languages/projects.language';
import { colors } from '../../styles';

function OverviewHeader(props) {
	const { project } = React.useContext(ContextStore);
	const styles = {
		root: {
			backgroundColor: project.overview.color ? project.overview.color : colors.gray4, 
			// backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('`+project.overview.image+`')`, 
			height: `120px`,
			overflow: `hidden`,
			padding: `0px 32px`,
			backgroundRepeat: `no-repeat`,
			backgroundSize: `cover`,
			backgroundPosition: `center`,
		},
		subTitle: {
			marginTop: `26px`,
			color: colors.white,
			fontSize: `14px`,
			lineHeight: `21px`,
			letterSpacing: `0.05em`,
			fontWeight: `600`,
			textAlign: `center`,
		},
		title: {
			color: colors.white,
			marginTop: `-4px`,
			fontSize: `36px`,
			lineHeight: `54px`,
			fontWeight: `700`,
			textAlign: `center`,
		}
	}

	return (
		<div style={styles.root}>
			<div className="container" style={{display: `block`}}>
				<div style={styles.subTitle}>{projectsLang.course}</div>
				<div style={styles.title}>{project.overview.name}</div>
			</div>
		</div>
	);
}
export default OverviewHeader;
