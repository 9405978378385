import React from 'react';
import { useParams } from 'react-router-dom';
import AiArtChallenge from '../../../Course/AiArt/Challenge';

function Challenge(props) {
	switch(useParams().name){
		case 'ai-art': 
			return <AiArtChallenge />;
		default:
			return <div />;
	}
}
export default Challenge;