import React from 'react';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";

function Code(props) {
	let markers = [];
	for(let i in props.markers){
		markers.push({ startRow: props.markers[i] - 1, endRow: props.markers[i], className: 'editable-marker', type: 'background' });
	}

	return (
		<AceEditor
			{...props}
			mode="python"
			theme="monokai"
			value={props.code}
			readOnly={true}
			focus={true}
			wrapEnabled={true}
			showGutter={true}
			showPrintMargin={false}
			highlightActiveLine={false}
			markers={markers}
		/>
	);
}
export default Code;
