import React, { useEffect } from 'react';
import { Row, Popover, Col } from 'antd';

import { ContextStore } from '../../../store/ContextStore';
import { publicLang } from '../../../languages/public.language';
import { 
	S_LANDING,
} from '../../../constants/tracker.constant';
import { getPopularProjects } from '../../../actions/project.action';
import { startAppLoading, endAppLoading } from '../../../actions/dom.action';
import { trackerWithPageView } from '../../../actions/app.action';
import { ReactComponent as Megaphone } from '../../../assets/megaphone.svg';
import { colors, text } from '../../styles';
import Project from './Project';
import HeroHeader from './HeroHeader';
// import TeacherForm from './TeacherForm';

function Projects(props) {
	const { dispatch, dom, project } = React.useContext(ContextStore);
	const ScrollDom = React.useRef();

	const styles = {
		title: {
			...text.heading2,
			color: colors.gray1,
			marginBottom: `24px`,
		},
		subTitle: {
			...text.headingHighlight,
			color: colors.primary2,
			marginBottom: `4px`,
		},
		activities: {
			background: colors.white, 
			padding: `80px 32px`,
			margin: `0px -32px`,
		},
		courses: {
			background: colors.gray6, 
			padding: `80px 32px`,
			margin: `0px -32px`,
		},
		hints: {
			fontWeight: `500`,
			fontSize: `14px`,
			lineHeight: `21px`,
			color: colors.gray2,
			display: `flex`,
			alignItems: `center`,
		},
		paragraph: {
			fontSize: `16px`,
			lineHeight: `28px`,
			color: colors.gray2,
		},
		bannerBg1: {
			width: `100px`,
			height: `80%`,
			background: `#292A45`,
			opacity: `0.15`,
			borderRadius: `4px`,
			position: `absolute`,
			left: `12px`,
			top: `10%`,
		},
		bannerBg2: {
			width: `100px`,
			height: `90%`,
			background: `#292A45`,
			opacity: `0.3`,
			borderRadius: `4px`,
			position: `absolute`,
			left: `29px`,
			top: `5%`,
		},
		banner: {
			width: `calc(100% - 40px)`,
			position: `inherit`,
			marginLeft: `34px`,
		}
	}

	function handleViewCourses() {
		ScrollDom.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
	}

	async function handleGetProjects(){
		dispatch(startAppLoading());
		dispatch(await getPopularProjects(false));
		dispatch(endAppLoading());
	}
	
	const projects = project.popularProjects.map((unit, index, array) => <Project key={index} value={unit} history={props.history} />);

	useEffect(() => {
		if(!dom.loading){
			handleGetProjects();
			trackerWithPageView(S_LANDING);
		}
	}, [])

	return (
		<div>
			<HeroHeader scroll={handleViewCourses} />
			<div ref={ScrollDom} style={styles.courses}>
				<div style={{display: `block`}} className="container">
					<div style={styles.subTitle}>{publicLang.online_learning}</div>
					<div style={styles.title}>
						<Popover 
							id="popular-courses-tooltip"
							placement="right" 
							visible={true} 
							content={<span style={styles.hints}><Megaphone style={{marginRight: `8px`}} />{publicLang.courses_coming_soon}</span>}
						>
							<span>{publicLang.popular_projects}</span>
						</Popover>
					</div>
					
					<Row id="popular-courses" gutter={24} type="flex">{ projects }</Row>
				</div>
			</div>

			<div style={styles.activities}>
				<div style={{display: `block`}} className="container">
					<Row gutter={24}>
						<Col lg={12} style={{position: `relative`}}>
							<div style={styles.bannerBg1} />
							<div style={styles.bannerBg2} />
							<img alt="deep racer" src="/images/Deepracer.png" style={styles.banner} />
						</Col>
						<Col lg={12} style={{padding: `20px 30px`}}>
							<div style={styles.subTitle}>{publicLang.extended_learning_activies}</div>
							<div style={styles.title}>AWS DeepRacer League</div>
							<div style={styles.paragraph}><b>AWS DeepRacer</b> is a 1:18 auto racing car for training reinforcement learning (RL) models. Students will train the model in a stimulated environment and help the model learn and strengthen itself to control the car by monitoring it. They could compete with others on physical tracks once finish training the car, which demonstrates how a model trained in a simulated environment transfer to the real world.</div>
						</Col>
					</Row>
				</div>
			</div>
			{/*<TeacherForm />*/}
		</div>
	);
}
export default Projects;
