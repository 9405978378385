import React from 'react';
import { Card, Row, Col, Progress } from 'antd';
import moment from 'moment';

import { ContextStore } from '../../../../store/ContextStore';
import { projectsLang } from '../../../../languages/projects.language';
import { ROUTE_QUIZ } from '../../../../constants/app.constant';
import { 
	CLICK_AIART_ASSESSMENT_AGAIN,
} from '../../../../constants/tracker.constant';
import { trackerWithEvent, parseProjectUrl } from '../../../../actions/app.action';
import { ReactComponent as ExploreSvg } from '../../../../assets/bookmark.svg';
import { ReactComponent as ChallengeSvg } from '../../../../assets/target.svg';
import { colors, text, shadows } from '../../../styles';
import StatisticBox from '../../Report/StatisticBox';
import Button from '../../Common/Button';
import { fontStyles } from '../styles';

function Review(props) {
	const { project } = React.useContext(ContextStore);
	const styles = {
		box: {
			boxShadow: shadows.elevation5,
			border: `1px solid `+colors.gray6,
			borderRadius: `16px`,
			marginTop: `49px`,
		},
		bottle: {
			position: `absolute`,
			top: `24px`,
			right: `24px`,
		},
		congrateTitle: {
			fontWeight: `600`,
			fontSize: `20px`,
			lineHeight: `28px`,
			color: colors.primary2,
		},
		congrateDescription: {
			color: colors.gray2,
			margin: `4px 0px 33px 0px`,
		},
		courseIcon: {
			display: `inline-block`,
			marginRight: `16px`,
			width: `40px`,
			height: `40px`,
			borderRadius: `8px`,
			padding: `8px`,
			background: `rgba(255,213,69,0.2)`,
		},
		courseTitle: {
			color: colors.gray1,
			fontWeight: `600`,
		},
		listItem: {
			fontSize: `14px`,
			lineHeight: `21px`,
			color: colors.gray2,
			paddingLeft: `5px`,
			marginTop: `18px`,
		},
	}

	function handleQuizAgain() {
		trackerWithEvent(CLICK_AIART_ASSESSMENT_AGAIN, project.overview.id); // project id
		const path = parseProjectUrl(project.overview.name, ROUTE_QUIZ);
		props.history.push(path);
	}

	const outcome = project.curriculum.assessment.summary.learnt.map(unit => <li key={unit} style={styles.listItem}>{unit}</li>);
	const duration = moment.duration(project.curriculum.assessment.duration)._data;
	const result = project.curriculum.assessment.result ? project.curriculum.assessment.result.split('/') : [0,0];

	return (
		<div style={{width: `100%`}}>
			<Row gutter={24}>
				<Col md={16}>
					<div style={fontStyles.heading1}>{projectsLang.assessment_results}</div>
					<div style={fontStyles.heading2}>{projectsLang.review_description}</div>
				</Col>
				<Col md={8}>
					<Button type="secondary" inlinestyle={{float: `right`, marginBottom: `24px`}} onClick={handleQuizAgain}>
						{projectsLang.take_assessment_again}
					</Button>
				</Col>
			</Row>

			<Row gutter={24}>
				<StatisticBox 
					boxStyle={{marginBottom: `16px`}}
					innerStyle={{padding: `17px 24px`}}
					title={projectsLang.quiz_result} 
					value={projectsLang.formatString(projectsLang.result_value, result[0], result[1])} 
					rightDom={
						<Progress 
							type="circle" 
							width={64} 
							strokeWidth={5} 
							strokeColor={colors.success3} 
							percent={parseInt(result[0])/parseInt(result[1])*100} 
						/>
					}
				/>
				<StatisticBox 
					boxStyle={{marginBottom: `16px`}}
					innerStyle={{padding: `17px 24px`}}
					title={projectsLang.time_spent} 
					value={`${duration.minutes}m ${duration.seconds}s`}
				/>
			</Row>

			<div style={{...text.heading3, color: colors.gray1, marginTop: `25px`}}>{projectsLang.have_learnt}</div>
			<ul className="outcome-list" style={{marginLeft: `-20px`}}>{outcome}</ul>

			<Card style={styles.box}>
				<div style={styles.congrateTitle}>{projectsLang.congratulations}</div>
				<div style={styles.congrateDescription}>{projectsLang.completed_the_course}</div>
				<div style={{display: `flex`}}>
					<div style={styles.courseIcon}><ExploreSvg /></div>
					<div style={{display: `inline-block`}}>
						<div style={styles.courseTitle}>{projectsLang.explore}</div>
						<div>{projectsLang.explore_description}</div>
					</div>
				</div>
				<div style={{display: `flex`, marginTop: `24px`}}>
					<div style={styles.courseIcon}><ChallengeSvg /></div>
					<div style={{display: `inline-block`}}>
						<div style={styles.courseTitle}>{projectsLang.challenge}</div>
						<div>{projectsLang.challenge_info}</div>
					</div>
				</div>
				<div id="result-bottle"><img alt="result bottle" src="/images/result-bottle.png" style={styles.bottle} /></div>
			</Card>
		</div>
	);
}
export default Review;
