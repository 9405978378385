import React from 'react';
import Carousel from './Carousel';

function Previewer(props) {
	const googlePdf = ['application/pdf'];
	const images = [
		'image/bmp', 
		'image/gif', 
		'image/jpeg', 
		'image/png', 
		'image/svg+xml', 
		'image/tiff', 
		'image/webp'
	];
	const officeViewer = [
		'application/vnd.ms-powerpoint', 
		'application/vnd.openxmlformats-officedocument.presentationml.presentation', 
		'application/msword', 
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
		'application/vnd.ms-excel', 
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
	];

	if(googlePdf.includes(props.type)){
		return <div><iframe title="pdf viewer" src={"https://docs.google.com/viewer?url="+props.content[0].src+"&embedded=true"} width="100%" height="546px" frameBorder="0" /></div>
	}
	else if(officeViewer.includes(props.type)){
		return <div><iframe title="document viewer" src={"https://view.officeapps.live.com/op/embed.aspx?src="+encodeURI(props.content[0].src)} width="100%" height="546px" frameBorder="0" /></div>
	}
	else if(images.includes(props.type)){
		return <Carousel route={props.route} slides={props.content} />
	}
	else {
		return <div />
	}
}
export default Previewer;
