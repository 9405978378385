import React from "react";
import { LANGUAGE_EN_US } from '../constants/app.constant';

export const defaultApp = {
		language: localStorage.getItem('app.language') || LANGUAGE_EN_US,
	};
export const defaultDom = {
		loading: false,
		lightbox:{
			isOpened: false,
			currentIndex: -1,
			imageSources: [],
		},
		welcome:{
			isOpened: false,
			dismissed: localStorage.getItem('welcome.dismissed')==='true' ? true : false,
		},
		lesson: false,
	};
export const defaultAuth = {
		userId: localStorage.getItem('auth.userId') || null,
		token: localStorage.getItem('auth.token') || null,
		userDisplayName: localStorage.getItem('auth.userDisplayName') || "",
	};
export const defaultProject = {
		popularProjects: localStorage.getItem('project.popularProjects') ? JSON.parse(localStorage.getItem('project.popularProjects')) : [],
		banners: localStorage.getItem('project.banners') ? JSON.parse(localStorage.getItem('project.banners')) : [],
		overview: localStorage.getItem('project.overview') ? JSON.parse(localStorage.getItem('project.overview')) : null,
		curriculum: localStorage.getItem('project.curriculum') ? JSON.parse(localStorage.getItem('project.curriculum')) : null,
		codeEditorUrl: localStorage.getItem('project.codeEditorUrl') || "",
	};
export const defaultReport = {
		avgTime: localStorage.getItem('report.avgTime') || 0,
		completedProjects: localStorage.getItem('report.completedProjects') || 0,
		completedAssessments: localStorage.getItem('report.completedAssessments') || 0,
		progress: localStorage.getItem('report.progress') ? JSON.parse(localStorage.getItem('report.progress')) : [],
		assesment: localStorage.getItem('report.assesment') ? JSON.parse(localStorage.getItem('report.assesment')) : [],
	};

export const ContextStore = React.createContext({
		app: defaultApp,
		dom: defaultDom,
		auth: defaultAuth,
		project: defaultProject,
		report: defaultReport,
	});
