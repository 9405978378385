import React from 'react';
import { projectsLang } from '../../../languages/projects.language';
import { ReactComponent as LeftSvg } from '../../../assets/chevron-left.svg';
import { ReactComponent as RightSvg } from '../../../assets/chevron-right.svg';
import { colors } from '../../styles';

function NextStep(props) {
	const styles = {
		root: {
			display: `inline-block`, 
			float: props.type==="left" ? `left` : `right`, 
			textAlign: props.type==="left" ? `left` : `right`, 
			cursor: `pointer`,
			marginTop: `32px`,
		},
		icon: {
			width: `16px`,
			height: `16px`,
		},
		subtitle: {
			fontSize: `11px`,
			lineHeight: `16px`,
			letterSpacing: `0.1em`,
			fontWeight: `600`,
			color: colors.primary2,
			display: `flex`,
		},
		title: {
			fontSize: `16px`,
			lineHeight: `24px`,
			color: colors.gray2,
		}
	}
	if(props.type==="left")
		return (
			<div style={styles.root} onClick={props.action}>
				<div style={styles.subtitle}>
					<LeftSvg style={styles.icon} />
					{projectsLang.previous_step}
				</div>
				<div style={styles.title}>{props.step}</div>
			</div>
		);
	else
		return (
			<div style={styles.root} onClick={props.action}>	
				<div style={styles.subtitle}>
					{projectsLang.next_step}
					<RightSvg style={styles.icon} />
				</div>
				<div style={styles.title}>{props.step}</div>
			</div>
		);
}
export default NextStep;
