import React from 'react';
import { Modal, Button } from 'antd';
import { ContextStore } from '../../../store/ContextStore';
import { controlWelcome } from '../../../actions/dom.action';
import { publicLang } from '../../../languages/public.language';
import { ReactComponent as Codi } from '../../../assets/Codi.svg';
import { ReactComponent as Handshake } from '../../../assets/handshake.svg';
import { colors, text } from '../../styles';

function WelcomeModal(props) {
  const { dispatch, dom } = React.useContext(ContextStore);

  const styles = {
    welcomeTitle: {
      ...text.sectionTitle,
      margin: `32px 0px 8px 0px`,
      color: colors.gray1,
    },
    welcomeDescription: {
      fontSize: `16px`,
      lineHeight: `24px`,
      color: colors.gray2,
      marginBottom: `24px`,
    }
  }

  function handleCloseWelcome(){
    dispatch(controlWelcome(false));
  }

  return (
    <Modal
      visible={dom.welcome.isOpened}
      closable={false}
      bodyStyle={{textAlign: `center`, padding: `48px 32px 32px 32px`}}
      onCancel={handleCloseWelcome}
      footer={null}
      width={420}
    >
      <Codi />
      <Handshake style={{margin: `0 0 -12px -48px`}} />
      <div style={styles.welcomeTitle}>{publicLang.welcome_title}</div>
      <div style={styles.welcomeDescription}>{publicLang.formatString(publicLang.welcome_description, <b>{publicLang.codi}</b>)}</div>
      <Button type="primary" onClick={handleCloseWelcome}>{publicLang.get_started}</Button>
    </Modal>
  );
}
export default WelcomeModal;
