import React from 'react';
import { Row, Col } from 'antd';
import Unity, { UnityContent } from "react-unity-webgl";
import Chart from "react-google-charts";

import { ContextStore } from '../../../../store/ContextStore';
import bridge from "./StopCar/bridge";

import {selfDrivingLang} from "../../../../languages/selfDriving.language"
import { colors, text, shadows } from '../../../styles';


function Try(props) {

  const { project } = React.useContext(ContextStore);

  const style = {
    title:{
      ...text.heading3,
      color: colors.gray1
    },
    description:{
      marginTop : `20px`,
      color: colors.gray2,
      fontSize: `14px`,
			lineHeight: `22px`,
      whiteSpace: "pre-wrap"
    },
  }

  // <------ graph data
  // remark (Tracy 20200129): added some data for create empty chart for no data
  const defaultData = {
    'trial': 0,
    'reward': [['Trials', 'Avg. Reward'],[0,20],[5,-20]], 
    'distance': [['Trials', 'Avg. distance'],[0,20],[5,-20]],
  };

  const emptyData = {
    'trial': 0,
    'reward': [['Trials', 'Avg. Reward']],
    'distance': [['Trials', 'Avg. distance']],
  }

  const [graphData, setGraphData] = React.useState(defaultData);

  const [resizeHandler, setResizeHandler] = React.useState(0);
  var resizeTimeout;

  window.addEventListener('resize', function(event){
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(function(){
        setResizeHandler(resizeHandler+1)
    }, 30);
  });
  /* Remark (Tracy 2020/01/30) : 
      The charts may be overlap or not resize correctly after resize the window.
      Current workaround - after receiving resize, force update chart componemts.  */
  
  // <------ unity assets
  const publicInterface = window.PublicInterface = window.PublicInterface || {};
  let unityContent = null;
  let unityJson = "";
  let unityJS = "";
  let projectTry = project.curriculum.try;

  for (let i in projectTry.content) {
    if (projectTry.content[i].name === 'stop_car_unity_json')
      unityJson = projectTry.content[i].src;

    if (projectTry.content[i].name === 'stop_car_unity_js')
      unityJS = projectTry.content[i].src;
  }

  if (unityJson !== "" && unityJS !== ""){
    unityContent = new UnityContent(unityJson, unityJS);
    // unityContent.on("loaded", () => {
    // });
  } else {
    console.warn('unity_asset_not_found');
  }
  
  // <------ callback triggered from Unity requests
  const UnityCallback = (action, payload) => {

    // <------ update chart
    if (action === 'endEpisode') {
      let trial = graphData.trial;
      let reward = trial === 0 ? emptyData.reward : graphData.reward;
      let distance = trial === 0 ? emptyData.distance : graphData.distance;

      trial++;
      console.log(`endEpisode - Trial: ${trial}, Reward: ${payload.reward}, Distance: ${payload.distance}`)

      reward.push([trial, payload.reward]);
      distance.push([trial, payload.distance]);
      setGraphData({ trial, reward, distance });
    }

    // <------ reset chart
    if (action === 'resetRL')
      setGraphData(defaultData);
  }

  // <------ initialize unity public interface
  publicInterface.callJS = (inputJSONStr) => {
    return bridge(inputJSONStr, UnityCallback);
  };
  
  const getChart = (data,chartTitle,hAxisTitle,vAxisTitle,isEmptyGraph,num) => {
    return (
      <Chart
        chartType="ScatterChart"
        width={'100%'}
        height={'333px'}
        data={data}
        options={{
          title: chartTitle,
          legend: 'none',
          hAxis: { 
            title: hAxisTitle ,
            titleTextStyle : { // control h axis title style
              color : colors.gray2,
              fontSize : 12,
              bold: false
            },
            minValue: 0, 
            maxValue: null,
            gridlines: { // hide h line
              color: "none", count: "auto"
            },
            baseline:{
              color: "none",
            },
            textStyle: { // control h axis label style
              color: colors.gray2, 
              fontSize: 12
            },
          },
          vAxis: { 
            title: vAxisTitle ,
            titleTextStyle : { // control v axis title style
              color : colors.gray2,
              fontSize : 12,
              bold: false
            },
            minValue: null,
            maxValue: null,
            gridlines: {
              count: "auto", 
              color: colors.gray5
            },
            // baseline:{
            //   color: colors.gray3,
            // },
            textStyle: { // control v axis label style
              color: colors.gray2, 
              fontSize: 12
            }
          },
          trendlines: { 0: {} },
          colors : [isEmptyGraph?"none":colors.primary2],
          pointSize : isEmptyGraph? 0:8,
          titleTextStyle : {
            color : colors.gray2,
            fontSize : 14,
            bold: true
          }
        }}
        className = "self-driving-chart"
        rootProps={{ 'data-resizehanding':num}}
      />
    )
  }

  return (
    <div>
      {/* top section */}
      <Row type="flex" gutter={[32,32]} style={{margin:`-8px -16px 0`}}>
        <Col xs={24} sm={24} md={24} lg={8} >
          <div style={style.title}>
            {selfDrivingLang.train_your_car_title}
          </div>
          <div style={style.description}>
            {selfDrivingLang.formatString(selfDrivingLang.train_your_car_description, 
                                             <b>{selfDrivingLang.train_your_car_description_bold_content}</b>)} 
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} lg={16}>
          {(unityContent !== null) && 
          <Unity unityContent={unityContent} height="auto" width="100%" className="unity-player"/>
          }
        </Col>
      </Row>

      {/* graph: do not show if no data */}
      {
      <Row type="flex" gutter={[32,32]} style = {{margin:`0 -16px`}} >
        <Col xs={24} sm={24} md={24} lg={12}>
          {getChart(graphData.reward, // data
                    selfDrivingLang.avg_reward_chart_title, // chart title
                    selfDrivingLang.avg_reward_chart_hAxis_title, // h axis title
                    selfDrivingLang.avg_reward_chart_vAxis_title, // v axis title
                    graphData.trial === 0,
                    resizeHandler
                  )}
        </Col>
        <Col sm={24} md={24} lg={12}>
          {getChart( graphData.distance, // data
                    selfDrivingLang.avg_distance_chart_title, // chart title
                    selfDrivingLang.avg_distance_chart_hAxis_title, // h axis title
                    selfDrivingLang.avg_distance_chart_vAxis_title, // v axis title
                    graphData.trial === 0,
                    resizeHandler
                  )}
        </Col>
      </Row>
      }
    </div>
  );
}
export default Try;
