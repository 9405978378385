import React from 'react';
import { Tag } from 'antd';
import { colors } from '../../styles';

function Label(props) {
  const styles = {
    default: {
      background: colors.gray6,
      padding: `4px 8px`,
      borderRadius: `4px`,
      color: colors.gray2,
      fontSize: `12px`,
      lineHeight: `16px`,
      border: `none`,
      marginLeft: `8px`,
    },
    outlined: {
      background: colors.white,
      border: `1px solid ${colors.gray5}`,
      color: colors.gray2,
      padding: `4px 8px`,
      borderRadius: `4px`,
      fontSize: `12px`,
      lineHeight: `16px`,
      marginLeft: `8px`,
    }
  }
  if(props.outlined)
    return <Tag style={styles.outlined}>{props.text}</Tag>
  else
    return <Tag style={styles.default}>{props.text}</Tag>

}
export default Label;
