import React, { useEffect } from 'react';
import { Card } from 'antd';

import { ContextStore } from '../../../../store/ContextStore';
import { projectsLang } from '../../../../languages/projects.language';
import { parseDate } from '../../../../utils/app';
import { 
	S_AIART_EXPLORE,
	CLICK_AIART_EXPLORE_LINK,
} from '../../../../constants/tracker.constant';
import { postLessonResult } from '../../../../actions/project.action';
import { trackerWithPageView, trackerWithEvent } from '../../../../actions/app.action';
import { ReactComponent as InfoSvg } from '../../../../assets/Info.svg';
import { ReactComponent as ArticleSvg } from '../../../../assets/Article.svg';
import { ReactComponent as VideoSvg } from '../../../../assets/Video.svg';
import { fontStyles } from '../styles';
import { colors, shadows } from '../../../styles';

function Explore(props) {
	const { dispatch, project } = React.useContext(ContextStore);
	const [values, setValue] = React.useState({
		init: false,
	});

	const styles = {
		root: {
			boxShadow: shadows.elevation5,
			borderRadius: `6px`,
		},
		category: {
			fontWeight: `600`,
			color: colors.gray2,
			fontSize: `14px`,
			lineHeight: `16px`,
			marginTop: `32px`,
			textTransform: `capitalize`,
		},
		item: {
			cursor: `pointer`,
			display: `flex`,
			marginTop: `20px`,
		},
		title: {
			fontWeight: `600`,
			color: colors.gray1,
			fontSize: `14px`,
			lineHeight: `20px`,
		},
		description: {
			marginTop: `2px`,
			color: colors.gray2,
			fontSize: `12px`,
			lineHeight: `20px`,
		}
	}

	async function handleClickLink(url, contentUuid){
		window.open(url, '_blank');
		trackerWithEvent(CLICK_AIART_EXPLORE_LINK, contentUuid); // link id

		// post content result
		const timer = parseDate(Date.now());
		dispatch(await postLessonResult([{
			...timer,
			project_uuid: project.overview.id,
			lesson_uuid: project.curriculum.explore.id,
			content_uuid: contentUuid,
			is_correct: true,
			result: "",
		}]));
	}

	function sortUrls(data) {
		let result = {};
		for(let i in data){
			if(!result[data[i].tag]){
				result[data[i].tag] = {category: data[i].tag, contents: [data[i]]};
			}
			else{
				result[data[i].tag].contents.push(data[i]);
			}
		}
		return result;
	}

	function parseCategory() {
		let category = [];
		const contents = sortUrls(project.curriculum.explore.content);
		for(let i in contents){
			const content = parseContent(contents[i].contents);
			category.push(
				<div key={i}>
					<div style={styles.category}>{contents[i].category}</div>
					{content}
				</div>
			);
		}
		return category;
	}

	function parseContent(data){
		return data.map((unit, index) => {
			let icon;
			const type = unit.type.split('/')[1];
			switch(type) {
				case "info":
					icon = <InfoSvg />;
					break;
				case "article":
					icon = <ArticleSvg />;
					break;
				case "video":
					icon = <VideoSvg />;
					break;
				default:
					icon = <InfoSvg />;
					break;
			}

			return (
				<div 
					key={unit.key}
					style={styles.item}
					onClick={() => handleClickLink(unit.src, unit.content_uuid)}
				>
					<div style={{display: `inline-block`}}>{icon}</div>
					<div style={{display: `inline-block`, marginLeft: `16px`}}>
						<div style={styles.title}>{unit.name}</div>
						<div style={styles.description}>{unit.description}</div>
					</div>
				</div>
			);
		});
	}

	useEffect(() => {
		// post lesson result
		if(!values.init){
			postResult();
			trackerWithPageView(S_AIART_EXPLORE);
		}
		
		setValue({init: true});
		async function postResult() {
			const timer = parseDate(Date.now());
			dispatch(await postLessonResult([{
				...timer,
				project_uuid: project.overview.id,
				lesson_uuid: project.curriculum.explore.id,
				content_uuid: null,
				is_correct: true,
				result: "",
			}]));
		}
	}, [dispatch, values.init, project.overview.id, project.curriculum.explore.id]);

	return (
		<div className="container" style={{display: `block`}}>
			<div style={fontStyles.heading1}>{projectsLang.explore}</div>
			<div style={fontStyles.heading2}>{projectsLang.explore_description}</div>
			{
				project.curriculum.explore.content.length > 0 &&
				<Card 
					bordered={false}
					style={styles.root} 
					bodyStyle={{padding: `0px 24px 20px 24px`}}
				>
					{parseCategory()}
				</Card>
			}
		</div>
	);
}
export default Explore;
