import { run } from '../utils/promise';
import { getLocalApi, getApi } from '../utils/api';
// import { fileToBase64 } from '../utils/file';
import {
	ACTION_PROJECT_POPULAR_PROJECTS,
	ACTION_PROJECT_OVERVIEW,
	ACTION_PROJECT_LESSON,
	ACTION_PROJECT_RESULT,
	ACTION_AI_ART_TRY,
} from '../constants/project.constant';

export const getPopularProjects = async (cache=true) => {
	const response = await getApi(cache).get(`/project/all`);
	return run(
		ACTION_PROJECT_POPULAR_PROJECTS,
		response.data,
	);
}

export const getProjectOverview = async (data) => {
	return run(
		ACTION_PROJECT_OVERVIEW,
		{data:data},
	);
}

export const getProjectLessons = async (uuid) => {
	const lessonData = await getLocalApi().get(`/api/lessons.json`);
	const response = await getApi().post(`/project/enroll`, {uuid: uuid});
	const data = {
		...response.data,
		data: {
			data: lessonData.data,
			api: response.data.data
		}
	}
	return run(
		ACTION_PROJECT_LESSON,
		data,
	);
}

export const postLessonResult = async (result) => {
	const response = await getApi().post(`/project/result`, result);
	return run(
		ACTION_PROJECT_RESULT,
		response.data,
	)
}

export const postAiArtTry = async (base64, source, style, preset, name) => {
	const data = {
		'fileData': preset ? "" : base64,
		'fileType': preset ? "" : source.type,
	};
	const response = await getApi().post(`/project/aiart/try?preset=`+preset+`&style=`+style+`&name=`+name+`&source=`+source, data);
	
	return run(
		ACTION_AI_ART_TRY,
		response.data,
	);
}

export default {
	getPopularProjects,
	getProjectOverview,
	getProjectLessons,
	postLessonResult,
	postAiArtTry,
};
