import React, { useEffect } from 'react';
import { Button, Icon } from 'antd';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";

import { aiArtLang } from '../../../../languages/aiArt.language';
import { ReactComponent as CheckSvg } from '../../../../assets/circle-check.svg';
import { ReactComponent as RefreshSvg } from '../../../../assets/refresh-ccw.svg';
import { colors } from '../../../styles';
import { init, validate } from '../../Common/Pyodide';

function CodeEditor(props) {
	const [values, setValue] = React.useState({
		code: props.code,
		loading: false,
		error: null,
	});

	const styles = {
		root: {
			...props.customStyle,
			width: `100%`, 
			background: colors.code2,
		},
		trainingBar: {
			background: colors.code1,
			borderRadius: `12px 12px 0px 0px`,
			color: colors.gray4,
			position: `relative`,
			padding: `0px 18px 0px 18px`,
			marginTop: `30px`,
			height: `40px`,
		},
		reset: {
			color: colors.info2,
			background: colors.code1,
			border: `none`,
		},
		validate: {
			color: colors.white,
			background: colors.info2,
			border: `none`,
			marginLeft: `9px`,
			padding: values.loading ? `0px 12px 0px 29px` : `0px 12px`,
			borderRadius: `15px`,
			height: `28px`,
		},
		checkError: {
			position: `relative`,
			top: `-6px`,
			left: `7px`,
		},
		refreshIcon: {
			position: `relative`,
			top: `2px`,
			marginRight: `8px`,
			width: `16px`,
			height: `16px`,
		},
		result: {
			position: `relative`, 
			top: `8px`,
		},
	}

	function handleReset() {
		setValue({ 
			...values, 
			code: props.code,
			error: null,
		});
	}

	function onChange(code, event) {
		setValue({ ...values, code: code });
	}

	function handleValidate(code) {
		setValue({ ...values, loading: true });
		validate(props.preDefined+'\n'+code+'\n'+props.outputCode, callback);
	}

	function callback(result){
		result = result ? JSON.stringify(result) === props.expectedOutput : false;
		props.codeSubmit(values.code, result);

		setValue({
			...values,
			loading: false,
			error: !result,
		});
	}

	useEffect(() => {
		init();
	}, [])

	const annotations = props.highlighted.map((unit, index) => {
		return {row: unit-1, type: 'warning', text: 'Type your code here'}
	});

	return (
		<React.Fragment>
			<div style={styles.trainingBar}>
				{ 
					values.error===true && 
					<span style={{...styles.result, color: colors.danger3}}>
						<Icon type="close-circle" theme="filled" style={{fontSize: `20px`, position: `relative`, top: `3px`, left: `2px`}} />
						<span style={{marginLeft: `10px`}}>{aiArtLang.have_error}</span>
					</span> 
				}
				{ 
					values.error===false && 
					<span style={{...styles.result, color: colors.success3}}>
						<CheckSvg />
						<span style={styles.checkError}>{aiArtLang.no_error}</span>
					</span> 
				}
				<span style={{float: `right`}}>
					<Button style={styles.reset} onClick={handleReset}><RefreshSvg style={styles.refreshIcon} />{aiArtLang.reset}</Button>
					<Button style={styles.validate} loading={values.loading} onClick={() => handleValidate(values.code)} disabled={!values.code}>{aiArtLang.validate}</Button>
				</span>
			</div>
			<AceEditor
				mode="python"
				theme="monokai"
				style={styles.root}
				value={values.code}
				focus={true}
				wrapEnabled={true}
				debounceChangePeriod={100}
				showGutter={true}
				highlightActiveLine={false}
				showPrintMargin={false}
				onChange={onChange}
				annotations={annotations}
			/>
		</React.Fragment>
	);
}
export default CodeEditor;
