// <----- actions
export const ACTION_START_APP_LOADING = 'action_start_app_loading';
export const ACTION_END_APP_LOADING = 'action_end_app_loading';
export const ACTION_LIGHTBOX = 'control_lightbox';
export const ACTION_WELCOME = 'control_welcome';
export const ACTION_VISIT_LESSON = 'visit_lesson';

// <----- z-indexes
export const Z_INDEX_MAIN = 1;
export const Z_INDEX_MAIN_HEADER = 99;
export const Z_INDEX_PROJECT_HEADER = 90;
export const Z_INDEX_LOADING = 9999;

// <--- welcome video
export const ENABLE_WELCOME_VIDEO = false;
export const WELCOME_VIDEO_URL = '/videos/SampleVideo_1280x720_1mb.mp4';
