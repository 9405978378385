import LocalizedStrings from 'react-localization';
 
export const reportLang = new LocalizedStrings({
	'en-us':{
		"report": "Report",
		"average_time": "Avg. Time Spent",
		"completed_projects": "Completed Projects",
		"completed_assessments": "Completed assessments",
		"learning_progress": "Learning Progress",
		"assessment": "Assessment",
		"project": "Course",
		"time_spent": "TIME SPENT",
		"score": "SCORE",
	},
});

export default { reportLang };
