import React, { useEffect } from 'react';

import { ContextStore } from '../../../../store/ContextStore';
import { projectsLang } from '../../../../languages/projects.language';
import { ROUTE_TRY, ROUTE_LEARN } from '../../../../constants/app.constant';
import { 
	S_AIART_LEARN,
	CLICK_AIART_NEXT,
} from '../../../../constants/tracker.constant';
import { parseDate } from '../../../../utils/app';
import { postLessonResult } from '../../../../actions/project.action';
import { trackerWithPageView, trackerWithEvent, parseProjectUrl } from '../../../../actions/app.action';
import { fontStyles } from '../styles';
import Previewer from '../Previewer';
import NextStep from '../NextStep';

function Learn(props) {
	const { dispatch, project } = React.useContext(ContextStore);
	const [values, setValue] = React.useState({
		init: false,
	});
	
	function handleNextStep(){
		trackerWithEvent(CLICK_AIART_NEXT, project.overview.id); // project id
		const path = parseProjectUrl(project.overview.name, ROUTE_TRY);
		props.history.push(path);
	}

	useEffect(() => {
		// post lesson result
		if(!values.init){
			postResult();
			trackerWithPageView(S_AIART_LEARN);
		}
		
		setValue({init: true});		
		async function postResult() {
			const timer = parseDate(Date.now());
			dispatch(await postLessonResult([{
				...timer,
				project_uuid: project.overview.id,
				lesson_uuid: project.curriculum.learn.id,
				content_uuid: null,
				is_correct: true,
				result: "",
			}]));
		}
	}, [dispatch, values.init, project.overview.id, project.curriculum.learn.id]);

	return (
		<div style={{width: `100%`}}>
			<div style={fontStyles.heading1}>{projectsLang.learn}</div>
			<div style={fontStyles.heading2}>{projectsLang.learn_description}</div>
			<Previewer route={ROUTE_LEARN} type={project.curriculum.learn.type} content={project.curriculum.learn.content} />
			<div style={{display: `flow-root`}}><NextStep step={projectsLang.try} action={handleNextStep} /></div>
		</div>
	);
}
export default Learn;
