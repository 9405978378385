import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';

import {
  Z_INDEX_MAIN,
} from '../../constants/dom.constant';
import {
  ROUTE_PROJECTS,
  ROUTE_REPORT,
  ROUTE_OVERVIEW,
  ROUTE_COURSE_PREFIX,
  ROUTE_COURSE_DEPTH,
  ROUTE_LEARN,
  ROUTE_TRY,
  ROUTE_INSPECT,
  ROUTE_REVIEW,
  ROUTE_EXPLORE,
  ROUTE_CHALLENGE,
  ROUTE_QUIZ,
  ROUTE_SUMMARY,
  ROUTE_LOGIN,
  ROUTE_HOME,
} from '../../constants/app.constant';
import { isAuthorized } from '../../reducers/rules/auth.rules';
import { parseProjectUrl } from '../../actions/app.action';
import { startAppLoading, endAppLoading, visitLessonPage } from '../../actions/dom.action';
import { getProjectLessons } from '../../actions/project.action';
import { ContextStore } from '../../store/ContextStore';
import MainHeader from './Common/MainHeader';
import MainFooter from './Common/MainFooter';
import Lightbox from './Common/Lightbox';
import WelcomeModal from './Common/WelcomeModal';
import ProjectHeader from './Common/ProjectHeader';
import MainSider from './Common/MainSider';
import OverviewHeader from './Overview/OverviewHeader';
import ProjectsView from './Projects';
import ReportView from './Report';
import OverviewView from './Overview';
import LearnView from './Lessons/Learn';
import TryView from './Lessons/Try';
import InspectView from './Lessons/Inspect';
import ReviewView from './Lessons/Review';
import ExploreView from './Lessons/Explore';
import ChallengeView from './Lessons/Challenge';
import AssessmentView from './Lessons/Assessment';
import SummaryView from './Lessons/Assessment/Summary';
import LoginView from './Login';

const { Content } = Layout;
const coursePages = [ROUTE_LEARN, ROUTE_TRY, ROUTE_INSPECT, ROUTE_REVIEW, ROUTE_EXPLORE, ROUTE_CHALLENGE, ROUTE_QUIZ, ROUTE_SUMMARY];
const hideFooter = [ROUTE_INSPECT, ROUTE_QUIZ];

function Auth(props) {
  const { dispatch, dom, auth, project } = React.useContext(ContextStore);

  useEffect(() => {
    if(getPathFromUrl(coursePages) 
      && isAuthorized(auth)
      && !dom.lesson
      && project.overview
      && project.overview.permitted
      ){
      getLessons();
    }
  });

  async function getLessons(){
    dispatch(visitLessonPage(true));
    dispatch(startAppLoading());
    dispatch(await getProjectLessons(project.overview.id));
    dispatch(endAppLoading());
  }

  function getPathFromUrl(routes, exclude) {
    const pathname = props.history.location.pathname;
    const path = pathname.indexOf(ROUTE_COURSE_PREFIX)===0
                  ? ROUTE_COURSE_PREFIX+'/:name/'+pathname.split('/')[ROUTE_COURSE_DEPTH]
                  : '/:name/'+pathname.split('/')[2];
    if(exclude){
      return !routes.includes(path);
    }
    return routes.includes(path);
  }

  // <----- course routes
  if(getPathFromUrl(coursePages)){
    if(!isAuthorized(auth)){
      window.location = ROUTE_LOGIN;
    }
    else if(!project.overview){
      window.location = ROUTE_HOME;
    }
    else if(!project.overview.permitted){
      window.location = parseProjectUrl(project.overview.name, ROUTE_OVERVIEW);
    }

    return (
      <Layout style={{minHeight: `100vh`}}>
        <Lightbox />
        <MainSider history={props.history} />
        <Layout>
          <ProjectHeader history={props.history} />
          <Content style={{marginTop:`64px`, zIndex: Z_INDEX_MAIN }}>
            <div style={{padding: `24px 32px`}}>
              <div className="course-container">
                <Route exact path={ROUTE_LEARN} component={LearnView} />
                <Route exact path={ROUTE_REVIEW} component={ReviewView} />
                <Route exact path={ROUTE_EXPLORE} component={ExploreView} />
                <Route exact path={ROUTE_QUIZ} component={AssessmentView} />
                <Route exact path={ROUTE_SUMMARY} component={SummaryView} />
                <Route exact path={ROUTE_CHALLENGE} component={ChallengeView} />
              </div>
              <Route exact path={ROUTE_INSPECT} component={InspectView} />
              <Route exact path={ROUTE_TRY} component={TryView} />
            </div>
          </Content>
          {
            getPathFromUrl(hideFooter, true) &&
            <MainFooter />
          }
        </Layout>
      </Layout>
    );
  }

  // <----- other routes
  if(props.history.location.pathname===ROUTE_REPORT && !isAuthorized(auth)){
    window.location = ROUTE_LOGIN;
  }
  return (
  	<Layout style={{minHeight: `100vh`}}>
      <Lightbox />
      <WelcomeModal />
      <Layout>
        <MainHeader history={props.history} pathname={props.history.location.pathname} />
        <Content style={{marginTop:`64px`, zIndex: Z_INDEX_MAIN }}>
          {
            getPathFromUrl([ROUTE_OVERVIEW]) &&
            <OverviewHeader />
          }
          <div style={{padding: `24px 32px`}}>
            <Route exact path={ROUTE_LOGIN} component={LoginView} />
            <Route exact path={ROUTE_HOME} component={ProjectsView} />
            <Route exact path={ROUTE_PROJECTS} component={ProjectsView} />
            <div className="container">
              <Route exact path={ROUTE_REPORT} component={ReportView} />
              <Route exact path={ROUTE_OVERVIEW} component={OverviewView} />
            </div>
          </div>
        </Content>
        {
          props.history.location.pathname!==ROUTE_LOGIN &&
          <MainFooter />
        }
      </Layout>
    </Layout>
  );
}
export default Auth;
