import React, { useEffect } from 'react';
import { Card, Input, Row, Col, Checkbox, Form, message, Layout } from 'antd';

import { login } from '../../../actions/auth.action';
import { trackerWithPageView, trackerWithEvent } from '../../../actions/app.action';
import { 
	S_LOGIN,
	CLICK_LOGIN_BUTTON,
	CLICK_FORGOT_PASSWORD,
	CLICK_TNC,
	CLICK_PRIVACY,
} from '../../../constants/tracker.constant';
import { ContextStore } from '../../../store/ContextStore';
// import { ReactComponent as Google } from '../../../assets/google.svg';
// import { ReactComponent as Edmodo } from '../../../assets/edmodo.svg';
import { parseError } from '../../../languages/error.language';
import { publicLang } from '../../../languages/public.language';
import { 
	startAppLoading, 
	endAppLoading, 
	showMessage, 
	controlWelcome 
} from '../../../actions/dom.action';
import { 
	ROUTE_HOME, 
	FORGOT_PASSWORD_URL,
	TERMS_AND_CONDITIONS,
	PRIVACY_POLICY,
} from '../../../constants/app.constant';
import { colors, shadows, text } from '../../styles';
import Button from '../Common/Button';
import MainFooter from '../Common/MainFooter';

function Public(props) {
	const { dispatch, dom, auth } = React.useContext(ContextStore);
	const [values, setValue] = React.useState({
		username: "",
		password: "",
		remember: true,
	});

	const styles = {
		root: {
			margin: `-24px -32px`,
			backgroundColor: colors.white,
			backgroundImage: `url('/images/login_bg.svg')`,
			backgroundSize: `contain`,
			backgroundRepeat: `no-repeat`,
			height: `calc(100vh - 64px)`,
			overflow: `auto`,
		},
		container: {
			height: `100%`,
			alignItems: `center`,
		},
		loginPanel: {
			borderRadius: `12px`,
			boxShadow: shadows.elevation2,
			textAlign: `center`,
			maxWidth: `420px`,
			minWidth: `380px`,
			margin: `0px auto 32px auto`,
		},
		input: {
			borderRadius: `6px`,
			marginTop: `16px`,
		},
		title: {
			...text.description,
			color: colors.gray2,
			fontWeight: `600`,
			margin: `16px 0px`,
		},
		agreement: {
			fontSize: `12px`,
			lineHeight: `20px`,
			color: colors.gray3,
			marginTop: `24px`
		},
		href: {
			color: colors.primary1,
			fontWeight: `500`,
			cursor: `pointer`,
		},
		eye: {
			position: `relative`,
			top: `-30px`,
			float: `right`,
			border: `0px`,
			backgroundColor: `transparent`,
			left: `-10px`,
		}
	}

	async function handleLogin(e){
		e.preventDefault();
		const {username, password, remember} = values;
		if(username==="" || password==="") {
			message.error(publicLang.empty_login_input);
			return;
		}

		dispatch(startAppLoading());
		try{
			dispatch(await login(username, password, remember));
			dispatch(endAppLoading());
		}catch(e){
			dispatch(endAppLoading());
			dispatch(showMessage(parseError(e)));
			trackerWithEvent(CLICK_LOGIN_BUTTON, '', 0); // login status
			return;
		}
	}

	function changeValue(name, data) {
    let value = {};
    value[name] = data;
    setValue({
      ...values,
      ...value,
    });
  }

	function handleExternalLink(url) {
		switch(url){
			case FORGOT_PASSWORD_URL: 
				trackerWithEvent(CLICK_FORGOT_PASSWORD);
				break;
			case TERMS_AND_CONDITIONS: 
				trackerWithEvent(CLICK_TNC);
				break;
			case PRIVACY_POLICY: 
				trackerWithEvent(CLICK_PRIVACY);
				break;
			default:
				break;
		}
		window.open(url, '_blank');
	}

	useEffect(() => {
		async function loginSuccess(){
			const path = localStorage.getItem('login.location') ? localStorage.getItem('login.location') : ROUTE_HOME;
			props.history.push(path);
			dispatch(endAppLoading());
			if(!dom.welcome.dismissed){
				dispatch(controlWelcome(true));
			}
		}
		if(auth.token && !dom.loading){
			trackerWithEvent(CLICK_LOGIN_BUTTON, '', 1); // login status
			loginSuccess();
		}
		if(!auth.token){
			trackerWithPageView(S_LOGIN);
		}
	});

	return (
		<Layout style={styles.root}>
			<Layout.Content className="container" style={styles.container}>
				<Row gutter={24} style={{maxHeight: `100%`, width: `100%`, justifyContent: `center`}} type="flex">
					<Col md={12} lg={14} id="login-banner">
						<img alt="login" src="/images/login.svg" style={{width: `100%`, position: `absolute`, bottom: `0px`}} />
					</Col>
					<Col md={12} lg={10}>
						<Card id="login-panel" bordered={false} style={styles.loginPanel} bodyStyle={{padding: `32px 32px 24px 32px`}}>
							<img alt="Coding Galaxy" src="/images/coding-galaxy.png" />
							<div style={styles.title}>{publicLang.login_with_coding_galaxy}</div>
							<Form onSubmit={handleLogin}>
								<Input style={styles.input} placeholder={publicLang.username} onChange={(e) => changeValue('username', e.target.value)} />
								<Input.Password style={styles.input} placeholder={publicLang.password} onChange={(e) => changeValue('password', e.target.value)} />
								<div style={{width: `100%`, textAlign: `left`, margin: `20px 0px`}}>
									<Checkbox defaultChecked={true} onChange={(e) => changeValue('remember', e.target.checked)}>{publicLang.remember_me}</Checkbox>
									{/*<span style={{...styles.href, float: `right`}} onClick={() => handleExternalLink(FORGOT_PASSWORD_URL)}>{publicLang.forgot_password}</span>*/}
								</div>
								<Button type="primary" block htmlType="submit">{publicLang.login}</Button>
							</Form>

							{/*<div style={{margin: `30px -32px`}}><Divider>{publicLang.or}</Divider></div>

							<Button type="secondary" block icon inlinestyle={{marginBottom: `16px`}}><Edmodo style={{float: `left`}} />{publicLang.edmodo}</Button>
							<Button type="secondary" block icon inlinestyle={{marginBottom: `16px`}}><Google style={{float: `left`}} />{publicLang.google}</Button>*/}

							<div style={styles.agreement}>
								{publicLang.formatString(
									publicLang.agreement, 
									<span style={styles.href} onClick={() => handleExternalLink(TERMS_AND_CONDITIONS)}><br />{publicLang.tnc}</span>, 
									<span style={styles.href} onClick={() => handleExternalLink(PRIVACY_POLICY)}>{publicLang.pp}</span>
								)}
							</div>
						</Card>
					</Col>
				</Row>
			</Layout.Content>
			<MainFooter />
		</Layout>
	);
}

export default Public;
