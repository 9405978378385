import React, { useEffect } from 'react';

import { aiArtLang } from '../../../../languages/aiArt.language';
import { ReactComponent as CheckCircleSvg } from '../../../../assets/check-circle.svg';
import { S3_URL } from '../../../../constants/app.constant';
import { 
	S_AIART_TRY,
	SELECT_AIART_CONTENT_IMG,
} from '../../../../constants/tracker.constant';
import { trackerWithPageView, trackerWithEvent } from '../../../../actions/app.action';
import Button from '../../../Auth/Common/Button';
import { fontStyles } from '../../../Auth/Lessons/styles';
import { colors, shadows } from '../../../styles';
import StyledImage from './StyledImage';

function Try(props) {
	const imgDom = React.useRef();
	const [values, setValue] = React.useState({
		uploadedImage: null,
		selectedImage: null,
		base64: '',
		source: null,
		preset: false,
		uploaded: false,
		init: false,
	});

	const styles = {
		root: {
			backgroundColor: colors.white, 
			boxShadow: shadows.elevation5,
			borderRadius: `6px`,
			padding: `20px 0px 20px 20px`,
		},
		container: {
			height: `516px`,
			maxHeight: `calc(100vh - 167px - 191px)`, 
			overflowY: `scroll`,
		},
		sample: {
			display: `inline-block`,
			position: `relative`,
			width: `164px`,
			height: `164px`,
			margin: `2px 4px`,
			backgroundSize: `cover`,
			backgroundPositionX: `center`,
			borderRadius: `2px`,
			cursor: `pointer`,
			overflow: `hidden`,
		},
		selectOverlay: {
			width: `100%`,
			height: `100%`,
			backgroundColor: `rgba(51, 51, 51, 0.25)`,
			textAlign: `right`,
			padding: `11px`,
			position: `absolute`,
			top: 0,
			color: colors.success2,
		}
	}

	const preset = [
		{src: "/images/preset_images/codi.jpg", name: "Codi"},
		{src: "/images/preset_images/coding-galaxy.jpg", name: "coding-galaxy"},
		{src: "/images/preset_images/portrait_01.jpg", name: "portrait_01"},
		{src: "/images/preset_images/portrait_02.jpg", name: "portrait_02"},
		{src: "/images/preset_images/portrait_03.jpg", name: "portrait_03"},
		{src: "/images/preset_images/animal_01.jpg", name: "animal_01"},
		{src: "/images/preset_images/animal_02.jpg", name: "animal_02"},
		{src: "/images/preset_images/food_01.jpg", name: "food_01"},
		{src: "/images/preset_images/food_02.jpg", name: "food_02"},
		{src: "/images/preset_images/landscape_01.jpg", name: "landscape_01"},
		{src: "/images/preset_images/landscape_02.jpg", name: "landscape_02"},
		{src: "/images/preset_images/cities_01.jpg", name: "cities_01"},
		{src: "/images/preset_images/cities_02.jpg", name: "cities_02"},
		{src: "/images/preset_images/cities_03.jpg", name: "cities_03"},
		{src: "/images/preset_images/cities_04.jpg", name: "cities_04"},
	];


	function handleLoaded() {
		setValue({...values, uploaded: true});
	}

	function handleClickSample(image) {
		setValue({
			...values,
			selectedImage: image.src,
			source: image.name,
		});
	}

	function handleReset() {
		setValue({
			...values,
			uploadedImage: null,
			selectedImage: null,
			source: null,
			uploaded: false,
		});
	}

	function handleSubmitImage(image) {
		trackerWithEvent(SELECT_AIART_CONTENT_IMG, values.source); // image id
		setValue({
			...values,
			uploadedImage: image,
			base64: '',
			preset: true,
		});
	}

	useEffect(() => {
		if(!values.init){
			trackerWithPageView(S_AIART_TRY);
			setValue({
				...values,
				init: true,
			});
		}
	}, [values]);

	const images = preset.map((unit, index) => (
		<div 
			key={index} 
			style={{...styles.sample, backgroundImage: `url(`+S3_URL+`/aiart-testtube/pretrained/thumbnail/source_image/`+unit.name+`.jpg)`}} 
			onClick={() => handleClickSample(unit)}
		>	
			{
				values.selectedImage === unit.src &&
				<div style={styles.selectOverlay}>
					<CheckCircleSvg style={{width: `27px`, height: `27px`}} />
				</div>
			}
		</div>
	));

	return (
		<div style={{width: `100%`}}>
			<div style={fontStyles.heading1}>{aiArtLang.ai_art_generator}</div>
			<div style={fontStyles.heading2}>{aiArtLang.try_description}</div>
			{
				values.uploaded
				? <StyledImage src={values.uploadedImage} reset={handleReset} preset={values.preset} source={values.source} base64={values.base64}/>
				: <div style={styles.root}>
					<div style={styles.container}>
						<div>{aiArtLang.choose_image}</div>
						{images}
					</div>
					<Button 
						type="primary" 
						inlinestyle={{marginTop: `16px`}}
						onClick={() => handleSubmitImage(values.selectedImage)} 
						disabled={!values.selectedImage}
					>
						{aiArtLang.choose_style}
					</Button>
				</div>
			}
			<img alt="tmp" ref={imgDom} src={values.uploadedImage} onLoad={handleLoaded} style={{display: `none`}} /> 
		</div>
	);
}
export default Try;
