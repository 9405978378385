import {
	APP_SERVER_PRODUCTION,
	APP_SERVER_LOCAL,
	APP_SERVER,
} from '../constants/app.constant';

let identity = null;

export const addHotjarIdentity = (userId=null) => {
	if(
			userId!==null 
			&& identity!==userId 
			&& (APP_SERVER===APP_SERVER_PRODUCTION 
			|| APP_SERVER===APP_SERVER_LOCAL)
			&& window.hj
			){
		identity = userId;
		window.hj('identify', userId, {
			    // 'Signed up': '2019—06-20Z', // Signup date in ISO-8601 format.
			    // Add your own custom attributes here. Some examples:
			    // 'Last purchase category': 'Electronics', // Send strings with quotes around them.
			    // 'Total purchases': 15, // Send numbers without quotes.
			    // 'Last purchase date': '2019-06-20Z', // Send dates in ISO-8601 format. 
			    // 'Last refund date': null, // Send null when no value exists for a user.
			});
	}
}

export default {
	addHotjarIdentity,
};
