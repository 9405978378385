import React from 'react';
import Carousel, { Modal as ImageModal, ModalGateway } from 'react-images';
import { ContextStore } from '../../../store/ContextStore';
import { controlLightbox } from '../../../actions/dom.action';

function Lightbox(props) {
  const { dispatch, dom } = React.useContext(ContextStore);

  function handleCloseLightbox(){
    dispatch(controlLightbox(false));
  }

  return (
    <ModalGateway>
      {dom.lightbox.isOpened ? (
        <ImageModal onClose={handleCloseLightbox}>
          <Carousel views={dom.lightbox.imageSources} currentIndex={dom.lightbox.currentIndex} />
        </ImageModal>
      ) : null}
    </ModalGateway>
  );
}
export default Lightbox;
