import React, { useEffect } from 'react';

import { ContextStore } from '../../../../store/ContextStore';
import { projectsLang } from '../../../../languages/projects.language';
import { 
	ROUTE_QUIZ, 
	ROUTE_INSPECT, 
	ROUTE_REVIEW 
} from '../../../../constants/app.constant';
import { 
	S_AIART_REVIEW,
	CLICK_AIART_REVIEW_NEXT,
} from '../../../../constants/tracker.constant';
import { parseDate } from '../../../../utils/app';
import { postLessonResult } from '../../../../actions/project.action';
import { trackerWithPageView, trackerWithEvent, parseProjectUrl } from '../../../../actions/app.action';
import { fontStyles } from '../styles';
import Previewer from '../Previewer';
import NextStep from '../NextStep';

function Review(props) {
	const { dispatch, project } = React.useContext(ContextStore);
	const [values, setValue] = React.useState({
		init: false,
	});

	function handleNextStep(){
		trackerWithEvent(CLICK_AIART_REVIEW_NEXT, project.overview.id); // project id
		const path = parseProjectUrl(project.overview.name, ROUTE_QUIZ);
		props.history.push(path);
	}

	function handlePreviousStep(){
		const path = parseProjectUrl(project.overview.name, ROUTE_INSPECT);
		props.history.push(path);
	}

	useEffect(() => {
		// post lesson result
		if(!values.init){
			postResult();
			trackerWithPageView(S_AIART_REVIEW);
		}
		
		setValue({init: true});	
		async function postResult() {
			const timer = parseDate(Date.now());
			dispatch(await postLessonResult([{
				...timer,
				project_uuid: project.overview.id,
				lesson_uuid: project.curriculum.review.id,
				content_uuid: null,
				is_correct: true,
				result: "",
			}]));
		}
	}, [dispatch, values.init, project.overview.id, project.curriculum.review.id]);

	return (
		<div style={{width: `100%`}}>
			<div style={fontStyles.heading1}>{projectsLang.review}</div>
			<div style={fontStyles.heading2}>{projectsLang.review_description}</div>
			<Previewer route={ROUTE_REVIEW} type={project.curriculum.review.type} content={project.curriculum.review.content} />
			<NextStep type="left" step={projectsLang.inspect} action={handlePreviousStep} />
			<NextStep type="right" step={projectsLang.quiz} action={handleNextStep} />
		</div>
	);
}
export default Review;
