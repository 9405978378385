import {
  GOOGLE_ANALYTICS,
	FIREBASE_GA,
} from '../constants/app.constant';
import ReactGA from 'react-ga';
import {
  CATEGORIES,
  CATEGORY_OTHERS,
} from '../constants/tracker.constant';

export const trackerWithPageView = (pageName) => {
  // console.log('trackerWithPageView', pageName);
  if(GOOGLE_ANALYTICS)
    ReactGA.pageview(pageName);
  if(FIREBASE_GA)
    window.gtag('config', FIREBASE_GA, {'page_title' : pageName});
  return;
}

export const trackerWithEvent = (eventName, label, value) => {
  var ga = ReactGA.ga();
  var category = parseTrackerCategory(eventName);
  var gaObj = {
    hitType: 'event',
    eventCategory: category,
    eventAction: eventName,
  };
  var fgaObj = {
    event_category: category,
  };

  if(label) {
    gaObj.label = label;
    fgaObj.event_label = label;
  } 
  if(value!==null && (Number.isInteger(parseInt(value)) || parseInt(value)===0)) {
    gaObj.value = parseInt(value);
    fgaObj.value = parseInt(value);
  } 
  // console.log('trackerWithEvent', fgaObj);
  if(GOOGLE_ANALYTICS)
    ga('send', gaObj);
  if(FIREBASE_GA)
    window.gtag('event', eventName, fgaObj);
  return;
}

export function parseProjectUrl(projectName, route){
  const name = projectName.toLowerCase().replace(/ /g, "-");
  const url = route.replace(/:name/g, name);
  return url;
}

function parseTrackerCategory(eventName){
  for(var key in CATEGORIES)
    if(CATEGORIES[key].indexOf(eventName)>-1)
      return key;
  return CATEGORY_OTHERS;
}

export default {
  trackerWithPageView,
  trackerWithEvent,
  parseProjectUrl,
};
