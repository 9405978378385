import React from 'react';
import { projectsLang } from '../../../languages/projects.language';
import { ReactComponent as CheckCircleSvg } from '../../../assets/check-circle.svg';
import { colors } from '../../styles';

function Step(props) {
	const styles = {
		root: {
			backgroundColor: colors.gray6, 
			padding: `20px 16px 20px 20px`, 
			marginBottom: `12px`,
			color: colors.gray3,
			borderRadius: `2px`,
			display: `flex`,
			alignItems: `center`,
		},
		title: {
			fontWeight: `500`,
			color: colors.gray2,
			marginLeft: `12px`, 
			width: `100px`, 
			display: `inline-block`
		},
		complete: {
			fontWeight: `600`,
			color: colors.success2,
			letterSpacing: `0.05em`,
			fontSize: `12px`,
			lineHeight: `16px`,
			position: `absolute`,
			right: `2px`,
			transform: `translateX(-50%)`,
			display: `flex`,
		},
		stepIcon: {
			width: `24px`, 
			height: `24px`,
		},
	}

	return (
		<div style={styles.root}>
			<span style={{marginRight: `16px`}}>{props.index} </span>
			<img alt={props.title} src={props.icon} style={styles.stepIcon} />
			<div style={styles.title}>{props.title}</div>
			<span>{props.content}</span>
			{
				props.success
				? <span style={styles.complete}>
						<CheckCircleSvg style={{width: `16px`, height: `16px`, marginRight: `4px`}} />
						{projectsLang.complete}
					</span>
				: <span />
			}
		</div>
	);
}

export default Step;
