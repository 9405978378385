import React from 'react';
import { Card, Col } from 'antd';

import { ContextStore } from '../../../store/ContextStore';
import { getProjectOverview } from '../../../actions/project.action';
import { trackerWithEvent, parseProjectUrl } from '../../../actions/app.action';
import { ROUTE_OVERVIEW } from '../../../constants/app.constant';
import { 
	CLICK_AIART_COURSE,
} from '../../../constants/tracker.constant';
import { ReactComponent as LockSvg } from '../../../assets/lock.svg';
import { colors, shadows } from '../../styles';

function Project(props) {
	const { dispatch } = React.useContext(ContextStore);

	const styles = {
		root: {
			marginBottom: `24px`,
		},
		card: {
			borderRadius: `12px`,
			height: `100%`,
			boxShadow: shadows.elevation4,
		},
		title: {
			marginBottom: `7px`,
			fontWeight: `500`,
			fontSize: `16px`,
			lineHeight: `24px`,
			color: colors.gray1,
		},
		tag: {
			marginRight: `8px`,
			fontSize: `12px`,
			lineHeight: `16px`,
			color: colors.gray2,
		},
		cover: {
			width: `100%`,
			height: `100%`,
			borderRadius: `12px 12px 0px 0px`,
		},
		lock: {
			color: colors.gray4,
			width: `16px`,
			height: `16px`,
			marginLeft: `8px`,
			position: `relative`,
			top: `2px`,
		},
	}

	async function handleClickProject(data) {
		switch(data.name) {
			case 'AI Art':
				trackerWithEvent(CLICK_AIART_COURSE);
				break;
			default:
				break;
		}

		const path = parseProjectUrl(data.name, ROUTE_OVERVIEW);
		dispatch(await getProjectOverview(data));
		props.history.push(path);
		return;
	}
	
	const tag = props.value.tag.map(item => <span key={item} style={styles.tag}>{item}</span>);
	const cover = props.value.image 
		? <img alt={props.value.name} src={props.value.image} style={styles.cover} /> 
		: <img alt={props.value.name} src="/images/AI-Art.svg" style={{...styles.cover, opacity: 0.3}} />;

	return (
		<Col xs={24} sm={12} md={8} lg={6} style={styles.root}>
			<Card 
				bordered={false}
				hoverable
				style={styles.card}
				cover={cover}
				onClick={() => handleClickProject(props.value)}
			>
				<div>
					<div style={styles.title}>
						{props.value.name}
						{!props.value.permitted && <LockSvg style={styles.lock} />}
					</div>
					<div style={{minHeight: `21px`}}>{tag}</div>
				</div>
			</Card>
		</Col>
	);
}

export default Project;
