import React from 'react';

import { ContextStore } from '../../../store/ContextStore';
import { projectsLang } from '../../../languages/projects.language';
import { trackerWithEvent, parseProjectUrl } from '../../../actions/app.action';
import { visitLessonPage } from '../../../actions/dom.action';
import { Z_INDEX_PROJECT_HEADER } from '../../../constants/dom.constant';
import { 
  CLICK_BACK_TO_COURSE,
} from '../../../constants/tracker.constant';
import { ReactComponent as BackSvg } from '../../../assets/chevron-left.svg';
import { ROUTE_OVERVIEW } from '../../../constants/app.constant';
import { colors } from '../../styles';
import Button from './Button';

function MainHeader(props) {
  const { dispatch, project } = React.useContext(ContextStore);

  const styles = {
    root: {
      padding: `12px 32px`,
      height: `64px`,
      zIndex: Z_INDEX_PROJECT_HEADER,
      position: `fixed`,
      width: `calc(100% - 256px)`,
      background: colors.white,
      boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.15)`,
    },
    title: {
      color: colors.gray1,
      fontWeight: `500`,
      fontSize: `20px`,
      lineHeight: `40px`,
    },
    backSvg: {
      marginRight: `8px`,
      position: `relative`,
      top: `6px`,
      color: colors.gray3,
    },
    backBtn: {
      position: `fixed`,
      right: `32px`,
    }
  }

  function handleToCourse() {
    trackerWithEvent(CLICK_BACK_TO_COURSE, project.overview.id); // project id
    dispatch(visitLessonPage(false));
    const path = parseProjectUrl(project.overview.name, ROUTE_OVERVIEW);
    props.history.push(path);
  }

  return (
    <div style={styles.root} id="project-header">
      <span style={styles.title}>{project.overview.name}</span>
      <Button type="secondary" icon inlinestyle={styles.backBtn} onClick={handleToCourse}>
        <BackSvg style={styles.backSvg} />
        <span style={{lineHeight: `40px`, position: `relative`, top: `-2px`}}>{projectsLang.back_to_course}</span>
      </Button>
    </div>
  );
}
export default MainHeader;
