const velocityInterval = 5;
const distanceInterval = 5;

let minVelocity_QTable;
let minDistance_QTable;

let qTable = [];

let noOfAction = 0;

let qTableLength = 0;
let qTableWidth = 0;

let qTableArea = 0;

export function resetQTable(inputMaxVelocity, inputMinVelocity, inputMaxDistance, inputMinDistance, totalNoOfAction) {
	qTable = [];

	noOfAction = totalNoOfAction;
	qTableLength = Math.ceil((inputMaxVelocity - inputMinVelocity) / velocityInterval);
	qTableWidth = Math.ceil((inputMaxDistance - inputMinDistance) / distanceInterval);

	minVelocity_QTable = inputMinVelocity;
	minDistance_QTable = inputMinDistance;

	qTableArea = qTableLength * qTableWidth;
}

export function getQTableBestAction(velocity, distance, isGetIndex) {
	let valueArray = getQTableValueArray(velocity, distance);

	let returnActionIndex = 0;
	let returnActionValue = null;

	for (let i = 0; i < valueArray.length; i++) {
		let value = valueArray[i];

		if (returnActionValue === null || returnActionValue < value) {
			returnActionValue = value;
			returnActionIndex = i;
		}
	}

	if (isGetIndex) {
		return returnActionIndex;
	} else {
		return returnActionValue;
	}
}

export function updateQTableOnBatch(stateAndActionIndexArray, updateValueArray) {
	let searchKeyArray = [];
	let sortedArray = [];

	// Group by index
	for (let i = 0; i < stateAndActionIndexArray.length; i++) {
		let state = stateAndActionIndexArray[i][0];
		let actionIndex = stateAndActionIndexArray[i][1];
		let tableIndex = getQTableIndex(state[0], state[1]);

		if (!sortedArray[tableIndex]) {
			sortedArray[tableIndex] = [];
			searchKeyArray.push(tableIndex);
		}

		sortedArray[tableIndex].push(createQTableValueArray(actionIndex, updateValueArray[i], true));
	}

	// Get average if there are more than one value
	for (let i = 0; i < searchKeyArray.length; i++) {
		let value = sortedArray[searchKeyArray[i]];
		if (value.length > 1) {
			let sum = value[0];

			for (let j = 1; j < value.length; j++) {
				sum = addQTableValueArrays(sum, value[j]);
			}
			let avg = divideQTableValue(sum, value.length);
			sortedArray[searchKeyArray[i]] = [avg];
		}
	}

	// update
	for (let i = 0; i < searchKeyArray.length; i++) {
		updateQTableByIndex(searchKeyArray[i], sortedArray[searchKeyArray[i]][0]);
	}
}

function getQTableIndex(velocity, distance) {
	let x = Math.floor((velocity - minVelocity_QTable) / velocityInterval);
	let y = Math.floor((distance - minDistance_QTable) / distanceInterval);

	return x * qTableWidth + y;
}

export function getQTableValueArray(velocity, distance) {
	let index = getQTableIndex(velocity, distance);
	let valueArray = qTable[index];

	if (!valueArray) {
		valueArray = createQTableValueArray(0, 0, false);

		updateQTableByIndex(index, valueArray);
	}

	return valueArray;
}

export function getQTableValue(velocity, distance, actionIndex) {
	return getQTableValueArray(velocity, distance)[actionIndex];
}

function updateQTable(velocity, distance, valueArray) {
	let index = getQTableIndex(velocity, distance);
	updateQTableByIndex(index, valueArray);
}

function updateQTableByIndex(index, valueArray) {

	let qTableValueArray = qTable[index];
	if (!qTableValueArray) {
		qTableValueArray = createQTableValueArray(0, 0, false);
	}
	for (let i = 0; i < valueArray.length; i++) {
		if (valueArray[i] !== null) {
			qTableValueArray[i] = valueArray[i];
		}
	}

	qTable[index] = qTableValueArray;
}

// region Q Table Value Array operation

function createQTableValueArray(assignIndex, assignValue, isUnassignIndexesNull) {
	let valueArray = [];

	for (let i = 0; i < noOfAction; i++) {
		if (i === assignIndex) {
			valueArray.push(assignValue);
		} else {
			if (isUnassignIndexesNull) {
				valueArray.push(null);
			} else {
				valueArray.push(0);
			}
		}
	}

	return valueArray;
}

function addQTableValueArrays(firstArray, secondArray) {
	let valueArray = [];

	for (let i = 0; i < noOfAction; i++) {
		let newValue = null;
		if (firstArray[i] !== null || secondArray[i] !== null) {
			newValue = firstArray[i] + secondArray[i];
		}

		valueArray.push(newValue);
	}

	return valueArray;
}

function divideQTableValue(targetArray, divisor) {
	let valueArray = [];
	let inverse = 1 / divisor;

	for (let i = 0; i < noOfAction; i++) {
		let newValue = null;
		if (targetArray[i] !== null) {
			newValue = targetArray[i] * inverse;
		}
		valueArray.push(newValue);
	}

	return valueArray;
}
// endregion