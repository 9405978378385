import LocalizedStrings from 'react-localization';
 
export const aiArtLang = new LocalizedStrings({
	'en-us':{
		// try
		"try_description": "Transfer an artistic style to another image.",
		"drop_image": "Drop or upload an image, or ",
		"open_camera": "open the camera",
		"pick_sample": "or pick a sample",
		"select_style": "Select a style",
		"start_over": "Choose source image",
		"ai_art_generator": "Stylize the Image",
		"launch_app": "Launch App",
		"training": "Transferring the style...",
		"training_completed": "Style transfer complete!",
		"original": "Original",
		"result": "Result",
		"intermediate": "Intermediate {0}",
		"final_result": "Final result",
		"start_styling": "Start stylization",
		"choose_image": "Choose a content image",
		"choose_style": "Choose Style",
		"styles": "Styles",
		"dimension_too_large": "The image dimension is larger than 1000px * 1000px",
		"file_too_large": "The file size is larger than 2MB",
		"not_an_image": "Please select an image",

		// challenge
		"challenge_goal": "Explore the style transfer program. Here are some tasks for you to think about and solve. Pick one or more to investigate – they are ordered from easy to difficult. Try to modify the code or write plans to complete the tasks.",
	},
});

export default { aiArtLang };
