import LocalizedStrings from 'react-localization';
 
export const commonLang = new LocalizedStrings({
	'en-us':{
		"logout": "Logout",
		"projects": "Courses",
		"report": "Report",
		"login": "Log In",
		"junior": "Junior",
		"cityx": "City X",
		"smart_lab": "Smart Lab",
	},
});

export default { commonLang };
