import React from 'react';
import { Layout, Menu, Icon, Dropdown } from 'antd';
import { Link } from 'react-router-dom';

import { logout } from '../../../actions/auth.action';
import { ContextStore } from '../../../store/ContextStore';
import { parseError } from '../../../languages/error.language';
import { commonLang } from '../../../languages/common.language';
import { showMessage } from '../../../actions/dom.action';
import { trackerWithEvent, parseProjectUrl } from '../../../actions/app.action';
import { Z_INDEX_MAIN_HEADER } from '../../../constants/dom.constant';
import { 
  CLICK_LOGIN,
  CLICK_LOGOUT,
  CLICK_REPORT,
} from '../../../constants/tracker.constant';
import { ReactComponent as HomeSvg } from '../../../assets/home.svg';
import { ReactComponent as ReportSvg } from '../../../assets/clipboard-tab.svg';
import { ReactComponent as DownCircleSvg } from '../../../assets/down-circle.svg';
import {
  ROUTE_HOME,
  ROUTE_OVERVIEW,
  ROUTE_REPORT,
  ROUTE_LOGIN,
  JUNIOR_URL,
  CITYX_URL,
} from '../../../constants/app.constant';
import { isAuthorized } from '../../../reducers/rules/auth.rules';
import { colors, shadows } from '../../styles'; 

function MainHeader(props) {
  const { dispatch, auth, project } = React.useContext(ContextStore);

  const styles = {
    root: {
      zIndex: Z_INDEX_MAIN_HEADER,
      position: `fixed`,
      width: `100%`,
      boxShadow: shadows.elevation5,
      padding: `0px`,
    },
    container: {
      maxWidth: `1178px`, 
      margin: `auto`,
      padding: `0px 32px`,
      display: `flex`,
      justifyContent: `space-between`,
      width: `100%`,
    },
    menu: {
      textAlign: `center`,
      lineHeight: `64px`, 
      height: `64px`, 
      borderBottom: `none`
    },
    logo: {
      margin: `12px 0px`,
    },
    userSvg: {
      width: `32px`,
      height: `32px`,
    },
    username: {
      marginLeft: `12px`,
      color: colors.gray2,
    },
    rightMenu: {
      display: `flex`,
      alignItems: `center`,
      cursor: `pointer`,
    },
    productMenu: {
      display: `flex`,
      alignItems: `center`,
      padding: `12px 16px`,
      fontSize: `16px`,
      color: colors.gray2,
    },
    productIcon: {
      marginRight: `16px`,
    }
  }

  async function handleLogout(){
    try{
      trackerWithEvent(CLICK_LOGOUT);
      dispatch(await logout());
      window.location = ROUTE_HOME;
      return;
    }catch(e){
      dispatch(showMessage(parseError(e)));
      return;
    }
  }

  function handleLogin(){
    trackerWithEvent(CLICK_LOGIN);
    localStorage.setItem('login.location', props.pathname);
    props.history.push(ROUTE_LOGIN);
  }

  function handleReport() {
    trackerWithEvent(CLICK_REPORT);
  }

  function isOverview() {
    const path = '/:name/'+props.pathname.split('/')[2];
    return ROUTE_OVERVIEW.includes(path);
  }

  // Popup Menu
  const productMenu = (
    <Menu style={{width: `179px`, marginTop: `8px`}}>
      <div style={{textAlign: `center`, padding: `8px`}}>
        <img alt="Coding Galaxy" src="/images/CodingGalaxy_logo.png" />
      </div>
      <Menu.Divider />
      <Menu.Item key="1">
        <a style={styles.productMenu} href={JUNIOR_URL} target="_blank" rel="noopener noreferrer">
          <img alt="Coding Galaxy Junior" src="/images/Junior_logo.png" style={styles.productIcon} />{commonLang.junior}
        </a>
      </Menu.Item>
      <Menu.Item key="2">
        <a style={styles.productMenu} href={CITYX_URL} target="_blank" rel="noopener noreferrer">
          <img alt="CityX" src="/images/CityX_logo.png" style={styles.productIcon} />{commonLang.cityx}
        </a>
      </Menu.Item>
      <Menu.Item key="3">
        <Link style={styles.productMenu} to={ROUTE_HOME}>
          <img alt="Smart Lab" src="/images/SmartLab_logo.png" style={styles.productIcon} />
          <span style={{fontWeight: `600`, color: colors.primary1}}>{commonLang.smart_lab}</span>
        </Link>
      </Menu.Item>
    </Menu>
  );

  const profileMenu = (
    <Menu style={{width: `100px`, marginTop: `-8px`}}>
      <Menu.Item key="profile" onClick={handleLogout}>{commonLang.logout}</Menu.Item>
    </Menu>
  );

  // Fixed Header
  return (
    <Layout.Header style={styles.root}>
      <div style={styles.container}>

      {/* Smart Lab Logo */}
      <div style={{height: `64px`, position: `relative`, display: `inline-block`}}>
        <Link to={ROUTE_HOME}>
          <img alt="Smart Lab" src="/images/logo.png" style={styles.logo} />
        </Link>
        <Dropdown overlay={productMenu} trigger={["click"]} placement="bottomRight">
          <DownCircleSvg style={{position: `absolute`, bottom: `16px`, right: `-3px`}} />
        </Dropdown>
      </div>

      {/* Menu Tab */}
      <Menu
        id="top-menu"
        mode="horizontal"
        selectedKeys={[props.pathname]}
        style={styles.menu}
      >
        { 
          (isOverview() || props.pathname===ROUTE_REPORT) &&
          <Menu.Item className="menu-item" key={ROUTE_OVERVIEW} style={{borderBottom: `none`}}>
            <Link to={parseProjectUrl(project.overview.name, ROUTE_OVERVIEW)} style={{color: isOverview() ? colors.primary1 : colors.gray2}}>
              <Icon component={HomeSvg} />
              {commonLang.projects}
            </Link>
          </Menu.Item>
        }
        { 
          (isOverview() || props.pathname===ROUTE_REPORT) && isAuthorized(auth) &&
          <Menu.Item className="menu-item" key={ROUTE_REPORT} style={{borderBottom: `none`}}>
            <Link to={ROUTE_REPORT} style={{color: props.pathname===ROUTE_REPORT ? colors.primary1 : colors.gray2}} onClick={handleReport}>
              <Icon component={ReportSvg} />
              {commonLang.report}
            </Link>
          </Menu.Item>
        }
      </Menu>
      { 
        /* Menu Right - Login / Logout */
        isAuthorized(auth)
        ? <Dropdown overlay={profileMenu} trigger={["click"]} placement="bottomCenter">
            <div style={styles.rightMenu}>
              <img alt="avatar" src="/images/avatar.png" style={styles.userSvg} />
              <span style={styles.username}>{auth.userDisplayName}</span>
            </div>
          </Dropdown>
        : <div style={styles.rightMenu} onClick={handleLogin}>
            <img alt="avatar" src="/images/anonymous.png" style={styles.userSvg} />
            <span style={styles.username}>{commonLang.login}</span>
          </div>
      }
      </div>
    </Layout.Header>
  );
}
export default MainHeader;
