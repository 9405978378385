import React from 'react';
import { Row, Col, Modal } from 'antd';
import ReactPlayer from 'react-player'

import { 
	// ENABLE_WELCOME_VIDEO, 
	WELCOME_VIDEO_URL 
} from '../../../constants/dom.constant';
import { publicLang } from '../../../languages/public.language';
import { ReactComponent as Polygon } from '../../../assets/Polygon.svg';
// import { ReactComponent as PlayCircle } from '../../../assets/play-circle.svg';
import { colors, text } from '../../styles';
import { getCourse } from '../../projects';
import Button from '../Common/Button';

function HeroHeader(props) {
	const [values, setValue] = React.useState({
		modal: false,
	});

	const styles = {
		home: {
			margin: `-32px -32px 0px -32px`,
			backgroundColor: colors.white,
			backgroundImage: `url('/images/home_bg.svg')`,
			backgroundRepeat: `no-repeat`,
			position: `relative`,
			height: `665px`
		},
		process: {
			background: colors.white, 
			padding: `80px 32px 180px 32px`,
			margin: `0px -32px`,
		},
		homeContainer: {
			display: `block`,
			padding: `104px 32px 0px 32px`,
			position: `relative`,
			height: `100%`,
		},
		codi: {
			position: `absolute`,
			bottom: `3px`,
			right: `0px`,
		},
		processSvg: {
			position: `absolute`,
			top: `24px`,
			marginLeft: `-85px`,
			width: `64px`,
			height: `64px`,
		},
		title: {
			...text.heading1,
			color:colors.gray1,
		},
		subtitle: {
			fontSize: `22px`,
			lineHeight: `26px`,
			color: colors.gray1,
			marginTop: `12px`,
		},
		videoBtn: {
			marginLeft: `12px`,
		},
		outlineTitle: {
			...text.courseOutlineTitle,
			color: colors.gray2,
			fontWeight: `500`,
			margin: `16px 0px`,
		},
		outlineDescription: {
			color: colors.gray3,
			fontSize: `14px`,
			lineHeight: `22px`,
		},
		processTitle: {
			...text.headingHighlight,
			color: colors.primary2,
			marginBottom: `4px`,
		},
		howItWorks: {
			...text.heading2,
			color: colors.gray1,
		},
		bold: {
			color: colors.primary1,
			fontWeight: `700`,
		},
	};
	const courseOutline = ["Learn", "Try", "Inspect", "Review", "Explore", "Challenge"];

	// function handlePlayVideo() {
	// 	setValue({
	// 		...values,
	// 		modal: true
	// 	});
	// }

	function handleStopVideo() {
		setValue({
			...values,
			modal: false
		});
	}

	const outlines = courseOutline.map((unit, index) => {
		const {icon, title, description} = getCourse(unit);
		return (
			<Col sm={12} md={8} style={{marginTop: `71px`}} key={index}>
				<div style={{height: `100%`}}>
					<div style={{position: `relative`}}>
						<Polygon /><img alt={title} src={icon} style={styles.processSvg} />
					</div>
					<div style={styles.outlineTitle}>{title}</div>
					<div style={styles.outlineDescription}>{description}</div>
				</div>
			</Col>
		);
	});

	return (
		<React.Fragment>
			<div style={styles.home}>
				<div className="container" style={styles.homeContainer}>
					<div style={styles.title}>{publicLang.learn_and_code}</div>
					<div style={styles.subtitle}>{publicLang.formatString(publicLang.project_based_learning, <span style={styles.bold}>{publicLang.a_to_g}</span>)}</div>
					<div style={{margin: `40px 0px`, display: `flex`}}>
						<Button type="primary"  onClick={props.scroll}>{publicLang.view_courses}</Button>
						{/*<Button type="secondary" icon inlinestyle={styles.videoBtn} disabled={!ENABLE_WELCOME_VIDEO} onClick={handlePlayVideo}>
							<PlayCircle style={{marginRight: `8px`, marginTop: `4px`}} />
							<span style={{position: `relative`, top: `-7px`}}>{publicLang.play_video}</span>
						</Button>*/}
					</div>
					<img alt="hero lab" style={{maxWidth: `calc(100vw - 32px)`}} src="/images/hero_lab.svg" />
					<img id="hero-codi" alt="hero codi" style={styles.codi} src="/images/hero_codi.svg" />
				</div>
			</div>
			<div style={styles.process}>
				<div style={{display: `block`, textAlign: `center`}} className="container">
					<div style={styles.processTitle}>{publicLang.the_process}</div>
					<div style={styles.howItWorks}>{publicLang.how_it_works}</div>
					<Row gutter={24} type="flex">
						{outlines}
					</Row>
				</div>
			</div>
			<Modal
				visible={values.modal}
				closable
				onCancel={handleStopVideo}
				footer={null}
			>
				{
					values.modal &&
					<ReactPlayer 
						url={WELCOME_VIDEO_URL} 
						width="100%" 
						height="100%" 
						playing={values.modal} 
						loop 
					/>
				}
			</Modal>
			
		</React.Fragment>
	);
}

export default HeroHeader;
