import * as model from "./model"
import * as rl from "./rl"
import * as reward from "./reward_function"
import { GraphUpdateCallback } from "./bridge"

export function getDefaultResponse() {
  return model.defaultResponse(false);
}

export function initRLConfig(request) {
  let [isSuccess, v1, v2, v3, v4, v5, v6, v7, v8, v9, v10, v11, v12, v13, v14, v15, v16] = rl.initConfig();
  return model.initRLConfigResponse(isSuccess, v1, v2, v3, v4, v5, v6, v7, v8, v9, v10, v11, v12, v13, v14, v15, v16);
}

export function resetRL(request) {
  let isSuccess = rl.reset();
  if (isSuccess) {
    GraphUpdateCallback('resetRL', null);
  }
  return model.resetRLResponse(isSuccess);
}

export function setRLReward(request) {
  let isSuccess = reward.setReward(
    request.rewardItems,
  );
  return model.setRewardResponse(isSuccess);
}

export function chooseTrainingAction(request) {
  let [isSuccess, action] = rl.chooseAction(request.state.remainDistance, request.state.velocity, request.state.isEpisodeFinished);
  return model.chooseTrainingActionResponse(isSuccess, action);
}

export function chooseEvaluationAction(request) {
  let [isSuccess, action] = rl.chooseAction(request.state.remainDistance, request.state.velocity, null);
  return model.chooseEvaluationActionResponse(isSuccess, action);
}

export function startRLSimulation(request) {
  return model.startSimulationResponse(rl.startSimulation(request.isTraining, request.noOfEpisodeToSimulate));
}

export function startRLEpisode(request) {
  let [isSuccess, episodeNo] = rl.startEpisode();
  return model.startEpisodeResponse(isSuccess, episodeNo);
}

export function endRLEpisode(request) {
  let [isSuccess, isTraining, totalReward, finalDistance] = rl.endEpisode();
  if (isSuccess && isTraining) {
    const payload = {
      'reward': totalReward,
      'distance': finalDistance
    }
    GraphUpdateCallback('endEpisode', payload);
  }
  return model.endEpisodeResponse(isSuccess, totalReward, finalDistance);
}

export function postEndRLEpisode(request) {
  let [isSuccess, isStopSimulation] = rl.postEndEpisode(request.isRequestStopSimulation);
  return model.postEndEpisodeResponse(isSuccess, isStopSimulation);
}