import React from 'react';
import { Card } from 'antd';
import Cookies from 'universal-cookie';

import { ContextStore } from '../../../../store/ContextStore';
import { projectsLang } from '../../../../languages/projects.language';
import { aiArtLang } from '../../../../languages/aiArt.language';
import { parseDate } from '../../../../utils/app';
import { ReactComponent as EasySvg } from '../../../../assets/easy.svg';
import { ReactComponent as IntermediateSvg } from '../../../../assets/intermediate.svg';
import { ReactComponent as HardSvg } from '../../../../assets/hard.svg';
import { 
	CLICK_AIART_CHALLENGE_JUPYTER,
} from '../../../../constants/tracker.constant';
import { postLessonResult } from '../../../../actions/project.action';
import { trackerWithEvent } from '../../../../actions/app.action';
import { colors, shadows } from '../../../styles';
import Button from '../../../Auth/Common/Button';

function Challenge(props) {
	const { dispatch, project } = React.useContext(ContextStore);

	const styles = {
		root: {
			boxShadow: shadows.elevation5,
			borderRadius: `6px`,
		},
		title: {
			color: colors.gray1,
			fontWeight: `600`,
			fontSize: `20px`,
			lineHeight: `30px`,
		},
		description: {
			color: colors.gray2,
			fontSize: `14px`,
			lineHeight: `24px`,
			marginTop: `4px`,
		},
		stepContainer: {
			lineHeight:   `24px`,
			display: `flex`,
			margin: `25px 0px 5px 0px`,
		},
		stepTitle: {
			marginLeft: `8px`,
			color: colors.gray3,
			fontWeight: `600`,
			fontSize: `14px`,
			letterSpacing: `0.05em`,
			textTransform: `uppercase`,
		},
		step: {
			backgroundColor: colors.gray6,
			padding: `14px 16px`,
			marginTop: `8px`,
			color: colors.gray1,
		}
	}

	async function handleOpen() {
		// clear jupyter cookies
		const cookie = new Cookies();
		cookie.remove('XSRF-TOKEN', {
			path: '/',
			domain: 'cherrypickslearning.com',
		});
		window.open(project.curriculum.challenge.jupyter, "_blank");
		trackerWithEvent(CLICK_AIART_CHALLENGE_JUPYTER, project.overview.id); // project id

		// post lesson result
		const timer = parseDate(Date.now());
		dispatch(await postLessonResult([{
			...timer,
			project_uuid: project.overview.id,
			lesson_uuid: project.curriculum.challenge.id,
			content_uuid: null,
			is_correct: true,
			result: "",
		}]));
	}

	const easy = project.curriculum.challenge.easy.map((unit, index) => <div key={index} style={styles.step}>{unit}</div>);
	const intermediate = project.curriculum.challenge.intermediate.map((unit, index) => <div key={index} style={styles.step}>{unit}</div>);
	const hard = project.curriculum.challenge.hard.map((unit, index) => <div key={index} style={styles.step}>{unit}</div>);

	return (
		<div style={{ width: `100%` }}>
			<Card 
				bordered={false} 
				style={styles.root}
				bodyStyle={{padding: `24px 32px`}}
			>
				<div style={styles.title}>{project.overview.name} {projectsLang.challenge}</div>
				<div style={styles.description}>{aiArtLang.challenge_goal}</div>

				<div style={styles.stepContainer}>
					<EasySvg />
					<span style={styles.stepTitle}>{projectsLang.easy}</span>
				</div>
				{easy}

				<div style={styles.stepContainer}>
					<IntermediateSvg />
					<span style={styles.stepTitle}>{projectsLang.intermediate}</span>
				</div>
				{intermediate}

				<div style={styles.stepContainer}>
					<HardSvg />
					<span style={styles.stepTitle}>{projectsLang.hard}</span>
				</div>
				{hard}

				<Button type="primary" inlinestyle={{marginTop: `24px`}} onClick={handleOpen}>{projectsLang.start}</Button>
			</Card>
		</div>
	);
}
export default Challenge;
