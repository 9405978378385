import { getAppPlatformCode } from '../utils/app';

// <----- external app IDs and secrets
export const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
export const FIREBASE_GA = process.env.REACT_APP_FIREBASE_GA;

// <----- external paths
export const PATH_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const PATH_APP_URL = process.env.REACT_APP_URL;
export const FORGOT_PASSWORD_URL = process.env.REACT_APP_CLASSMIND_URL+'/en_us/general/forgot_password?platform=web&productKey='+process.env.REACT_APP_PRODUCT_KEY;
export const TERMS_AND_CONDITIONS = 'https://codinggalaxy.com/en-hk/terms';
export const PRIVACY_POLICY = 'https://codinggalaxy.com/en-hk/privacy';
export const JUNIOR_URL = process.env.REACT_APP_JUNIOR_URL;
export const CITYX_URL = process.env.REACT_APP_CITYX_URL;
export const S3_URL = process.env.REACT_APP_S3_URL;

// <----- platforms
export const PLATFORM_DESKTOP = 0;
export const PLATFORM_IOS = 1;
export const PLATFORM_ANDROID = 2;
export const PLATFORM_WINDOWS_PHONE = 3;
export const PLATFORM = getAppPlatformCode(window.navigator.userAgent || window.navigator.vendor || window.opera);
export const IS_MOBILE_PLATFORM = PLATFORM===PLATFORM_IOS || PLATFORM===PLATFORM_ANDROID; // || true;

// <----- system
export const NODE_ENV = process.env.NODE_ENV;
export const APP_SERVER_LOCAL = 'Local';
export const APP_SERVER_DEVELOPMENT = 'Development';
export const APP_SERVER_STAGING = 'Staging';
export const APP_SERVER_PRODUCTION = 'Production';
export const APP_SERVER = process.env.REACT_APP_SERVER ? process.env.REACT_APP_SERVER : APP_SERVER_LOCAL;
export const APP_VERSION = process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : '0.0.1';
export const LANGUAGE_EN_US = 'en-us';
export const MAX_UPLOAD_FILE_SIZE = process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE ? parseInt(process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE) : 2097152;

// <----- actions
export const ACTION_CHANGE_LANGUAGE = 'action_change_language';

// <----- routes
export const ROUTE_HOME = '/';
export const ROUTE_LOGIN = '/login';
export const ROUTE_PROJECTS = '/projects';
export const ROUTE_REPORT = '/report';
export const ROUTE_OVERVIEW = '/:name/overview';

export const ROUTE_COURSE_PREFIX = '/learn';
export const ROUTE_COURSE_DEPTH = 3;
export const ROUTE_LEARN = ROUTE_COURSE_PREFIX+'/:name/learn';
export const ROUTE_TRY = ROUTE_COURSE_PREFIX+'/:name/try';
export const ROUTE_INSPECT = ROUTE_COURSE_PREFIX+'/:name/inspect';
export const ROUTE_REVIEW = ROUTE_COURSE_PREFIX+'/:name/review';
export const ROUTE_EXPLORE = ROUTE_COURSE_PREFIX+'/:name/explore';
export const ROUTE_CHALLENGE = ROUTE_COURSE_PREFIX+'/:name/challenge';
export const ROUTE_QUIZ = ROUTE_COURSE_PREFIX+'/:name/quiz';
export const ROUTE_SUMMARY = ROUTE_COURSE_PREFIX+'/:name/summary';

