import React from 'react';
import { Card, Row, Col } from 'antd';

import { trackerWithEvent } from '../../../../actions/app.action';
import { 
	CLICK_AIART_INSPECT_ANSWER,
} from '../../../../constants/tracker.constant';
import { projectsLang } from '../../../../languages/projects.language';
import { colors, shadows } from '../../../styles';
import Button from '../../Common/Button';

function Question(props) {
	const [values, setValue] = React.useState({
		nextFlow: false,
		selected: [],
	});

	const styles = {
		root: {
			borderRadius: `12px`,
			background: colors.gray6,
			boxShadow: shadows.elevation5,
		},
		options: {
			marginTop: `16px`,
			textAlign: `left`,
		},
		question: {
			color: colors.gray1,
			fontSize: `16px`,
			lineHeight: `19px`,
			marginBottom: `8px`,
		},
		nextFlow: {
			color: colors.gray2,
			marginTop: `16px`,
		},
		description: {
			marginBottom: `32px`,
			fontSize: `14px`,
			lineHeight: `22px`,
			color: colors.gray2,
		},
	}

	function handleClickAnswer(unit) {
		let selected = values.selected;
		let nextFlow = unit.correct ? props.next(unit.content) : values.nextFlow;
		selected.push(unit);
		trackerWithEvent(CLICK_AIART_INSPECT_ANSWER, props.question.id, unit.correct); // question id, correct status

		setValue({
			...values,
			selected: selected,
			nextFlow: nextFlow,
		});
	}

	const options = props.question.options.map(function(unit, index) {
		let type = "answer";
		if(values.selected.includes(unit)){
			type = unit.correct ? "correct" : "wrong";
		}

		return <Button key={index} type={type} icon inlinestyle={styles.options} onClick={() => handleClickAnswer(unit)}>{unit.content}</Button>;
	});

	return (
		<React.Fragment>
			{
				props.question.introduction &&
				<div id="project-description" style={styles.description} dangerouslySetInnerHTML={{__html: `${props.question.introduction}`}} />
			}
			
			<Card 
				bordered={false} 
				style={styles.root}
				bodyStyle={{padding: `0px`}}
			>
				<Row gutter={24}>
					<Col sm={24} md={16}>
						{props.image}
					</Col>
					<Col sm={24} md={8} style={{padding: `24px 32px 24px 12px`}}>
						<div style={styles.question}>{props.question.content}</div>
						{options}
						{
							values.nextFlow &&
							<div style={styles.nextFlow}>{projectsLang.next_flow}</div>
						}
					</Col>
				</Row>
			</Card>
		</React.Fragment>
	);
}
export default Question;
