import {
  ACTION_AUTH_LOGIN,
  ACTION_AUTH_LOGOUT,
 } from '../constants/auth.constant';
import { defaultAuth } from '../store/ContextStore';
import { logout } from '../reducers/rules/auth.rules';
import { addHotjarIdentity } from '../actions/hotjar.action';

function authReducer(state, action) {
	const payload = action.payload;
	switch (action.key) {
		case ACTION_AUTH_LOGIN:
			const {userId, token, userDisplayName} = parsePayloadFromAuthLogin(payload);
			localStorage.setItem('auth.userId', userId);
			localStorage.setItem('auth.token', token);
			localStorage.setItem('auth.userDisplayName', userDisplayName);
			addHotjarIdentity(userDisplayName);
			return Object.assign({}, state, {
						...state,
						userId: userId,
						token: token,
						userDisplayName: userDisplayName,
					});
		case ACTION_AUTH_LOGOUT:
			logout();
			return Object.assign({}, state, defaultAuth);
		default:
			return state;
	}
}
export default authReducer;

function parsePayloadFromAuthLogin(payload){
	return {
		userId: payload.user.uuid,
		token: payload.jwt.token,
		userDisplayName: payload.user.username,
	};
}
