import { colors, text } from '../../styles';

const fontStyles = {
	heading1: {
		...text.heading3,
		color: colors.gray1,
		marginBottom: `2px`,
	},
	heading2: {
		...text.description,
		color: colors.gray2,
		marginBottom: `24px`,
	},
	heading3: {
		...text.courseOutlineTitle,
		color: colors.gray1,
		fontWeight: `600`,
	},
	content: {
		color: colors.gray1,
		fontSize: `16px`,
	},
	paragraph: {
		marginBottom: `40px`,
	}
};

export {
	fontStyles,
}
