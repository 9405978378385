import LocalizedStrings from 'react-localization';
 
export const selfDrivingLang = new LocalizedStrings({
	'en-us':{
        "train_your_car_title" : "Train your Car",
        "train_your_car_description" : "We will train a car to stop at the line before a traffic light. \n\nLet’s do this by allowing the car to simulate many times. By setting rewards for when it is doing well, it will gradually learn to do it better. \n\nAfter running the simulation for {0} in “training” mode, we will test the car in the real world in the “evaluation” mode",
        "train_your_car_description_bold_content" : "the defined number of trials",
        "avg_reward_chart_title" : "Avg. reward in total",
        "avg_reward_chart_hAxis_title" : "Trials",
        "avg_reward_chart_vAxis_title" : "Avg. Reward",
        "avg_distance_chart_title" : "Avg. distance from the goal",
        "avg_distance_chart_hAxis_title" : "Trials",
        "avg_distance_chart_vAxis_title" : "Avg. distance"
	},
});

export default { selfDrivingLang };
