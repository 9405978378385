import LocalizedStrings from 'react-localization';
 
export const publicLang = new LocalizedStrings({
	'en-us':{
		// login
		"login": "Log in",
		"login_with_coding_galaxy": "Log In with Coding Galaxy account",
		"username": "Username",
		"password": "Password",
		"remember_me": "Remember me",
		"forgot_password": "Forgot password?",
		"edmodo": "Continue with Edmodo",
		"google": "Continue with Google",
		"agreement": "By logging up you agree to our {0} and {1}.",
		"tnc": "Terms and Conditions",
		"pp": "Privacy Policy",
		"empty_login_input": "Please enter username and password",
		"or": "or",
		"show_password": "Show password",
		"hide_password": "Hide password",

		// home
		"popular_projects": "Popular Courses",
		"are_you_teacher": "ARE YOU A TEACHER?",
		"purpose_driven_learning": "Bring Purpose-driven Learning to your Class",
		"extended_learning_activies": "EXTENDED LEARNING ACTIVITIES",
		"the_process": "THE PROCESS",
		"how_it_works": "How It Works",
		"learn_and_code": "Learn and code real-world projects",
		"a_to_g": "Technology A→G",
		"project_based_learning": "Project-based learning covering {0}",
		"view_courses": "View Courses",
		"play_video": "Play Video",
		"submit": "Submit",
		"name": "Name*",
		"email": "Email*",
		"organization": "Organization",
		"courses_coming_soon": "More courses coming soon",

		// welcome
		"welcome_title": "Welcome to Smart Lab",
		"welcome_description": "Welcome new comer! I’m {0}! Feel free to walk around here and make your own innovations starting from courses you are interested.",
		"codi": "Codi, Director of Smart Lab",
		"get_started": "Start Now",

		// footer
		"footer": "Copyright © Cherrypicks {0}. All Rights Reserved.",
	},
});

export default { publicLang };
